import type { SnakeCasedPropertiesDeep } from "type-fest";

import { ReportViewFilterOperator, toFilterOperator } from "../services/report/ReportViewService";
import { TableData } from "./table";
import { DefaultReportTemplate } from "services/report/erasure/ReportTemplateService";

export const PATH_TYPE_ONLY_STRING = "ONLY_STRING";
const PATH_TYPE_UINT = "UINT";
const PATH_TYPES = ["STRING", PATH_TYPE_ONLY_STRING, "KEYWORD", PATH_TYPE_UINT, "BOOLEAN", "DATE"] as const;
export type PathType = typeof PATH_TYPES[number];
export const MAX_COLUMN_COUNT = 250;

export function toPathType(value?: string | null): PathType {
    if (value === "LONG") {
        value = PATH_TYPE_UINT;
    }
    const result = PATH_TYPES.find((each) => each === value);
    if (result == null) {
        throw Error("Not a PathType: " + value);
    }
    return result;
}

export interface DiagnosticData extends TableData {
    audioEarpiece: string;
    audioHeadphone: string;
    audioHeadsetMicrophone: string;
    audioMicrophone: string;
    audioMicrophoneRecording: string;
    audioNoiseCancellation: string;
    audioSpeaker: string;
    audioSpeakerMicrophone: string;
    batteryCharge: string;
    batteryChargeHold: string;
    batteryDrain: string;
    batteryHealth: string;
    batteryTemperature: string;
    buttonBack: string;
    buttonHome: string;
    buttonMute: string;
    buttonPower: string;
    buttonRecentApp: string;
    buttonSide: string;
    buttonVolumeDown: string;
    buttonVolumeUp: string;
    cameraBack: string;
    cameraBackAuto: string;
    cameraBackFlash: string;
    cameraBackVideo: string;
    cameraFront: string;
    cameraFrontAuto: string;
    cameraFrontFlash: string;
    cameraFrontVideo: string;
    cameraMultiCheck: string;
    carrierSignalCheckOne: string;
    carrierSignalCheckTwo: string;
    detectBluetooth: string;
    detectNfc: string;
    detectSim: string;
    deviceAutoRotate: string;
    deviceFmRadio: string;
    deviceLcdBacklight: string;
    deviceOtg: string;
    devicePerformance: string;
    deviceSdCard: string;
    deviceUsb: string;
    deviceVibration: string;
    displayBlackInkSpots: string;
    displayColouredVerticalLines: string;
    displayCrack: string;
    displayDragGesture: string;
    displayGhostImages: string;
    displayLcdColor: string;
    displayLcdPink: string;
    displayLcdStarring: string;
    displayMultiTouch: string;
    displayPixel: string;
    displayPressure: string;
    displayPurpleInkStain: string;
    displayTouch: string;
    enclosureBack: string;
    faceId: string;
    journeyId: number;
    liveCall: string;
    longAssisstedQuickCheck: string;
    longGroupTestQuickCheck: string;
    longStress: string;
    make: string;
    model: string;
    modelName: string;
    networkCellular: string;
    networkWifi: string;
    passcodeStatus: string;
    sensorAccelerometer: string;
    sensorAltimeter: string;
    sensorBarometer: string;
    sensorCompass: string;
    sensorFingerprint: string;
    sensorGyroscope: string;
    sensorHall: string;
    sensorHeartRate: string;
    sensorInfrared: string;
    sensorIris: string;
    sensorLight: string;
    sensorMagnetometer: string;
    sensorProximity: string;
    sensorThreeDimenTouch: string;
    signalAgps: string;
    signalGps: string;
    testPassRate: string;
    uuid: string;
    reportDate: string;
    feature: string;
    journeyType: string;
}

export enum TestResult {
    PASSED = "Passed",
    FAILED = "Failed",
    SKIPPED = "Skipped",
    INDETERMINATE = "Indeterminate",
    ERRORS = "Errors",
}

export enum Status {
    SUCCEEDED = "SUCCEEDED",
    WARNING = "WARNING",
    FAILED = "FAILED",
    STARTED = "STARTED",
}

export interface DeviceDetail {
    id?: string;
    make?: string;
    model?: string;
    modelName?: string;
    color?: string;
    imei?: string;
    imei2?: string;
    serialNumber?: string;
    carrier?: string;
    capacity?: string;
    operatingSystem?: string;
    location?: string;
    timezone?: string;
    softwareVersion?: string;
    tagCode?: string;
    licenseKeyIdentifier?: string;
    storeName?: string;
}

export interface DiagnosticSummary {
    test: string;
    pass: number;
    fail: number;
    skip: number;
    indeterminate: number;
    latestResult: string;
    lastAttemptDate: string;
    reasonCode?: string;
}

export interface ConditionalQuestions {
    report_date: string;
    results: ConditionalQuestionsResults[];
}

export interface ConditionalQuestionsResults {
    question: string;
    answer: string;
}

export interface BuyBackAndTradeIn {
    estimate?: Estimate;
    quote?: Quote;
}

export interface Results {
    result: string | boolean;
    name: string;
}

export interface Images {
    preSignedUrl: string;
    s3_key: string;
    label: string;
    source_url: string;
}

export interface MirrorTest {
    images: Images[];
    session_id: string;
    error_code?: number;
    error?: string;
    results: Results[];
    timestamp: string;
}

export interface ScreenAnalysis {
    fraudRisk: string;
    mirrorTest: MirrorTest[];
}

export interface NtfResult {
    faultCode: string;
    history: NtfHistory[];
}

export interface NtfHistory {
    faultFound: boolean;
    description: string;
    reportDate: string;
}

export interface OtherDetails {
    faultFound: boolean;
    store: string;
    referenceCode: string;
}

export interface Ntf {
    results: NtfResult[];
    otherDetails: OtherDetails;
}

export interface Estimate {
    eligible: boolean | undefined;
    price: number;
    date: string;
    currency: string;
}

export interface Quote {
    date: string;
    tradeId: string;
    eligible?: boolean;
    price: number;
    expiration: string;
    currency: string;
    channel: string;
}

export interface Price {
    date: string;
    price: number;
    currency: string;
    currencySymbol: string;
    priceType: string;
    expiration: string;
    productName: string;
    productCode: string;
    punchout: string;
    coverage: string[];
    eligible?: boolean;
}

export interface Insurance {
    price?: Price;
}

export interface DiagnosticReportResponse extends TableData {
    deviceDetail: DeviceDetail;
    diagnosticSummary: DiagnosticSummary[];
    conditionalQuestions: ConditionalQuestions[];
    buybackTradein: BuyBackAndTradeIn;
    screenAnalysis: ScreenAnalysis;
    insurance: Insurance;
    ntf: Ntf;
}

export const reportSections = [
    "CUSTOM_FIELDS",
    "ERASURE_RESULT",
    "HARDWARE_DETAILS",
    "BATTERY_INFORMATION",
    "DEVICE_COMPONENTS",
    "HARDWARE_FUNCTIONAL_CHECKS",
    "SOFTWARE_INFORMATION",
    "REPORT_DETAILS",
    "REPORT_SIGNATURE",
] as const;

export type ReportSection = typeof reportSections[number];

export enum ReportType {
    ADVANCED = "ADVANCED",
    STANDARD = "STANDARD",
}

export interface ReportTemplate {
    uuid: string;
    templateName: string;
    reportType: ReportType;
    logoVisible: boolean;
    disclaimer: string;
    sections: ReportSection[];
    version?: number;
    disclaimerColor?: string;
}

export interface TemplateTableData extends TableData, ReportTemplate {
    createdDate: string;
    editedDate: string;
    createdBy: string;
    editedBy: string;
    defaultReportTemplate: DefaultReportTemplate;
}

export const BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID = "default";

interface CommonCustomReportView {
    uuid: string;
    name: string;
    columns: string[];
    shared: boolean;
    own: boolean;
}

export interface CustomReportView extends CommonCustomReportView {
    filters?: CustomReportViewFilterGroup;
}

export interface CustomReportViewDto extends CommonCustomReportView {
    filters?: CustomReportViewFilterGroupDto;
}

export interface JobDetail {
    filename: string;
    status: string;
    timestamp: number;
    tenantUuid: string;
    userUuid: string;
    totalReportsProcessed: number;
    presignedUrl: string;
    totalReportsExported: number;
    totalReports: number;
    creationDate: string;
    format: string;
    jobUuid: string;
    linkExpirationDate: string;
    totalFailures: number;
}

export type JobDetailDto = SnakeCasedPropertiesDeep<JobDetail>;

export type InvalidFilterReason = "EMPTY" | "CONTAINS_WILDCARD";

export interface FieldsData {
    fields: {
        name: string;
        oldValue: string;
        newValue: string;
    }[];
}

export type FieldsDataDto = SnakeCasedPropertiesDeep<FieldsData>;

export function toFieldsDataDto(fieldsData: FieldsData): FieldsDataDto {
    return {
        fields: fieldsData.fields.map((e) => ({
            name: e.name,
            old_value: e.oldValue,
            new_value: e.newValue,
        })),
    };
}

export interface CustomFieldsDto {
    name: string;
    value: string;
}

export interface CustomField {
    name: string;
    value: string;
}

export interface CustomFieldsDataDto {
    custom_fields: CustomFieldsDto[];
}

export interface CustomFieldsData {
    customFields: CustomField[];
}

export function toCustomFieldsData(dto: CustomFieldsDataDto): CustomFieldsData {
    return {
        customFields: dto.custom_fields,
    };
}

export const REPORT_TAG_ROOT = "root";
export const REPORT_TAG_REPORT = "report";
export const REPORT_TAG_BLANCCO_DATA = "blancco_data";
export const REPORT_TAG_DESCRIPTION = "description";
export const REPORT_TAG_DOCUMENT_ID = "document_id";
export const REPORT_TAG_DOCUMENT_LOG = "document_log";
export const REPORT_TAG_LOG_ENTRY = "log_entry";
export const REPORT_TAG_AUTHOR = "author";
export const REPORT_TAG_PRODUCT_NAME = "product_name";
export const REPORT_TAG_PRODUCT_VERSION = "product_version";
export const REPORT_TAG_PRODUCT_REVISION = "product_revision";
export const REPORT_TAG_DATE = "date";
export const REPORT_TAG_USER_DATA = "user_data";

export const REPORT_ENTRY_TYPE_STRING = "string";
export const REPORT_ENTRY_TYPE_UINT = "uint";
export const REPORT_ENTRY_ATTRIBUTE_TYPES = [REPORT_ENTRY_TYPE_STRING, REPORT_ENTRY_TYPE_UINT] as const;
export type ReportEntryAttributeType = typeof REPORT_ENTRY_ATTRIBUTE_TYPES[number];

export const REPORT_DESCRIPTION_ENTRIES_NAME = "description_entries";

export enum Path {
    DATE = "date",
    ERASURE_STATE = "blancco_erasure_report.erasures.erasure.state",
    JOURNEY_TYPE = "journey_type",
    PRODUCT_ID = "report.product_id",
    PRODUCT_NAME = "report.product_name",
    PRODUCT_REVISION = "report.product_revision",
    PRODUCT_VERSION = "report.product_version",
    REPORT_DATE = "report.report_date",
    UUID = "report.uuid",
    REPORT_OWNER = "report.user_uuid",
    VERIFIED = "blancco_data.description.description_entries.verified",
    NTF_RESULT_FAULT_CODE = "report_details.ntf.results.fault_code",
    NTF_RESULT_FAULT_FOUND = "report_details.ntf.results.history.fault_found",
    NTF_RESULT_REPORT_DATE = "report_details.ntf.results.history.report_date",
    NTF_OVERALL_REFERENCE_CODE_EXPIRATION = "report_details.ntf.results.history.reference_code_expiration",
    NTF_OVERALL_FAULT_FOUND = "report_details.ntf.overall_result.fault_found",
}

interface WithLogicOperatorDto {
    logic_operator?: CustomReportViewFilterLogicOperator;
}

interface WithLogicOperator {
    logicOperator?: CustomReportViewFilterLogicOperator;
}

interface CommonCustomReportViewFilter {
    name: string;
    value: string;
    operator: ReportViewFilterOperator;
}

export type CustomReportViewFilterLogicOperator = "AND" | "OR";

function isFilterGroupDto(
    entity: CustomReportViewFilterGroupDto | CustomReportViewFilterDto
): entity is CustomReportViewFilterGroupDto {
    return (entity as CustomReportViewFilterGroupDto).filters != null;
}

export function isFilterGroup(
    entity: CustomReportViewFilterGroup | CustomReportViewFilter
): entity is CustomReportViewFilterGroup {
    return (entity as CustomReportViewFilterGroup).filters != null;
}

export interface CustomReportViewFilterGroupDto extends WithLogicOperatorDto {
    filters?: (CustomReportViewFilterDto | CustomReportViewFilterGroupDto)[];
}

export interface CustomReportViewFilterGroup extends WithLogicOperator {
    filters?: (CustomReportViewFilter | CustomReportViewFilterGroup)[];
}

export interface CustomReportViewFilterDto extends CommonCustomReportViewFilter, WithLogicOperatorDto {
    path_type: string;
}

export interface CustomReportViewFilter extends CommonCustomReportViewFilter, WithLogicOperator {
    pathType: string;
}

export function toCustomReportViewFilterDto(filter: CustomReportViewFilter): CustomReportViewFilterDto {
    return {
        name: filter.name,
        value: filter.value,
        operator: filter.operator,
        path_type: filter.pathType,
    };
}

export function toCustomReportViewFilterGroupDto(
    group?: CustomReportViewFilterGroup
): CustomReportViewFilterGroupDto | undefined {
    if (group == null) {
        return undefined;
    }
    const dto = {
        logic_operator: group.logicOperator,
    };
    if (group.filters == null) {
        return dto;
    }
    const dtoFilters: (CustomReportViewFilterDto | CustomReportViewFilterGroupDto)[] = [];
    for (const each of group.filters) {
        if (isFilterGroup(each)) {
            const groupDto = toCustomReportViewFilterGroupDto(each);
            if (groupDto != null) {
                dtoFilters.push(groupDto);
            }
            continue;
        }
        const filterDto: CustomReportViewFilterDto = {
            logic_operator: each.logicOperator,
            operator: each.operator,
            value: each.value,
            name: each.name,
            path_type: each.pathType,
        };
        dtoFilters.push(filterDto);
    }
    return { ...dto, filters: dtoFilters };
}

export function toReportViewFilters(
    dto: CustomReportViewFilterGroupDto,
    pathToType: Map<string, PathType>
): CustomReportViewFilterGroup {
    if (dto.filters == null) {
        return {};
    }
    const filters: (CustomReportViewFilterGroup | CustomReportViewFilter)[] = [];
    for (const each of dto.filters) {
        if (isFilterGroupDto(each)) {
            filters.push(toReportViewFilters(each, pathToType));
        } else {
            filters.push({
                name: each.name,
                pathType: each.path_type ?? pathToType.get(each.name),
                value: each.value,
                operator: toFilterOperator(each.operator),
                logicOperator: each.logic_operator,
            });
        }
    }
    return {
        filters: filters,
        logicOperator: dto.logic_operator,
    };
}
