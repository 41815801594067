import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { ProductToRates } from "./LicensesTable";
import style from "./token-pricing-tier-view.scss";
import Modal from "components/modal/Modal";
import StaticTable from "components/support/api-guide/StaticTable";
import tierStyle from "components/tenants/pricing-tier-view.scss";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    visibility: boolean;
    setVisibility: (visibility: boolean) => void;
    productToRate: ProductToRates[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const BundleTokenPricingView = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={props.visibility}
            hideModal={() => {
                props.setVisibility(false);
            }}
            modalTitle={t("Licenses.tokenRateDialogTitle")}
        >
            <div className={style.model}>
                {t("Licenses.licenseRatesDescription")}
                <div className={style.tableContainer}>
                    <StaticTable
                        tableClass={tierStyle.table}
                        headers={[
                            {
                                className: tierStyle.firstColumnHeader,
                                value: t("Licenses.licenses"),
                            },
                            {
                                className: tierStyle.firstColumnHeader,
                                value: t("Common.rate"),
                            },
                        ]}
                        cells={props.productToRate.map((each) => {
                            return [
                                <div key={each.productId} className={style.label}>
                                    {each.productId}
                                </div>,
                                <div key={each.productId} className={style.label}>
                                    {each.rate}
                                </div>,
                            ];
                        })}
                        testId={testIds.workArea.license.bundlesWithTokenDialog.table.itself}
                    />
                </div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(
                            buttons.medium,
                            buttons.primaryButton,
                            buttons.okButton,
                            buttons.buttonWithoutIcon
                        )}
                        type="reset"
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={() => {
                            props.setVisibility(false);
                        }}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
export default connector(BundleTokenPricingView);
