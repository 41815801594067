import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./confirmation-modal.scss";
import Modal from "components/modal/Modal";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

export interface Props {
    confirmCancelVisible?: boolean;
    setShowConfirmCancelDialog?: (value: boolean) => void;
    onCancel: (value: boolean) => void;
    onUpdateParentView: () => void;
}

const ConfirmationModal = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={props.confirmCancelVisible ?? false}
            hideModal={() => {
                props.onCancel(false);
                props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
            }}
            modalTitle={t("ManageWorkflowDialog.confirmCancelEditingDialog.title")}
        >
            <p className={style.cancelEditMessage}>
                {t("ManageWorkflowDialog.confirmCancelEditingDialog.confirmationText")}
            </p>
            <div className={style.buttonContainer}>
                <button
                    className={classNames(buttons.deleteButton, buttons.medium, buttons.buttonWithoutIcon)}
                    onClick={() => {
                        props.onCancel(true);
                        props.onUpdateParentView();
                    }}
                    data-testid={testIds.common.confirmationDialog.confirmButton}
                >
                    {t("ManageWorkflowDialog.confirmCancelEditingDialog.closeDontSaveButton")}
                </button>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                    onClick={() => {
                        props.onCancel(false);
                        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
                    }}
                    data-testid={testIds.common.dialog.closeButton}
                >
                    {t("ManageWorkflowDialog.confirmCancelEditingDialog.keepEditingButton")}
                </button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
