import classNames from "classnames";
import * as React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import ReactTooltip from "react-tooltip";

import deliveryStyle from "./add-license-delivery.scss";
import {
    createLicenseTypes,
    FEATURE_TENANT_MANAGEMENT,
    isExpirationDateValid,
    NON_DELIVERABLE_FEATURE_LICENSES,
} from "components/licenses/common";
import AddLicenseDeliveryForm from "components/licenses/delivery-history/AddLicenseDeliveryForm";
import { DeliveryForm, License } from "components/licenses/delivery-history/DeliveryFormContent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { generateSelectableLicenses, isTokenLicense } from "components/tenants/add-tenant/AddTenantForm";
import { LicenseToDeliver, MAX_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel, TenantType } from "domain/tenants";
import { FEATURE_LICENSES, FeatureLicenseType, toFeatureLicenseType } from "domain/users";
import { LicenseResponse, licenseService, ProductToRateList } from "services/licenses/LicenseService";
import { getTenantName, hasSubTenantCookie, isUserParentInternal } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import { updateTenantDetails } from "store/tenantDetails";
import { setUser } from "store/user";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { formatDateWithoutTime, formatIsoDate } from "utils/format";

interface Result {
    title: string;
    message: string;
    succeeded: boolean;
}

const mapState = (state: StoreState) => ({
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    tenants: state.tenantDetailsReducer.stack,
});
const connector = connect(mapState, { setUser, updateTenantDetails });

const AddLicenseDeliveryView = (
    props: {
        onLicenseAdded: () => void;
        tenantUuid?: string;
        tenantName?: string;
        onHideModal?: () => void;
        tenantTier: CombinedTier;
        tenantType: TenantType;
        licensingModel: LicensingModel;
        allowed: boolean;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({ title: "", message: "", succeeded: false });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [addLicenseFormVisible, setAddLicenseFormVisible] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);
    const LICENSE_TYPES = createLicenseTypes(true);
    const [licenses, setLicenses] = React.useState<License[]>([]);
    const [loadingOnFetch, setLoadingOnFetch] = React.useState(false);
    const [selectedLicenses, setSelectedLicenses] = React.useState<LicenseToDeliver[]>([]);
    const [failedToFetchLicenses, setFailedToFetchLicenses] = React.useState(false);
    const [loadingOnCreate, setLoadingOnCreate] = React.useState(false);
    const [ownedLicenses, setOwnedLicenses] = React.useState<License[]>([]);
    const [rateVersions, setRateVersions] = React.useState<ProductToRateList[]>([]);

    const deliverableLicenseTypes = LICENSE_TYPES.filter(
        (element) => !NON_DELIVERABLE_FEATURE_LICENSES.includes(element.productId)
    );

    const addLicenseSubmitEventHandler = async (
        { deliveryType, caseNumber, notes, opportunityId, tokenRateVersion }: DeliveryForm,
        licensesToDeliver: LicenseToDeliver[]
    ): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoadingOnCreate(true);
        await licenseService
            .createLicenseDelivery(
                {
                    licenses: licensesToDeliver,
                    notes: notes,
                    caseNumber: caseNumber,
                    opportunityId: opportunityId,
                    type: deliveryType,
                    tenantUuid: props.tenantUuid,
                    tokenRateVersion: tokenRateVersion,
                },
                abortController,
                props.tenantDetails.region
            )
            .then(() => {
                setSelectedLicenses(licensesToDeliver);
                showResult({
                    title: t("DeliveryHistory.addLicenseDelivery.successTitle"),
                    message: t("DeliveryHistory.addLicenseDelivery.successMessage", {
                        customerName: props.tenantUuid == null ? getTenantName() : props.tenantName,
                    }),
                    succeeded: true,
                });
                setAddLicenseFormVisible(false);
            })
            .catch(() => {
                setAddLicenseFormVisible(false);
                showResult({
                    title: t("DeliveryHistory.addLicenseDelivery.failureTitle"),
                    message: t("DeliveryHistory.addLicenseDelivery.failureMessage"),
                    succeeded: false,
                });
                setAddLicenseFormVisible(true);
            })
            .finally(() => {
                setLoadingOnCreate(false);
                setLoadingOnFetch(false);
            });
        props.onLicenseAdded();
    };

    const showResult = (resultToShow: Result) => {
        setResultVisible(true);
        setResult(resultToShow);
    };

    const hideSuccessDialog = () => {
        setResultVisible(false);
        if (props.tenantUuid == null) {
            const filteredFeatureLicenses: LicenseToDeliver[] = selectedLicenses.filter((license) =>
                FEATURE_LICENSES.includes(toFeatureLicenseType(license.licenseId))
            );
            const featureLicenses: FeatureLicenseType[] = filteredFeatureLicenses.map((license) =>
                toFeatureLicenseType(license.licenseId)
            );
            props.updateTenantDetails({
                uuid: props.tenantDetails.uuid,
                featureLicenses: props.tenantDetails.featureLicenses.concat(featureLicenses),
                type: props.tenantDetails.type,
                tenantName: props.tenantDetails.tenantName,
                region: props.tenantDetails.region,
                tenantTier: props.tenantTier,
                licensingModel: props.tenantDetails.licensingModel,
            });
            props.onLicenseAdded();
        }
        setAddLicenseFormVisible(false);
        props.onHideModal?.();
    };

    const hideAddLicenseFormDialog = () => {
        setAddLicenseFormVisible(false);
        props.onHideModal?.();
    };

    const openForm = () => {
        fetchLicensesAndOrRateVersionFromApi();
    };
    const listAddedLicenses = (): JSX.Element => {
        return result.succeeded ? (
            <div className={form.resultContainer}>
                <ul>
                    {selectedLicenses.map((each) => {
                        const product = deliverableLicenseTypes.find((license) => license.productId === each.licenseId);
                        return (
                            <li key={each.licenseId}>
                                {t("DeliveryHistory.addLicenseDelivery.license", {
                                    amount: each.totalOfLicenses,
                                    productName: product?.productName,
                                    expirationDate: formatDateWithoutTime(each.expirationDate),
                                })}
                            </li>
                        );
                    })}
                </ul>
            </div>
        ) : (
            <></>
        );
    };
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, [licenses]);

    React.useEffect(() => {
        if (props.tenantUuid != null) {
            fetchLicensesAndOrRateVersionFromApi();
        }
    }, []);

    function fetchLicensesAndOrRateVersionFromApi() {
        if (props.licensingModel === LicensingModel.BUNDLE_WITH_TOKEN) {
            fetchLicensesAndRateVersion();
        } else {
            fetchLicenses();
        }
    }

    function fetchLicenses() {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoadingOnFetch(true);
        setAddLicenseFormVisible(true);
        licenseService
            .fetchLicenses(abortController, undefined, undefined, props.tenantUuid)
            .then((fetchedLicenses) => {
                const effectiveLicenses = deriveEffectiveLicenses(
                    fetchedLicenses,
                    deliverableLicenseTypes,
                    props.tenantTier,
                    props.licensingModel,
                    props.tenantType
                );
                setOwnedLicenses(effectiveLicenses.foundLicenses);
                setLicenses(effectiveLicenses.allLicenses);
                setLoadingOnFetch(false);
            })
            .catch(() => {
                setFailedToFetchLicenses(true);
                setLoadingOnFetch(false);
            });
    }

    function fetchLicensesAndRateVersion() {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoadingOnFetch(true);
        setAddLicenseFormVisible(true);
        const fetchLicenses = fetchLicensesFromApi(abortController, props.tenantUuid);
        const fetchRateVersions = fetchRateVersionsFromApi(abortController);
        Promise.all([fetchLicenses, fetchRateVersions])
            .then(([fetchedLicenses, rateVersions]) => {
                const effectiveLicenses = deriveEffectiveLicenses(
                    fetchedLicenses,
                    deliverableLicenseTypes,
                    props.tenantTier,
                    props.licensingModel,
                    props.tenantType
                );
                setOwnedLicenses(effectiveLicenses.foundLicenses);
                setLicenses(effectiveLicenses.allLicenses);
                setRateVersions(rateVersions);
                setLoadingOnFetch(false);
            })
            .catch(() => {
                setFailedToFetchLicenses(true);
                setLoadingOnFetch(false);
            });
    }

    const licenseDeliveryOpenModal = (
        <>
            <Modal
                isOpen={addLicenseFormVisible}
                hideModal={hideAddLicenseFormDialog}
                modalTitle={t("DeliveryHistory.addLicenseDelivery.title", {
                    customer: props.tenantUuid == null ? getTenantName() : props.tenantName,
                })}
            >
                <div>
                    {loadingOnFetch || loadingOnCreate ? (
                        <LoadingIndicator />
                    ) : failedToFetchLicenses ? (
                        t("DeliveryHistory.addLicenseDelivery.failedToLoadLicenses")
                    ) : (
                        <AddLicenseDeliveryForm
                            availableLicenses={licenses}
                            submitEventHandler={addLicenseSubmitEventHandler}
                            ownedLicenses={ownedLicenses}
                            preselectedFromBundle={isUserParentInternal() && props.tenantType === "CHANNEL"}
                            licensingModel={props.licensingModel}
                            rateVersions={rateVersions}
                            tenantTier={props.tenantTier}
                            tenantType={props.tenantType}
                        />
                    )}
                </div>
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideSuccessDialog} modalTitle={result.title}>
                <div className={form.resultContainer}>{result.message}</div>
                {listAddedLicenses()}
                <div className={form.okButtonContainer}>
                    <button
                        className={classNames(
                            buttonsStyle.primaryButton,
                            buttonsStyle.medium,
                            buttonsStyle.buttonWithoutIcon,
                            buttonsStyle.okButton
                        )}
                        onClick={hideSuccessDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
    const deliveryButtonId = "deliveryButtonInView";

    function createButton() {
        return (
            <button
                className={classNames(buttonsStyle.primaryButton, buttonsStyle.small, buttonsStyle.buttonWithoutIcon, {
                    [buttonsStyle.disabledButton]: !props.allowed,
                })}
                disabled={!props.allowed}
                onClick={openForm}
                id={deliveryButtonId}
                data-for={deliveryButtonId}
                data-tip
            >
                {t("DeliveryHistory.addLicenseDelivery.button")}
            </button>
        );
    }

    return (
        <div>
            {showTooltipForDeliveryButton(props.allowed, t, deliveryButtonId)}
            {props.tenantUuid == null ? createButton() : null}
            {licenseDeliveryOpenModal}
        </div>
    );
};

interface EffectiveLicenses {
    allLicenses: License[];
    foundLicenses: License[];
}

function fetchLicensesFromApi(abortController: AbortController, tenantUuid?: string) {
    return licenseService.fetchLicenses(abortController, undefined, undefined, tenantUuid);
}

function fetchRateVersionsFromApi(abortController: AbortController) {
    return licenseService.fetchTokenRates(abortController);
}

export function createLicense(
    licenseType: string,
    available: number,
    productName: string,
    expirationDate: string,
    index: number,
    parentAvailableAmount: number,
    licensesToAdd: number,
    parentLicenseExpiration: string | null
): License {
    return {
        index: index,
        licenseType: licenseType,
        available: available,
        licensesToAdd: licensesToAdd,
        totalOfLicenses: available + licensesToAdd,
        expirationDate: expirationDate,
        productName: productName,
        parentAvailableAmount: parentAvailableAmount,
        parentLicenseExpirationDate: parentLicenseExpiration,
    };
}

function createFilterCondition(licensingModel: LicensingModel, licenseType: string) {
    const withoutDeliverables = !NON_DELIVERABLE_FEATURE_LICENSES.includes(licenseType);
    if (licensingModel === LicensingModel.OLD_MODEL) {
        return withoutDeliverables && !isTokenLicense(licenseType);
    }
    return withoutDeliverables;
}

export function deriveEffectiveLicenses(
    data: LicenseResponse,
    deliverableLicenseTypes: { productName: string; productId: string }[],
    tierType: CombinedTier,
    licensingModel: LicensingModel,
    tenantType: TenantType
): EffectiveLicenses {
    const allLicenses: License[] = [];
    const foundLicenses: License[] = [];
    const allowedLicenses: { productName: string; productId: string }[] = [];
    generateSelectableLicenses(allowedLicenses, tierType, licensingModel, tenantType);
    const allowedProductIds: string[] = allowedLicenses.map((each) => each.productId);
    data.parentLicenses
        .filter((license) => createFilterCondition(licensingModel, license.type))
        .forEach((tenantLicense) => {
            const remainingLicenses =
                tenantLicense.type === FEATURE_TENANT_MANAGEMENT
                    ? tenantLicense.assigned - tenantLicense.used - 1
                    : tenantLicense.assigned - tenantLicense.used; // Keep 1 tenant management license for own use
            if (!isExpirationDateValid(tenantLicense.expiration, MAX_DATE)) {
                return;
            }

            const productName = deliverableLicenseTypes.find(
                (each) => each.productId === tenantLicense.type
            )?.productName;
            const foundLicense = data.licenses.find((each) => each.type === tenantLicense.type);
            if (foundLicense === undefined) {
                if (allowedProductIds.includes(tenantLicense.type)) {
                    const expirationDate =
                        isUserParentInternal() && !hasSubTenantCookie()
                            ? formatIsoDate(new Date())
                            : tenantLicense.expiration;
                    allLicenses.push(
                        createLicense(
                            tenantLicense.type,
                            0,
                            productName || tenantLicense.type,
                            expirationDate,
                            allLicenses.length,
                            remainingLicenses,
                            1,
                            tenantLicense.expiration
                        )
                    );
                }
            } else {
                const licenseToAdd = createLicense(
                    foundLicense.type,
                    foundLicense.available,
                    productName || foundLicense.product,
                    foundLicense.expirationDate,
                    allLicenses.length,
                    remainingLicenses,
                    0,
                    tenantLicense.expiration
                );
                allLicenses.push(licenseToAdd);
                foundLicenses.push(licenseToAdd);
            }
        });
    return { allLicenses: allLicenses.sort((a, b) => (a.productName < b.productName ? -1 : 1)), foundLicenses };
}

export function allowDelivery(
    parentType: TenantType,
    parentModel: LicensingModel,
    kidModel: LicensingModel,
    parentTier: CombinedTier,
    kidTier: CombinedTier
) {
    if (parentType === "INTERNAL") {
        return true;
    }
    if (parentModel !== kidModel) {
        return false;
    }
    return kidModel === LicensingModel.OLD_MODEL || parentType === "CHANNEL" || parentTier === kidTier;
}

export function showTooltipForDeliveryButton(allowed: boolean, t: TFunction, buttonId: string) {
    if (allowed) {
        return <></>;
    }
    return (
        <ReactTooltip id={buttonId} className={deliveryStyle.deliveryButtonTooltip} effect="solid">
            {t("DeliveryHistory.addLicenseDelivery.tooltipForDisabled")}
        </ReactTooltip>
    );
}

export default connector(AddLicenseDeliveryView);
