import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import style from "./license-keys-table.scss";
import { ShortUrlTableCell } from "components/licenses/ShortUrlTableCell";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_LICENSE_POOL_VIEW } from "domain/authority";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { Key, KeyCursor, Keys } from "domain/licenses";
import { hasAnyBmsLicenses } from "services/licenses/licenseRepository";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import {
    setHasBmsAllLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsLeaseLicenses,
    setHasBmsLicenses,
    setHasBmsNtfLicenses,
    setHasBmsValidationLicenses,
    setHasUsdkLicenses,
} from "store/license";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    licenseKey: Key[];
    cursor: KeyCursor | null;
    scrollPosition?: number;
}

interface Props {
    initialLicensekeys: Keys;
    requestFailureMessage: string;
    scrollPosition?: number;
    count: number;
    setExportButtonVisibility: (visible: boolean) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
});
const connector = connect(mapState, {
    setHasBmsLicenses,
    setHasBmsLeaseLicenses,
    setHasBmsValidationLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasUsdkLicenses,
    setHasBmsNtfLicenses,
    setHasBmsAllLicenses,
});

const LicenseKeysTable = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const licenseTypes: Map<string, string> = new Map([
        ["bms-validation", "Validation"],
        ["bms-bbti", "Buy-Back / Trade In"],
        ["bms-lease", "Lease"],
        ["bms-insurance", "Insurance"],
        ["bms-ntf", "My Device Health"],
        ["bms-all", "All Features"],
        ["usdk", "Mobile Software Development Kit (SDK)"],
    ]);

    const createColumns = () => {
        const columns: Array<Column<Key>> = [
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.licenseKey")} key="licenseKey" />,
                accessor: "licenseKey",
                Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.license")} key="licenseType" />,
                accessor: "licenseType",
                Cell: ({ cell: { value } }) => (
                    <TextWithTooltip text={licenseTypes.has(value) ? (licenseTypes.get(value) as string) : ""} />
                ),
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.identifier")} key="identifier" />,
                accessor: "identifier",
                Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.assigned")} key="assigned" />,
                accessor: "assigned",
                Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.used")} key="used" />,
                accessor: "used",
                Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.remaining")} key="remaining" />,
                accessor: "remaining",
                Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.startDate")} key="startDate" />,
                accessor: "startDate",
                Cell: ({ cell: { value } }) => <DateCell withoutTime={true} tooltip={true} value={value} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.expirationDate")} key="expirationDate" />,
                accessor: "expirationDate",
                Cell: ({ cell: { value } }) => <DateCell withoutTime={true} tooltip={true} value={value} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.usageHoursLimit")} key="usageHoursLimit" />,
                accessor: "usageHoursLimit",
                Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
            },
            {
                Header: () => <TextWithTooltip text={t("LicenseKeys.shortUrl")} key="shortUrl" />,
                accessor: "shortUrl",
                width: 230,
                Cell: (cellInfo) => <ShortUrlTableCell shortUrl={cellInfo.cell.row.original.shortUrl} />,
            },
        ];
        userSessionService.userHasAllAuthorities([AUTH_LICENSE_POOL_VIEW])
            ? columns.push({
                  Header: () => <TextWithTooltip text={t("LicensePools.view.poolName")} />,
                  accessor: "poolName",
                  Cell: ({ row }) => <TextWithTooltip text={row.original.poolName} />,
              })
            : null;
        return columns;
    };
    const columns = createColumns();
    const [state, setLicensesData] = React.useState<TableState>({
        licenseKey: props.initialLicensekeys.licenseKeys,
        cursor: props.initialLicensekeys.cursor,
        scrollPosition: props.scrollPosition,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [fetchAllLicenses, setFetchAllLicenses] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");

    const fetchLicenses = () => {
        // const dispatch = useDispatch();
        const abortController = new AbortController();
        licenseService
            .fetchAllLicenses({ abortController: abortController, own: !hasTenantCookie() })
            .then((licenseList) => {
                const hasValidationLicenses = licenseList.licenses.licenseData.some(
                    (license) => license.type === "bms-validation"
                );
                props.setHasBmsValidationLicenses(hasValidationLicenses);
                const hasBbtiLicenses = licenseList.licenses.licenseData.some((license) => license.type === "bms-bbti");
                props.setHasBmsBbtiLicenses(hasBbtiLicenses);
                const hasInsuranceLicenses = licenseList.licenses.licenseData.some(
                    (license) => license.type === "bms-insurance"
                );
                props.setHasBmsInsuranceLicenses(hasInsuranceLicenses);
                const hasUsdkLicenses = licenseList.licenses.licenseData.some((license) => license.type === "usdk");
                props.setHasUsdkLicenses(hasUsdkLicenses);
                const hasNtfLicenses = licenseList.licenses.licenseData.some((license) => license.type === "bms-ntf");
                props.setHasBmsNtfLicenses(hasNtfLicenses);
                const hasLeaseLicenses = licenseList.licenses.licenseData.some(
                    (license) => license.type === "bms-lease"
                );
                props.setHasBmsLeaseLicenses(hasLeaseLicenses);
                const hasAllLicenses = licenseList.licenses.licenseData.some((license) => license.type === "bms-all");
                props.setHasBmsAllLicenses(hasAllLicenses);
                props.setHasBmsLicenses(hasAnyBmsLicenses());
                setFetchAllLicenses(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    console.error("Failed to fetch licenses");
                }
            });
    };

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchLicenseKeys(
                abortController,
                initialLoading ? "" : state.cursor?.licenseKey,
                initialLoading ? "" : state.cursor?.tenantIdentifier,
                search
            )
            .then((licenseKey) => {
                // First, License Keys are sorted in descending order by Start Date
                // Second, License Keys are sorted in ascending order by identifier: if identifier is null, empty string is considered for sorting
                licenseKey.licenseKeys.licenseKeys.sort(
                    (a, b) =>
                        b.startDate.localeCompare(a.startDate) || (a.identifier ?? "").localeCompare(b.identifier ?? "")
                );
                setLicensesData((prevState) => ({
                    ...prevState,
                    licenseKey: prevState.licenseKey.concat(licenseKey.licenseKeys.licenseKeys),
                    scrollPosition: prevState.licenseKey.length - 1,
                    cursor: licenseKey.licenseKeys.cursor,
                }));
                props.setExportButtonVisibility(licenseKey.licenseKeys.licenseKeys.length > 0);
                props.setHasBmsLicenses(true);
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("LicenseKeys.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialLicensekeys.licenseKeys.length > 0) {
            return;
        }
        setLicensesData({
            licenseKey: [],
            cursor: { licenseKey: "", tenantIdentifier: "" },
            scrollPosition: 0,
        });
        fetchData(true);
        if (fetchAllLicenses) {
            fetchLicenses();
        }

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, search]);

    return (
        <>
            {state.licenseKey.length > 0 ? (
                <div className={layoutStyle.aboveTable}>
                    <div className={style.recordCount}>
                        {t("Common.recordsCount", { dataCount: state.licenseKey.length })}
                    </div>
                    <div className={formStyle.search}>
                        <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                    </div>
                </div>
            ) : (
                <div className={classNames(formStyle.search, style.searchBox)}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                </div>
            )}
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.LICENSE_TABLE}
                    data={state.licenseKey}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    scrollTo={state.scrollPosition}
                    tooltips={true}
                    emptyMessage={t("LicenseKeys.emptyStateMessage", { email: SUPPORT_EMAIL })}
                    testId={testIds.workArea.license.bms.table.itself}
                />
            </div>
            {state.cursor != null &&
                state.licenseKey.length >= 100 &&
                state.licenseKey.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(
                            buttonStyle.buttonWithoutIcon,
                            buttonStyle.primaryButton,
                            buttonStyle.loadMoreButton
                        )}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                label: Label.BMS,
                                action: Action.LOAD_MORE,
                                category: Category.LICENSE,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

LicenseKeysTable.defaultProps = {
    initialLicensekeys: {
        licenseKeys: [],
        cursor: { licenseKey: "", tenantIdentifier: "" },
    },
    requestFailureMessage: "",
};

export default connector(LicenseKeysTable);
