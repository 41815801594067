import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import FlexibleModal from "components/modal/FlexibleModal";
import Modal from "components/modal/Modal";
import ReportTemplateForm from "components/reports/report-template/ReportTemplateForm";
import { ReportTemplate, TemplateTableData } from "domain/reports";
import { reportTemplateService } from "services/report/erasure/ReportTemplateService";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";

const AddReportTemplateView = (props: { templates: TemplateTableData[]; onTemplateAdded: () => void }): JSX.Element => {
    const { t } = useTranslation();
    const [formVisible, setFormVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);
    const [result, setResult] = React.useState({ title: "", message: "" });
    const [dynamicSize, setDynamicSize] = React.useState(true);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const openForm = () => {
        setDynamicSize(false);
        setFormVisible(true);
    };
    const onToggleFullscreen = () => {
        setRefreshCount(refreshCount + 1);
    };
    const manageDynamicSize = (initial: boolean) => {
        setDynamicSize(initial);
    };

    const createTemplate = (template: ReportTemplate) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoading(true);
        setDynamicSize(true);
        reportTemplateService
            .createReportTemplate(template, abortController)
            .then(() => {
                setResult({
                    title: t("ReportTemplate.confirmationDialog.success.title"),
                    message: t("ReportTemplate.confirmationDialog.success.message", {
                        templateName: template.templateName,
                    }),
                });
                props.onTemplateAdded();
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("ReportTemplate.confirmationDialog.failed.title"),
                        message: t("ReportTemplate.confirmationDialog.failed.message"),
                    });
                }
            })
            .finally(() => {
                setLoading(false);
                setDynamicSize(false);
                setFormVisible(false);
                setResultVisible(true);
            });
    };

    const hideSuccessDialog = () => {
        setResultVisible(false);
    };
    React.useEffect(() => {
        setDynamicSize(true);
        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, [props.onTemplateAdded]);
    return (
        <div>
            <button
                className={classNames(buttonsStyle.primaryButton, buttonsStyle.small, buttonsStyle.buttonWithoutIcon)}
                onClick={openForm}
            >
                {t("ReportTemplate.form.button")}
            </button>
            <FlexibleModal
                key={1}
                isOpen={formVisible}
                hideModal={() => setFormVisible(false)}
                title={t("ReportTemplate.form.addTemplateTitle")}
                onToggleFullscreen={onToggleFullscreen}
                dynamicSize={dynamicSize}
                fullscreenIcon={true}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <ReportTemplateForm
                        templates={props.templates}
                        submitEventHandler={createTemplate}
                        onShowModal={setFormVisible}
                        hasDynamicSize={manageDynamicSize}
                    />
                )}
            </FlexibleModal>
            <Modal isOpen={resultVisible} hideModal={hideSuccessDialog} modalTitle={result.title}>
                <div className={form.resultContainer}>{result.message}</div>
                <div className={form.buttonContainer}>
                    <button
                        className={classNames(
                            buttonsStyle.primaryButton,
                            buttonsStyle.medium,
                            buttonsStyle.buttonWithoutIcon,
                            buttonsStyle.okButton
                        )}
                        onClick={hideSuccessDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AddReportTemplateView;
