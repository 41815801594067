import classNames from "classnames";
import { ErrorMessage, Formik, Form as FormikForm, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import style from "./email-report-view-form.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { EMAIL_MAX_LENGTH } from "domain/globalConstants";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    date: string;
    emailAddress?: string;
    pdf?: boolean;
    xml?: boolean;
    templateUuid?: string;
    language?: string;
    subject?: string;
    emailBody?: string;
    title?: string;
    handleSubmit: (values: FormValues) => Promise<void>;
    handleCancel: () => void;
}

export interface FormValues {
    emailAddress: string;
    pdf: boolean;
    xml: boolean;
    templateUuid: string;
    language: string;
    subject: string;
    emailBody: string;
}
const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
}));

const EmailReportViewForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [formatPdf, setFormatPdf] = React.useState(true);
    const [formatXml, setFormatXml] = React.useState(true);
    return (
        <div className={style.model}>
            <Formik
                initialValues={{
                    emailAddress: props.user?.email ?? "",
                    pdf: formatPdf,
                    xml: formatXml,
                    templateUuid: props.templateUuid ?? "",
                    language: props.language ?? "",
                    subject: props.title ?? "",
                    emailBody: t("ErasureReport.sendEmail.mainBody") ?? "",
                }}
                onSubmit={(view) => props.handleSubmit(view)}
                validationSchema={object().shape({
                    emailAddress: string()
                        .required(t("ErasureReport.sendEmail.emailRequired"))
                        .email(t("Common.invalidEmail"))
                        .max(EMAIL_MAX_LENGTH),
                })}
                validateOnChange={false}
                validateOnBlur={true}
            >
                {({
                    isSubmitting,
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                }: FormikProps<FormValues>) => {
                    if (isSubmitting) {
                        return <LoadingIndicator />;
                    }
                    const handleXmlChange = (e: { target: { checked: boolean } }) => {
                        const { checked } = e.target;
                        if (checked) {
                            setFormatXml(true);
                            setFieldValue("xml", true);
                        } else {
                            setFormatXml(false);
                            setFieldValue("xml", false);
                        }
                    };
                    const handlePdfChange = (e: { target: { checked: boolean } }) => {
                        const { checked } = e.target;
                        if (checked) {
                            setFormatPdf(true);
                            setFieldValue("pdf", true);
                        } else {
                            setFormatPdf(false);
                            setFieldValue("pdf", false);
                        }
                    };

                    return (
                        <FormikForm>
                            <div>
                                <div className={form.formFields}>
                                    {t("ErasureReport.sendEmail.from")}
                                    <strong>{t("ErasureReport.sendEmail.fromEmail")}</strong>
                                </div>
                                <div className={form.formFields}>
                                    <label className={classNames(form.label)}>
                                        {t("ErasureReport.sendEmail.toLabel")}
                                    </label>
                                    <input
                                        id="emailAddress"
                                        name="emailAddress"
                                        className={classNames(form.input, form.fixedWidthInput, {
                                            [form.inputError]: errors.emailAddress,
                                        })}
                                        data-testid={testIds.workArea.report.erasure.sendEmail.toInput}
                                        maxLength={EMAIL_MAX_LENGTH}
                                        defaultValue={values.emailAddress}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <div
                                        className={form.error}
                                        data-testid={testIds.workArea.report.erasure.sendEmail.toInput.errorLabel}
                                    >
                                        <ErrorMessage name="emailAddress" />
                                    </div>
                                </div>
                                <div className={form.formFields}>
                                    <label className={classNames(form.label)}>
                                        {t("ErasureReport.sendEmail.subjectLabel")}
                                    </label>
                                    <span data-testid={testIds.workArea.report.erasure.sendEmail.subjectLabel}>
                                        {props.title}
                                    </span>
                                </div>
                                <div className={classNames(form.formFields, style.messageBody)}>
                                    <label className={classNames(form.label)}>
                                        {t("ErasureReport.sendEmail.messageLabel")}
                                    </label>
                                    <div>
                                        <p data-testid={testIds.workArea.report.erasure.sendEmail.messageLabel}>
                                            {t("ErasureReport.sendEmail.bodyHi")}
                                        </p>
                                        <p>{t("ErasureReport.sendEmail.mainBody")}</p>
                                        <p>
                                            {t("ErasureReport.sendEmail.regards")}
                                            <br />[{props.user?.name}]
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className={form.formFields}>
                                        <label className={form.label}>{t("ErasureReport.sendEmail.pdfLabel")}</label>
                                        <label className={form.container}>
                                            <input
                                                id={"pdf"}
                                                type="checkbox"
                                                name={"pdf"}
                                                defaultChecked={formatPdf}
                                                className={form.input}
                                                onChange={handlePdfChange}
                                                data-testid={testIds.workArea.report.erasure.sendEmail.pdfCheckbox}
                                            />
                                            <span className={form.checkmark} />
                                        </label>
                                    </div>

                                    <div className={form.formFields}>
                                        <label className={form.label}>{t("ErasureReport.sendEmail.xmlLabel")}</label>
                                        <label className={form.container}>
                                            <input
                                                id={"xml"}
                                                type="checkbox"
                                                name={"xml"}
                                                defaultChecked={formatXml}
                                                className={form.input}
                                                onChange={handleXmlChange}
                                                data-testid={testIds.workArea.report.erasure.sendEmail.xmlCheckbox}
                                            />
                                            <span className={form.checkmark} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(form.buttonContainer, style.padding)}>
                                <button
                                    type={"button"}
                                    className={classNames(
                                        buttons.secondaryButton,
                                        buttons.medium,
                                        buttons.buttonWithoutIcon
                                    )}
                                    data-testid={testIds.common.dialog.closeButton}
                                    onClick={props.handleCancel}
                                >
                                    {t("Common.cancel")}
                                </button>
                                <button
                                    type={"submit"}
                                    className={classNames(
                                        isSubmitting ? buttons.disabledButton : buttons.primaryButton,
                                        buttons.medium,
                                        buttons.buttonWithoutIcon
                                    )}
                                    data-testid={testIds.workArea.report.erasure.sendEmail.sendButton}
                                    disabled={isSubmitting}
                                >
                                    {t("ErasureReport.sendEmail.sendButton")}
                                </button>
                            </div>
                        </FormikForm>
                    );
                }}
            </Formik>
        </div>
    );
};

export default connector(EmailReportViewForm);
