import { FeatureLicenseType } from "domain/users";
import { ConnectedWorkflowsDto, VersionsData, Workflow, WorkflowTableData } from "domain/workflows";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export interface WorkflowDto {
    name: string;
    uuid: string;
    sequence_id: number;
    profile: string;
    created_date: string;
    user_uuid: string;
    user_name: string;
    default_workflow: DefaultWorkflow;
    workflow: Workflow;
    own: boolean;
    modified_date: string;
    modified_by_user_uuid: string;
    modified_by_username: string;
    editor_version: string;
    editor_generation: EditorGeneration;
    connected_workflows: ConnectedWorkflowsDto;
}

export interface DefaultWorkflow {
    own: boolean;
    other: OtherDefaultWorkflow;
}

export interface VersionsDto {
    versions: VersionsData[];
}

export interface DefaultWorkflowUser {
    username: string;
    name: string | null;
}

export interface OtherDefaultWorkflow {
    count: number;
    userDetails: DefaultWorkflowUser[];
}

export interface WorkflowsResponseDto {
    workflows: WorkflowDto[];
    count: number;
    cursor: string;
}

export interface ManifestWorkflowEditorDto {
    version: string;
    profile: string;
    url: string;
    editor_generation: string;
}

export interface ManifestWorkflowEditor {
    version: string;
    profile: string;
    url: string;
    editorGeneration: string;
}

export function toManifestWorkflowEditor(dto: ManifestWorkflowEditorDto) {
    return {
        version: dto.version,
        profile: dto.profile,
        url: dto.url,
        editorGeneration: dto.editor_generation,
    };
}

export interface WorkflowEditorsResponseDto {
    editors: ManifestWorkflowEditorDto[];
}

export interface FetchProfileListResponse {
    editors: ManifestWorkflowEditorDto[];
    version: string;
    timestamp: string;
    url: string;
}

export interface SuccessResponse {
    message: string;
}

export interface SaveSuccessResponse extends SuccessResponse {
    uuid: string;
    modified: string;
    modifiedBy: string;
    missingSubWorkflows: string[];
}

export interface SaveSuccessResponseDto {
    message: string;
    uuid: string;
    modified: string;
    modified_by: string;
    missing_sub_workflows?: string[];
}

export interface SetDefaultResponse extends SuccessResponse {
    oldDefaultUuid: string;
    oldDefaultName: string;
    newDefaultUuid: string;
    newDefaultName: string;
    profile: string;
}

export function toWorkflow(dto: WorkflowDto): WorkflowTableData {
    return {
        name: dto.name,
        uuid: dto.uuid,
        id: dto.sequence_id,
        product: dto.profile,
        profile: dto.profile.toLowerCase() as Profile,
        createdDate: dto.created_date,
        userUuid: dto.user_uuid,
        creator: dto.user_name,
        defaultWorkflow: dto.default_workflow,
        workflow: dto.workflow,
        own: dto.own,
        editorVersion: dto.editor_version,
        editorGeneration: dto.editor_generation,
        connectedWorkflows: dto.connected_workflows
            ? {
                  parentWorkflows: dto.connected_workflows.parent_workflows,
                  subWorkflows: dto.connected_workflows.sub_workflows,
                  runDiagnoseWorkflows: dto.connected_workflows.run_diagnose_workflows,
                  runProcessFlowWorkflows: dto.connected_workflows.run_process_flow_workflows,
                  collectWorkflows: dto.connected_workflows.collect_workflows,
                  gradingPanelWorkflows: dto.connected_workflows.grading_panel_workflows,
                  remoteWorkflows: dto.connected_workflows.remote_workflow_count,
                  totalConnectedWorkflows:
                      dto.connected_workflows.parent_workflows.length +
                      dto.connected_workflows.sub_workflows.length +
                      dto.connected_workflows.run_diagnose_workflows.length +
                      dto.connected_workflows.run_process_flow_workflows.length +
                      dto.connected_workflows.collect_workflows.length +
                      dto.connected_workflows.grading_panel_workflows.length +
                      dto.connected_workflows.remote_workflow_count,
              }
            : {
                  parentWorkflows: [],
                  subWorkflows: [],
                  runDiagnoseWorkflows: [],
                  runProcessFlowWorkflows: [],
                  collectWorkflows: [],
                  gradingPanelWorkflows: [],
                  remoteWorkflows: 0,
                  totalConnectedWorkflows: 0,
              },
    };
}

export function toSaveSuccessResponse(dto: SaveSuccessResponseDto): SaveSuccessResponse {
    return {
        message: dto.message,
        uuid: dto.uuid,
        modified: dto.modified,
        modifiedBy: dto.modified_by,
        missingSubWorkflows: dto.missing_sub_workflows ?? [],
    };
}

export enum Profile {
    ALL = "all",
    BDE = "bde",
    BMDE = "bmde",
    BMDE_DIAGNOSTIC = "bmde_diagnostic",
}

export type EditorGeneration = "V1" | "V2" | null;
export const profileToNameMap = new Map([
    [Profile.BDE, "Drive Eraser"],
    [Profile.BMDE, "Mobile Diagnostics and Erasure"],
    [Profile.BMDE_DIAGNOSTIC, "Mobile Diagnostics and Erasure - Diagnostics"],
]);

export const profileToShortNameMap = new Map([
    ["bde", ""],
    ["bmde", "Erasure"],
    ["bmde_diagnostic", "Diagnostics"],
]);

export const profileToFeatureLicenseMap = new Map<Profile, FeatureLicenseType>([
    [Profile.BDE, "FEATURE_WORKFLOW_BDE"],
    [Profile.BMDE, "FEATURE_WORKFLOW_BMDE"],
    [Profile.BMDE_DIAGNOSTIC, "FEATURE_WORKFLOW_BMDE"],
]);

const PATH_API_WORKFLOWS = "/api/workflows";
const PATH_API_WORKFLOWS_V1 = "/api/v1/workflows";
const PATH_API_USERS = "/api/users";

export class WorkflowService {
    public async deleteWorkflow(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}`, "DELETE", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async fetchWorkflowEditors(
        profile: Profile,
        abortController?: AbortController
    ): Promise<ManifestWorkflowEditor[]> {
        return apiGatewayService
            .invokeApi("/workflow-editors/" + profile + "/manifest.json", "GET", null, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.STAN,
                includeCredentials: true,
            })
            .then((dto: WorkflowEditorsResponseDto) => dto.editors.map(toManifestWorkflowEditor));
    }

    public async updateShowUpdateWorkflowsDialogStatus(uuid: string, abortController?: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(
            PATH_API_USERS + "/" + uuid,
            "POST",
            { show_workflow_update_dialog: false },
            {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.LAUREL,
                includeCredentials: true,
            }
        );
    }

    public async fetchWorkflows(
        search?: string,
        cursor?: string,
        product?: string,
        version?: string,
        from?: string,
        to?: string,
        abortController?: AbortController
    ) {
        return apiGatewayService
            .invokeApi(
                PATH_API_WORKFLOWS_V1,
                "POST",
                {
                    search: search,
                    cursor: cursor,
                    product: product,
                    version: version,
                    from: from,
                    to: to,
                },
                {
                    abortController: abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: WorkflowsResponseDto) => {
                return {
                    count: dto.count,
                    cursor: dto.cursor,
                    workflowTableData: dto.workflows.map(toWorkflow),
                };
            });
    }

    public async create(
        workflow: Workflow,
        subWorkflow?: boolean | undefined,
        abortController?: AbortController
    ): Promise<SaveSuccessResponse> {
        return apiGatewayService
            .invokeApi(
                PATH_API_WORKFLOWS,
                "POST",
                { workflow, subWorkflow },
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: SaveSuccessResponseDto) => toSaveSuccessResponse(dto));
    }

    public async update(
        uuid: string,
        workflow: Workflow,
        abortController?: AbortController
    ): Promise<SaveSuccessResponse> {
        return apiGatewayService
            .invokeApi(
                `${PATH_API_WORKFLOWS}/${uuid}`,
                "PUT",
                { workflow },
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: SaveSuccessResponseDto) => toSaveSuccessResponse(dto));
    }

    public async fetch(uuid: string, abortController?: AbortController): Promise<WorkflowDto> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}`, "GET", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async export(uuid: string, abortController?: AbortController): Promise<void> {
        apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}/export`, "GET", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
            setWindowLocation: true,
        });
    }

    public async fetchVersionList(uuid: string, abortController?: AbortController): Promise<VersionsDto> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}/versions`, "GET", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async fetchPreviouslySaveVersion(
        uuid: string,
        version: string,
        abortController?: AbortController
    ): Promise<WorkflowDto> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}/versions/${version}`, "GET", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async setDefault(uuid: string, abortController?: AbortController): Promise<SetDefaultResponse> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}/default`, "POST", null, {
            abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }
}

export const workflowService = new WorkflowService();
