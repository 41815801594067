import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./manage-user-groups.scss";
import ManageUserGroupsForm, { FormValues } from "./ManageUserGroupsForm";
import Modal from "components/modal/Modal";
import { AUTH_USER_GROUP_CREATE } from "domain/authority";
import { userGroupsService } from "services/user-groups/UserGroupsService";
import { userSessionService } from "services/user/UserSessionService";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
}

export interface Props {
    onGroupAdded: () => void;
}

const ManageUserGroupView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState(false);
    const [manageGroupFormVisible, setManageGroupFormVisible] = React.useState(false);
    const showManageGroupsForm = () => {
        setManageGroupFormVisible(true);
    };
    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };
    const hideResult = () => {
        setResultVisible(false);
        props.onGroupAdded();
    };

    const addUserGroupSubmitEventHandler = async ({
        groupName,
        backgroundColor,
        roleUuid,
        textColor,
        usersList,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        const createRequest = {
            name: groupName,
            backgroundColor: backgroundColor,
            textColor: textColor,
            abortController: abortController,
            roleUuid: roleUuid,
            usersList: usersList,
        };
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await userGroupsService.createGroup(createRequest);
        } catch (e) {
            if (!signal.aborted) {
                setManageGroupFormVisible(false);
                showResult({
                    title: t("UserGroups.manageUserGroupsView.createGroupFailure.title"),
                    message: t("UserGroups.manageUserGroupsView.createGroupFailure.message"),
                });
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setManageGroupFormVisible(false);
        showResult({
            title: t("UserGroups.manageUserGroupsView.resultTitle"),
            message: t("UserGroups.manageUserGroupsView.resultMessage", { groupName: groupName }),
        });
    };

    const createUserGroupModal = (
        <Modal
            isOpen={manageGroupFormVisible}
            hideModal={() => setManageGroupFormVisible(false)}
            modalTitle={t("UserGroups.manageUserGroupsView.title")}
        >
            <ManageUserGroupsForm submitEventHandler={addUserGroupSubmitEventHandler} />
        </Modal>
    );

    return (
        <div>
            {userSessionService.userHasAnyAuthority([AUTH_USER_GROUP_CREATE]) && (
                <button
                    className={classNames(buttons.primaryButton, buttons.small, buttons.buttonWithoutIcon)}
                    onClick={showManageGroupsForm}
                    data-testid={testIds.workArea.userGroup.addUserGroupButton}
                >
                    {t("UserGroups.manageUserGroupsView.addUserGroupButton")}
                </button>
            )}
            {createUserGroupModal}
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(
                            buttons.primaryButton,
                            buttons.medium,
                            buttons.buttonWithoutIcon,
                            buttons.okButton
                        )}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ManageUserGroupView;
