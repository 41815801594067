import classNames from "classnames";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./template-kebab-menu.scss";
import Copy from "components/icons/Copy";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import SetDefault from "components/icons/SetDefault";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_REPORT_TEMPLATE_DEFAULT, AUTH_REPORT_TEMPLATE_DELETE, AUTH_REPORT_TEMPLATE_EDIT } from "domain/authority";
import { reportTemplateService } from "services/report/erasure/ReportTemplateService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ReportTemplatesKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        name: string;
        onTemplateDelete: () => void;
        onTemplateEdit: (uuid: string) => void;
        onTemplateSetDefault: () => void;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteTemplateModalVisible, setDeleteTemplateModalVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
    });
    const [okClicked, setOkClicked] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const clipboardId = "uuid-" + props.uuid;
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [copyTooltipPosition, setCopyTooltipPosition] = React.useState("0px");
    // This value helps to position the tooltip.
    const COPY_ICON_OFFSET_POSITION = 55;
    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.uuid);
        setCopied(true);
    };
    const hideResultAndRedirectToRoot = () => {
        setResult((prevState) => ({ ...prevState, resultVisible: false }));
        props.onTemplateDelete();
    };

    const deleteTemplate = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportTemplateService
            .deleteTemplate(props.uuid, abortController)
            .then(() => {
                setDeleteTemplateModalVisible(false);
                setResult({
                    title: t("DeleteTemplate.templateDeleted.templateDeletedTitle"),
                    message: t("DeleteTemplate.templateDeleted.successMessage", { name: props.name }),
                    resultVisible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setOkClicked(false);
                    setDeleteTemplateModalVisible(false);
                    setResult({
                        title: t("DeleteTemplate.templateDeleted.templateNotDeletedTitle"),
                        message: t("DeleteTemplate.templateDeleted.failureMessage"),
                        resultVisible: true,
                    });
                }
            });
    };

    const setDefaultTemplate = (uuid: string) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportTemplateService.setDefault(uuid, abortController).then(() => {
            props.onTemplateSetDefault();
        });
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const handleTemplateDelete = () => {
        setOkClicked(true);
        deleteTemplate();
    };

    const isEditable = props.uuid !== "default";
    return (
        <div className={style.templatesIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <li>
                        <span>
                            <MenuItemButton
                                id={clipboardId}
                                onClick={copyToClipboard}
                                onMouseLeave={() => {
                                    setShowTooltip(false);
                                    setCopied(false);
                                }}
                                onMouseOver={(event) => {
                                    setShowTooltip(true);
                                    setCopyTooltipPosition(
                                        (event.nativeEvent.clientY - COPY_ICON_OFFSET_POSITION).toString() + "px"
                                    );
                                }}
                            >
                                <span
                                    hidden={!showTooltip}
                                    className={classNames(style.tooltip, style.copyToClipBoardToolTip)}
                                    style={{ top: copyTooltipPosition }}
                                >
                                    {copied ? t("Common.copied") : t("TemplatesTable.copyUuid")}
                                </span>
                                <Copy color={props.theme.iconFillColor} />
                            </MenuItemButton>
                        </span>
                    </li>
                    {isEditable && userSessionService.userHasAllAuthorities([AUTH_REPORT_TEMPLATE_EDIT]) && (
                        <li>
                            <TextWithTooltip text={t("EditTemplate.tooltipEdit")}>
                                <MenuItemButton
                                    onClick={() => {
                                        props.onTemplateEdit(props.uuid);
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORT_TEMPLATE,
                                            action: Action.EDIT_REPORT_TEMPLATE,
                                        });
                                    }}
                                >
                                    <Edit color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                    {userSessionService.userHasAllAuthorities([AUTH_REPORT_TEMPLATE_DEFAULT]) && (
                        <li>
                            <TextWithTooltip text={t("SetDefaultTemplate.tooltipSetDefault")}>
                                <MenuItemButton
                                    onClick={() => {
                                        setDefaultTemplate(props.uuid);
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORT_TEMPLATE,
                                            action: Action.SET_DEFAULT_REPORT_TEMPLATE,
                                        });
                                    }}
                                >
                                    <SetDefault color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                    {isEditable && userSessionService.userHasAllAuthorities([AUTH_REPORT_TEMPLATE_DELETE]) && (
                        <li>
                            <TextWithTooltip text={t("DeleteTemplate.tooltipDelete")}>
                                <MenuItemButton
                                    onClick={() => {
                                        setDeleteTemplateModalVisible(true);
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORT_TEMPLATE,
                                            action: Action.REMOVE_REPORT_TEMPLATE,
                                        });
                                    }}
                                >
                                    <Delete color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                </ul>
            </Menu>
            <Modal
                isOpen={deleteTemplateModalVisible}
                hideModal={() => setDeleteTemplateModalVisible(false)}
                modalTitle={t("DeleteTemplate.title")}
            >
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {deleteTemplateModalVisible ? (
                            <>
                                <div className={style.resultContainer}>
                                    {t("DeleteTemplate.introductionMessage", { name: props.name })}
                                </div>
                                <div className={classNames(style.buttonContainer, form.buttonContainer)}>
                                    <button
                                        className={classNames(
                                            buttons.secondaryButton,
                                            buttons.medium,
                                            buttons.buttonWithoutIcon
                                        )}
                                        onClick={() => setDeleteTemplateModalVisible(false)}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>

                                    <button
                                        className={classNames(
                                            buttons.primaryButton,
                                            style.deleteButton,
                                            buttons.medium,
                                            buttons.buttonWithoutIcon
                                        )}
                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                        onClick={handleTemplateDelete}
                                    >
                                        {t("Common.delete")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(
                            buttons.primaryButton,
                            buttons.medium,
                            buttons.okButton,
                            buttons.buttonWithoutIcon
                        )}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default connector(ReportTemplatesKebabMenu);
