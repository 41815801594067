import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import { TFunction } from "i18next";
import moment from "moment/moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import { object, string } from "yup";

import style from "./add-user.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import { EMAIL_MAX_LENGTH } from "domain/globalConstants";
import {
    DEFAULT_LOGIN_METHOD,
    INTEGRATION_USER_TYPE,
    MACHINE_USER_TYPE,
    STANDARD_USER_TYPE,
    UserLoginMethod,
    userLoginMethodList,
    UserType,
    userTypeList,
} from "domain/user";
import { CheckEmailAvailability, Role } from "domain/users";
import { LicensePoolList } from "services/licenses/LicensePoolService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userService } from "services/user/users/UserService";
import { userSessionService } from "services/user/UserSessionService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { isStringBlank, isStringNotBlank } from "utils/commonFunctions";
import { formatDate, formatDateWithoutTime } from "utils/format";

import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

interface Props {
    fullName?: string;
    email?: string;
    roles?: Role[];
    userRoleUuid?: string;
    userRoleName?: string;
    submitEventHandler?: (values: FormValues) => Promise<void>;
    disableEmail: boolean;
    enabled: boolean;
    disableStatus: boolean;
    expirationDate: string;
    loginMethod: UserLoginMethod;
    ssoEnabled: boolean;
    licensePools: LicensePoolList[];
    userLicensePoolUuid?: string;
    licensePoolName?: string;
    onView?: boolean;
    userType: UserType;
}

export interface FormValues {
    fullName: string;
    email: string;
    roleUuid: string;
    enabled: boolean;
    expirationDate: string;
    loginMethod: string;
    licensePool: string;
    userType: string;
}

function deriveDefaultRole(roles: Role[]): Role {
    const defaultRole = roles.find((r) => r.defaultNewUserRole);
    if (defaultRole != null) {
        return defaultRole;
    }
    if (roles.length > 0) {
        return roles[0];
    }
    throw new Error("Unsupported state: there should always be at least three roles");
}

export function generateUserTypesToTranslationMap(t: TFunction) {
    return new Map<UserType, string>([
        [STANDARD_USER_TYPE, t("AddUserForm.userType.standard")],
        [INTEGRATION_USER_TYPE, t("AddUserForm.userType.integration")],
        [MACHINE_USER_TYPE, t("AddUserForm.userType.machine")],
    ]);
}

export default function AddUserForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const abortController = new AbortController();
    const [loading, setLoading] = React.useState<boolean>(false);
    const oldStatus = props.enabled;
    const [newStatus, setNewStatus] = React.useState<boolean>(oldStatus);
    const [cleaned, setCleaned] = React.useState(false);
    const [existingExpirationDate, setExistingExpirationDate] = React.useState<string>(props.expirationDate);
    const [licensePool, setLicensePool] = React.useState<string>();

    const statusChanged = newStatus !== oldStatus;
    const LOGIN_METHODS_TO_TRANSLATION_MAP = new Map<UserLoginMethod, string>([
        [DEFAULT_LOGIN_METHOD, t("Settings.loginSettings.loginMethod.default")],
        ["COMPANY_ID", t("Settings.loginSettings.loginMethod.companyId")],
        ["EMAIL", t("Settings.loginSettings.loginMethod.emailSignIn")],
        ["COMPANY_ID_AND_EMAIL", t("Settings.loginSettings.loginMethod.both")],
    ]);
    const USER_TYPES_TO_TRANSLATION_MAP = generateUserTypesToTranslationMap(t);

    const disableDateBeforePresentDay = () => {
        const disableDate = new Date();
        disableDate.setDate(disableDate.getDate() - 1);
        return disableDate;
    };
    const handleClean = () => {
        setCleaned(true);
        setExistingExpirationDate("");
    };

    function getUserPoolUuid() {
        const defaultPoolUuid = props.licensePools.find((item) => {
            if (item.defaultPool) {
                return item.poolUuid;
            }
        });
        return props.userLicensePoolUuid ? props.userLicensePoolUuid : defaultPoolUuid?.poolUuid;
    }

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setErrors, setSubmitting }) => {
        if (loading || props.submitEventHandler == null) {
            return;
        }
        values.email = values.email.toLowerCase();
        values.expirationDate = existingExpirationDate;
        values.enabled = newStatus;
        values.licensePool = licensePool != undefined ? licensePool : (props.userLicensePoolUuid as string);
        setSubmitting(false);
        try {
            if (!props.disableEmail) {
                setLoading(true);
                const emailAvailability: CheckEmailAvailability = await userService.checkEmailAvailability(
                    values.email,
                    abortController
                );
                if (emailAvailability.emailIsAvailable && !props.disableEmail) {
                    await props.submitEventHandler(values);
                    setSubmitting(true);
                } else {
                    setLoading(false);
                    if (emailAvailability.emailIsUndeliverable) {
                        if (emailAvailability.errorMessage === "domainNameCheckingError") {
                            setErrors({ email: t("Common.domainNameCheckingError") });
                        } else {
                            setErrors({
                                email: t("Common." + emailAvailability.errorMessage, {
                                    domain_name: emailAvailability.domainName,
                                }),
                            });
                        }
                    } else {
                        setErrors({ email: t("Common.emailNotAvailable") });
                    }
                }
            } else {
                await props.submitEventHandler(values);
                setSubmitting(true);
            }
        } catch (e) {
            setLoading(false);
            setErrors({ email: t("Common.emailNotAvailable") });
        }
    };
    const handleOkClick = (date: Date) => {
        setCleaned(false);
        setExistingExpirationDate(formatDate(moment(date)));
    };

    const changeDefaultViewValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLicensePool(e.target.value);
    };

    return (
        <Formik
            initialValues={{
                email: props.email,
                fullName: props.fullName,
                roleUuid: props.disableEmail || !props.roles ? props.userRoleUuid : deriveDefaultRole(props.roles).uuid,
                expirationDate: existingExpirationDate,
                enabled: props.enabled,
                loginMethod: props.loginMethod,
                licensePool: getUserPoolUuid(),
                userType: props.userType,
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                fullName: string().max(255),
                email: string()
                    .required(t("AddUserForm.emailRequired"))
                    .email(t("Common.invalidEmail"))
                    .max(EMAIL_MAX_LENGTH),
            })}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ values, errors, isSubmitting, handleChange, handleBlur }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }

                const loader = loading ? (
                    <div className={style.loaderContainer}>
                        <LoadingIndicator small={true} />
                    </div>
                ) : (
                    ""
                );

                const calendar = document.getElementsByClassName("rs-picker-toggle-caret rs-icon")[0] as HTMLElement;
                if (calendar) {
                    calendar.setAttribute("aria-label", t("AltText.calendar"));
                }

                return (
                    <Form className={classNames(props.onView && form.readOnlyForm)}>
                        <div className={form.formFields}>
                            <span className={form.optional}>{t("Common.optional")}</span>
                            <label htmlFor="fullName" className={form.label}>
                                {t("AddUserForm.fullName")}
                            </label>
                            {props.onView ? (
                                <span data-testid={testIds.workArea.user.viewUserDialog.general.nameLabel}>
                                    {values.fullName}
                                </span>
                            ) : (
                                <>
                                    <input
                                        id="fullName"
                                        className={classNames(form.input, form.fixedWidthInput)}
                                        maxLength={255}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.fullName}
                                        autoFocus
                                        data-testid={testIds.workArea.user.addUserDialog.nameInput.itself}
                                    />
                                    <div className={form.error}>
                                        <ErrorMessage name="fullName" />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={form.formFields}>
                            {!props.disableEmail ? (
                                <>
                                    <label
                                        htmlFor="email"
                                        className={form.label + (errors.email ? " " + form.inputError : "")}
                                    >
                                        {t("Common.emailWithColon")}
                                    </label>
                                    <input
                                        id="email"
                                        className={classNames(form.input, form.fixedWidthInput, {
                                            [form.inputError]: errors.email,
                                        })}
                                        maxLength={EMAIL_MAX_LENGTH}
                                        onChange={handleChange}
                                        value={values.email}
                                        data-testid={testIds.workArea.user.addUserDialog.emailInput.itself}
                                        onBlur={() => submitHandler}
                                    />
                                    {loader}

                                    <div
                                        className={form.error}
                                        data-testid={testIds.workArea.user.addUserDialog.emailInput.errorLabel}
                                    >
                                        <ErrorMessage name="email" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <label htmlFor="email" className={form.label}>
                                        {t("Common.emailWithColon")}
                                    </label>
                                    {props.onView ? (
                                        <span data-testid={testIds.workArea.user.viewUserDialog.general.emailLabel}>
                                            {values.email}
                                        </span>
                                    ) : (
                                        <input
                                            id="email"
                                            className={classNames(
                                                style.emailInputBox,
                                                form.input,
                                                form.fixedWidthInput
                                            )}
                                            value={values.email}
                                            data-testid={testIds.workArea.user.addUserDialog.emailInput.itself}
                                            onBlur={() => submitHandler}
                                            disabled
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        <div className={form.formFields}>
                            <label htmlFor="role" className={form.label}>
                                {t("AddUserForm.role")}
                            </label>
                            {props.onView ? (
                                <span data-testid={testIds.workArea.user.viewUserDialog.general.roleLabel}>
                                    {props.userRoleName}
                                </span>
                            ) : (
                                <>
                                    <select
                                        id={"roleUuid"}
                                        value={values.roleUuid}
                                        data-testid={testIds.workArea.user.addUserDialog.roleSelect.itself}
                                        onChange={handleChange}
                                        className={classNames(form.select, form.fixedWidthInput)}
                                    >
                                        {props.roles != null &&
                                            props.roles
                                                .sort((first, second) => first.name.localeCompare(second.name))
                                                .map((role) => (
                                                    <option key={role.uuid} value={role.uuid}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                    </select>
                                    {/*
                                    It's impossible to get an error but the
                                    element itself takes space in the UI. When
                                    all form fields have it, they have
                                    equivalent amount of space below them. Just
                                    an easy and maintainable way to keep the
                                    form field spacing consistent.
                                */}
                                    <div className={form.error}>
                                        <ErrorMessage name="role" />
                                    </div>
                                </>
                            )}
                        </div>
                        {userSessionService.hasFeatureLicense("FEATURE_LICENSE_POOLS") &&
                            (props.onView ? null : (
                                <>
                                    <div className={form.formFields}>
                                        <label htmlFor="licensePool" className={form.label}>
                                            {t("AddUserForm.assignLicensePool")}
                                        </label>
                                        <select
                                            id={"poolName"}
                                            value={licensePool ? licensePool : props.userLicensePoolUuid}
                                            data-testid={testIds.workArea.user.addUserDialog.licensePoolSelect.itself}
                                            onChange={(e) => changeDefaultViewValue(e)}
                                            className={classNames(form.select, form.fixedWidthInput)}
                                        >
                                            {props.licensePools != null &&
                                                props.licensePools.map((each) => (
                                                    <option key={each.poolUuid} value={each.poolUuid}>
                                                        {each.poolName}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className={form.error}>
                                            <ErrorMessage name="poolName" />
                                        </div>
                                    </div>
                                </>
                            ))}
                        {props.ssoEnabled && (
                            <div className={form.formFields}>
                                <label htmlFor="loginMethod" className={form.label}>
                                    {t("Settings.loginSettings.loginMethod.label")}
                                </label>
                                {props.onView ? (
                                    <span data-testid={testIds.workArea.user.viewUserDialog.general.loginMethodLabel}>
                                        {LOGIN_METHODS_TO_TRANSLATION_MAP.get(values.loginMethod)}
                                    </span>
                                ) : (
                                    <select
                                        id={"loginMethod"}
                                        value={values.loginMethod}
                                        data-testid={testIds.workArea.user.addUserDialog.loginMethodSelect}
                                        onChange={handleChange}
                                        className={classNames(form.select, form.fixedWidthInput)}
                                    >
                                        {userLoginMethodList.map((each) => (
                                            <option key={each} value={each}>
                                                {LOGIN_METHODS_TO_TRANSLATION_MAP.get(each)}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        )}
                        {
                            <div className={form.formFields}>
                                <label htmlFor="userType" className={form.label}>
                                    {t("AddUserForm.userType.label")}
                                </label>
                                {props.onView ? (
                                    <span data-testid={testIds.workArea.user.viewUserDialog.general.userTypeLabel}>
                                        {USER_TYPES_TO_TRANSLATION_MAP.get(values.userType)}
                                    </span>
                                ) : (
                                    <select
                                        id={"userType"}
                                        value={values.userType}
                                        data-testid={testIds.workArea.user.addUserDialog.userTypeSelect}
                                        onChange={handleChange}
                                        className={classNames(form.select, form.fixedWidthInput)}
                                    >
                                        {userTypeList.map((each) => (
                                            <option key={each} value={each}>
                                                {USER_TYPES_TO_TRANSLATION_MAP.get(each)}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        }

                        {props.disableStatus && (
                            <div className={form.formFields}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                <label htmlFor="enabled" className={form.label}>
                                    {t("Common.active")}:
                                </label>
                                <label className={form.container}>
                                    <input
                                        type="checkbox"
                                        name="enabled"
                                        defaultChecked={oldStatus}
                                        className={form.input}
                                        onChange={(event) => {
                                            setNewStatus(event.target.checked);
                                            if (event.target.checked) {
                                                setExistingExpirationDate("");
                                            }
                                            usageStatisticsService.sendEvent({
                                                category: Category.USER,
                                                action: Action.ENABLE_USER,
                                                label: newStatus.toString(),
                                            });
                                        }}
                                        data-testid={
                                            props.onView
                                                ? testIds.workArea.user.viewUserDialog.general.statusCheckbox
                                                : testIds.workArea.user.addUserDialog.statusCheckbox.itself
                                        }
                                        disabled={props.onView}
                                    />
                                    <span className={form.checkmark} />
                                </label>
                                {props.onView ?? (
                                    <Tooltip
                                        content={
                                            <div className={style.warningMessage}>
                                                {t("EditUserForm.warningTooltip")}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <div className={style.warningIcon} tabIndex={0}>
                                            <img src={alert} alt={t("AltText.alertIcon")} />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                        {
                            <div className={form.formFields}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                <label htmlFor="expirationDate" className={form.label}>
                                    {t("Common.accessExpiry")}:
                                </label>
                                {props.onView ? (
                                    <span
                                        data-testid={testIds.workArea.user.viewUserDialog.general.accessExpirationLabel}
                                    >
                                        {isStringBlank(existingExpirationDate)
                                            ? t("Common.never")
                                            : `${formatDateWithoutTime(props.expirationDate)}`}
                                    </span>
                                ) : (
                                    <>
                                        <DatePicker
                                            id="expirationDate"
                                            className={form.dateRange}
                                            placement={"topStart"}
                                            title={t("AltText.date")}
                                            onSelect={handleOkClick}
                                            placeholder={t("Common.never")}
                                            disabledDate={(date: Date) => isBefore(date, disableDateBeforePresentDay())}
                                            ranges={[
                                                {
                                                    label: "Today",
                                                    value: new Date(),
                                                    closeOverlay: true,
                                                },
                                            ]}
                                            renderValue={(value: Date) => {
                                                return isStringBlank(existingExpirationDate)
                                                    ? t("Common.never")
                                                    : `${formatDateWithoutTime(value.toString())}`;
                                            }}
                                            value={
                                                isStringNotBlank(existingExpirationDate) && !cleaned
                                                    ? new Date(existingExpirationDate.split("T")[0])
                                                    : null
                                            }
                                            onClean={handleClean}
                                            disabled={statusChanged === oldStatus}
                                        />
                                        <div className={form.error}>
                                            <ErrorMessage name="expirationDate" />
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                        {props.onView ?? (
                            <div className={form.buttonContainer}>
                                {!props.disableEmail ? (
                                    <button
                                        type="submit"
                                        className={classNames(
                                            buttons.primaryButton,
                                            buttons.medium,
                                            buttons.buttonWithoutIcon
                                        )}
                                        disabled={loading || isSubmitting}
                                        data-testid={testIds.workArea.user.addUserDialog.submitButton}
                                    >
                                        {t("AddUserView.userButton")}
                                    </button>
                                ) : (
                                    <button
                                        className={classNames(
                                            isSubmitting ? buttons.disabledButton : buttons.primaryButton,
                                            buttons.medium,
                                            buttons.buttonWithoutIcon
                                        )}
                                        type="submit"
                                        disabled={isSubmitting}
                                        data-testid={testIds.workArea.user.editUserDialog.saveButton}
                                    >
                                        {t("Common.save")}
                                    </button>
                                )}
                            </div>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}
