import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "components/button/Button";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import style from "components/reports/report-view.scss";
import DeleteItemStatusDialog from "components/status-dialog/DeleteItemStatusDialog";
import { AUTH_REPORT_DELETE } from "domain/authority";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { FLAG_DELETE_REPORT } from "services/feature/FeatureFlagService";
import { reportDeletionService } from "services/report/ReportDeletionService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import FailedRedNotificationIcon from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface Result {
    title: string;
    message: string | JSX.Element;
    image: string | JSX.Element;
}

interface DeleteReportProperties {
    reportUuid: string;
    onDelete: () => void;
    deletionInvoked: boolean;
    onDeletionInvoked: (invoked: boolean) => void;
    onDeleteAgain?: () => void;
}

const DeleteReportDialog = (props: DeleteReportProperties & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [deleteReportFormVisible, setDeleteReportFormVisible] = React.useState<boolean>(false);
    const [okClicked, setOkClicked] = React.useState<boolean>(false);
    const [confirmationState, setConfirmationState] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", image: "" });
    const [reportNotDeleted, setReportNotDeleted] = React.useState<boolean>(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    function handleConfirmation() {
        setConfirmationState(
            (document.getElementById("confirmInput") as HTMLInputElement).value !== t("Common.delete").toUpperCase()
        );
    }

    const showResult = (resultToShow: {
        title: string;
        message: string | JSX.Element;
        image: string | JSX.Element;
    }) => {
        setResult(resultToShow);
    };

    const handleReportDelete = () => {
        props.onDeleteAgain?.();
        deleteReportEventHandler();
    };

    const deleteReportEventHandler = async () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportDeletionService
            .deleteReport(props.reportUuid, abortController)
            .then(() => {
                props.onDeletionInvoked(true);
                props.onDelete();
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("DeleteReport.deleteReport.reportDeleted.reportDeletedTitle", {
                            reportUuid: props.reportUuid,
                        })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
            })
            .catch(() => {
                const mailToLink = `mailto:${SUPPORT_EMAIL}`;
                showResult({
                    title: t("DeleteReport.deleteReport.reportDeleted.reportNotDeletedTitle"),
                    message: (
                        <span>
                            {t("DeleteReport.deleteReport.reportDeleted.failureMessage")}
                            <a href={mailToLink}>{t("DeleteReport.deleteReport.reportDeleted.supportMessage")} </a>
                        </span>
                    ),
                    image: <img className={style.image} src={FailedRedNotificationIcon} />,
                });
                setReportNotDeleted(true);
                setDeleteReportFormVisible(false);
                setOkClicked(true);
            });
    };

    const deleteSingleReportModal = (
        <Modal
            isOpen={deleteReportFormVisible}
            hideModal={() => setDeleteReportFormVisible(false)}
            modalTitle={t("DeleteReport.deleteReport.title")}
        >
            {okClicked ? (
                <LoadingIndicator />
            ) : (
                <>
                    {deleteReportFormVisible ? (
                        <>
                            <div className={style.resultContainer}>
                                {t("DeleteReport.deleteReport.introductionMessage", { reportUuid: props.reportUuid })}
                            </div>
                            <div className={style.resultContainer}>
                                {t("DeleteReport.deleteReport.confirmationMessage")}
                            </div>
                            <div className={style.resultContainer}>
                                <input
                                    id="confirmInput"
                                    type="text"
                                    data-testid={testIds.common.confirmationDialog.confirmInput}
                                    placeholder={t("Common.delete").toUpperCase()}
                                    onChange={handleConfirmation}
                                />
                            </div>
                            <div className={style.okButtonContainer}>
                                <button
                                    className={classNames(
                                        buttons.secondaryButton,
                                        buttons.medium,
                                        buttons.buttonWithoutIcon
                                    )}
                                    onClick={() => setDeleteReportFormVisible(false)}
                                    data-testid={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.cancel")}
                                </button>

                                <button
                                    className={classNames(
                                        buttons.primaryButton,
                                        buttons.deleteButton,
                                        buttons.medium,
                                        buttons.buttonWithoutIcon
                                    )}
                                    data-testid={testIds.common.confirmationDialog.confirmButton}
                                    onClick={handleReportDelete}
                                    disabled={confirmationState}
                                >
                                    {t("Common.delete")}
                                </button>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </>
            )}
        </Modal>
    );

    return (
        <div>
            {userSessionService.userHasAllAuthorities([AUTH_REPORT_DELETE]) && useFeature(FLAG_DELETE_REPORT) && (
                <Button
                    variant="TERTIARY"
                    icon="DELETE"
                    onClick={() => setDeleteReportFormVisible(true)}
                    testId={testIds.workArea.report.primaryView.reportDialog.deleteButton}
                >
                    {t("Common.delete")}
                </Button>
            )}
            {deleteSingleReportModal}
            <DeleteItemStatusDialog
                modalDisplayed={reportNotDeleted}
                setModalDisplayed={() => setReportNotDeleted(false)}
                message={t("DeleteReport.deleteReport.reportDeleted.failureMessage")}
                supportMessage={t("DeleteReport.deleteReport.reportDeleted.supportMessage")}
                handleCommand={deleteReportEventHandler}
                title={result.title}
                reportUuid={props.reportUuid}
                ditchLink={false}
            />
        </div>
    );
};

export default connector(DeleteReportDialog);
