import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ExportReportDialog from "./export/ExportReportDialog";
import style from "./report-view.scss";
import ReportView, { deriveReportUrl } from "./ReportView";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import Email from "components/icons/Email";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import DeleteReportDialog from "components/reports/DeleteReportDialog";
import { FormValues } from "components/reports/EmailReportViewForm";
import EmailReportViewForm from "components/reports/EmailReportViewForm";
import { FormValues as Values } from "components/reports/export/ExportReportDialog";
import { REPORTS_TEMPLATE_ROUTE } from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID, CustomReportView, TemplateTableData } from "domain/reports";
import { FLAG_PRINT_REPORT } from "services/feature/FeatureFlagService";
import { getLanguage } from "services/language/languageRepository";
import { reportService } from "services/report/erasure/ReportService";
import { reportExportService } from "services/report/ReportExportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import FailedRedNotificationIcon from "assets/images/icons/alert.svg";
import PassedGreenNotificationIcon from "assets/images/icons/checkMarkInCircle.svg";
import WarningYellowNotificationIcon from "assets/images/icons/quickIndeterminate.svg";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
    image: string | JSX.Element;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);
export enum Format {
    PDF = "PDF",
    XML = "XML",
    PDF_XML = "PDF_XML",
    SUMMARY_CSV = "SUMMARY_CSV",
    SUMMARY_PDF = "SUMMARY_PDF",
    CSV = "CSV",
    VIEW_ALL = "VIEW_ALL",
}

function ErasureReportModal(
    props: {
        date: string;
        reportUuid: string;
        emailAddress?: string;
        pdf?: boolean;
        xml?: boolean;
        subject?: string;
        emailBody?: string;
        hide: () => void;
        viewDetails?: CustomReportView;
        openInNewTab: () => void;
        onReportDelete: () => void;
    } & ConnectedProps<typeof connector>
): JSX.Element {
    const languageCode = getLanguage().code;
    const visible = props.reportUuid !== "";
    if (!visible) {
        return <></>;
    }
    const { t } = useTranslation();
    const viewDetails: CustomReportView = {
        uuid: "",
        name: "",
        columns: ["uuid", "product", "date"],
        shared: false,
        own: true,
    };
    const [templates, setTemplates] = React.useState<TemplateTableData[]>([]);
    const erasureReportTitle = t("ErasureReport.title");
    const [templateUuid, setTemplateUuid] = React.useState("");
    const [emailReportViewTitle, setEmailReportViewTitle] = React.useState(erasureReportTitle);
    const [customizationEnabled, setCustomizationEnabled] = React.useState(false);
    const [emailReportViewVisible, setEmailReportViewVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [okClicked, setOkClicked] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", resultVisible: false, image: "" });
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [resultVisible, setResultVisible] = React.useState<boolean>(false);
    const [print, setPrint] = React.useState(false);

    const hide = () => {
        setIsVisible(false);
    };

    const hideErasureResultAndRedirectToRoot = () => {
        hide();
        props.hide();
        props.onReportDelete();
    };

    const submitEventHandler = async ({
        language,
        reportUuids,
        templateUuid,
        format,
        columns,
        filenameColumns,
        filenameSeparator: separator,
        subRecordSeparator,
        recordSeparator,
        humanReadableNumbers,
        localizedColumns,
    }: Values) => {
        if (!reportUuids) {
            logger.error("Report uuid is empty or undefined");
            return;
        }
        const urlPath = deriveReportUrl(reportUuids[0], language, format, templateUuid, columns);
        const abortController = new AbortController();
        try {
            reportExportService.exportReportBySelectFormat(
                urlPath,
                abortController,
                humanReadableNumbers,
                localizedColumns,
                filenameColumns,
                separator,
                subRecordSeparator,
                recordSeparator
            );
            setIsVisible(false);
        } catch (e) {
            logger.error("Failed to export report: ", e);
            return;
        }
    };

    const updateEmailReportViewTitle = (templateUuid: string) => {
        if (templateUuid != BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID) {
            const sections = templates.filter((temp) => temp.uuid == templateUuid)[0].sections;
            setEmailReportViewTitle(
                sections.includes("ERASURE_RESULT") ? erasureReportTitle : t("ErasureReport.hardwareTitle")
            );
        } else {
            setEmailReportViewTitle(erasureReportTitle);
        }
    };

    React.useEffect(() => {
        if (templates.length > 0) {
            const defaultTemplate = templates.filter((template) => template.defaultReportTemplate.own).shift();
            setTemplateUuid(defaultTemplate ? defaultTemplate.uuid : BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID);
            if (defaultTemplate) {
                updateEmailReportViewTitle(defaultTemplate.uuid);
            } else {
                setEmailReportViewTitle(erasureReportTitle);
            }
        }
    }, [templates]);

    const sendEmail = async ({
        emailAddress,
        pdf,
        xml,
        templateUuid,
        language,
        subject,
        emailBody,
    }: FormValues): Promise<void> => {
        setOkClicked(true);
        const abortController = new AbortController();

        abortControllers.push(abortController);
        let format: Format | undefined;
        if (pdf && xml) {
            format = Format.PDF_XML;
        } else if (pdf && !xml) {
            format = Format.PDF;
        } else if (!pdf && xml) {
            format = Format.XML;
        }
        if (pdf || xml) {
            reportService
                .sendErasureReportEmail(
                    props.reportUuid,
                    emailAddress,
                    format,
                    templateUuid,
                    language,
                    subject,
                    emailBody,
                    abortController
                )
                .then(() => {
                    setEmailReportViewVisible(false);
                    setResult({
                        title: t("ErasureReport.sendEmail.successTitle"),
                        message: t("ErasureReport.sendEmail.successMessage", { email: emailAddress }),
                        resultVisible: true,
                        image: <img className={style.image} src={PassedGreenNotificationIcon} />,
                    });
                    setOkClicked(false);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setEmailReportViewVisible(true);
                        setResult({
                            title: t("ErasureReport.sendEmail.failureTitle"),
                            message: t("ErasureReport.sendEmail.failureMessage"),
                            resultVisible: true,
                            image: (
                                <img
                                    className={style.image}
                                    src={FailedRedNotificationIcon}
                                    alt={t("AltText.severeWarning")}
                                />
                            ),
                        });
                        setOkClicked(false);
                    }
                });
        } else {
            setEmailReportViewVisible(true);
            setResult({
                title: t("ErasureReport.sendEmail.warningTitle"),
                message: t("ErasureReport.sendEmail.warningMessage"),
                resultVisible: true,
                image: (
                    <img className={style.image} src={WarningYellowNotificationIcon} alt={t("AltText.indeterminate")} />
                ),
            });
            setOkClicked(false);
        }
    };

    const hideResultAndRedirectToRoot = () => {
        setResultVisible(false);
        setResult({ title: result.title, message: result.message, resultVisible: false, image: result.image });
    };

    const createTemplateSelection = () => {
        return (
            <div className={classNames(style.templates, { [style.flexDisplay]: templates.length <= 0 })}>
                <label htmlFor={"templates"} className={classNames(form.label, style.previewLabel)}>
                    {t("ErasureReport.templateSelection")}
                </label>
                <select
                    id={"templates"}
                    className={classNames(form.select, form.fixedWidthInput, style.selectArrow)}
                    onChange={(event) => {
                        usageStatisticsService.sendEvent({
                            category: Category.REPORTS,
                            action: Action.PREVIEW_ERASURE_REPORT_WITH_TEMPLATE,
                        });
                        const template = templates.find((each) => each.uuid.toLowerCase() === event.target.value);
                        setTemplateUuid(typeof template == "undefined" ? event.target.value : template.uuid);
                        updateEmailReportViewTitle(event.target.value);
                    }}
                    value={templateUuid}
                    data-testid={testIds.workArea.report.erasure.previewReportSelect}
                >
                    <option key={BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID} value={BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID}>
                        {t("ErasureReport.default")}
                    </option>
                    {templates.map((each) => (
                        <option key={each.uuid.toLowerCase()} value={each.uuid.toLowerCase()}>
                            {each.templateName} {each.defaultReportTemplate.own && t("ErasureReport.defaultTemplate")}
                        </option>
                    ))}
                </select>
                {templates.length <= 0 ? (
                    <Tooltip
                        content={
                            <div className={style.tooltipOpen}>
                                {t("ErasureReport.previewInfo")}
                                <button
                                    className={classNames(style.link, buttons.textButton)}
                                    onClick={() => window.location.replace(REPORTS_TEMPLATE_ROUTE.path)}
                                >
                                    {t("ErasureReport.createTemplate")}
                                </button>
                            </div>
                        }
                        placement={"top"}
                    >
                        <div className={style.info} />
                    </Tooltip>
                ) : (
                    ""
                )}
            </div>
        );
    };

    return (
        <>
            <Modal
                isOpen={visible}
                hideModal={props.hide}
                modalTitle={erasureReportTitle}
                openInNewTab={props.openInNewTab}
            >
                <div className={style.header}>
                    <div>{customizationEnabled ? createTemplateSelection() : ""}</div>
                    <div className={style.buttonPosition}>
                        <ButtonContainer noSpacingTop={true} gap="MEDIUM">
                            <Tooltip
                                content={t("ErasureReport.sendEmailIconTooltip")}
                                maxWidth={350}
                                delay={[300, 0]}
                                placement={"top"}
                            >
                                <Button
                                    variant="TERTIARY"
                                    customIcon={<Email color={props.theme.linkTextColor} />}
                                    onClick={() => setEmailReportViewVisible(true)}
                                >
                                    {t("Common.email")}
                                </Button>
                            </Tooltip>
                            <div>
                                <Button
                                    variant="TERTIARY"
                                    icon="EXPORT"
                                    testId={testIds.workArea.report.primaryView.table.exportSelectedButton}
                                    tooltipText={t("ErasureReport.exportSelectedReportsTooltip")}
                                    onClick={() => setIsVisible(true)}
                                >
                                    {t("Common.export")}
                                </Button>
                            </div>

                            <div>
                                {useFeature(FLAG_PRINT_REPORT) && (
                                    <Button variant="TERTIARY" icon="PRINT" onClick={() => setPrint(true)}>
                                        {t("ErasureReport.print.title")}
                                    </Button>
                                )}
                            </div>

                            <DeleteReportDialog
                                reportUuid={props.reportUuid}
                                onDelete={hideErasureResultAndRedirectToRoot}
                                deletionInvoked={false}
                                onDeletionInvoked={() => false}
                            />
                        </ButtonContainer>
                    </div>
                </div>

                <ReportView
                    title={erasureReportTitle}
                    reportUuid={props.reportUuid}
                    languageCode={languageCode}
                    onFetchTemplates={setTemplates}
                    templateUuid={templateUuid}
                    onCustomizationEnabled={setCustomizationEnabled}
                    print={print}
                    setPrint={setPrint}
                />
            </Modal>
            <Modal
                isOpen={emailReportViewVisible}
                hideModal={() => setEmailReportViewVisible(false)}
                modalTitle={t("ErasureReport.sendEmail.title")}
            >
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <EmailReportViewForm
                        title={emailReportViewTitle}
                        date={props.date}
                        emailAddress={props.emailAddress}
                        pdf={props.pdf}
                        xml={props.xml}
                        templateUuid={templateUuid === BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID ? "" : templateUuid}
                        language={languageCode}
                        subject={props.subject}
                        emailBody={props.emailBody}
                        handleSubmit={sendEmail}
                        handleCancel={() => setEmailReportViewVisible(false)}
                    />
                )}
            </Modal>
            <Modal isOpen={isVisible} hideModal={hide} modalTitle={t("ErasureReport.exportTypeTitle")}>
                <ExportReportDialog
                    handle={submitEventHandler}
                    columns={viewDetails.columns}
                    onShowModal={setIsVisible}
                    isAllReportsView={typeof viewDetails?.uuid === "undefined" || viewDetails?.uuid.trim() === ""}
                    reportUuids={[props.reportUuid]}
                    language={languageCode}
                    templateUuid={templateUuid === BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID ? "" : templateUuid}
                    singleReportExport={true}
                    search=""
                />
            </Modal>

            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.titleMessage}>{result.image}</div>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={buttons.okButtonContainer}>
                    <button
                        className={classNames(
                            buttons.primaryButton,
                            buttons.medium,
                            buttons.okButton,
                            buttons.buttonWithoutIcon
                        )}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(
                            buttons.primaryButton,
                            buttons.medium,
                            buttons.okButton,
                            buttons.buttonWithoutIcon
                        )}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default connector(ErasureReportModal);
