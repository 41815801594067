import classNames from "classnames";
import * as React from "react";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import DragDrop from "components/icons/DragDrop";
import style from "components/import-file-dialog/import-file-dialog.scss";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    introductionMessage: string;
    handleFileDrop: (fileList: FileList, event: React.DragEvent<HTMLDivElement>) => void;
    onFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectFileClicked: () => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    showGoBackButton?: boolean;
    handleGoBack?: () => void;
}

const SelectingView = (props: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <div data-testid={testIds.common.importFileDialog.dragDropArea} className={style.selectFileWrapper}>
                {props.introductionMessage && (
                    <div className={style.introductionLabel}>{props.introductionMessage}</div>
                )}
                <FileDrop onDrop={props.handleFileDrop}>
                    <DragDrop />
                    <div>{t("Settings.selectFile.dragDropMessage")}</div>
                    <div>{t("Common.lowerCaseOr")}</div>
                    <div>
                        <input
                            data-testid={testIds.common.importFileDialog.fileNameInput}
                            onChange={props.onFileInputChange}
                            ref={props.fileInputRef}
                            type="file"
                            className={style.hidden}
                        />
                        <button
                            onClick={props.onSelectFileClicked}
                            className={classNames(style.selectFile, buttons.textButton, buttons.buttonWithoutIcon)}
                        >
                            {t("Settings.selectFile.chooseFileMessage")}
                        </button>
                    </div>
                </FileDrop>
            </div>
            {props.showGoBackButton === true && (
                <ButtonContainer>
                    <button
                        className={classNames(
                            buttons.primaryButton,
                            buttons.medium,
                            style.button,
                            buttons.buttonWithoutIcon
                        )}
                        data-testid={testIds.common.importFileDialog.uploadAnotherButton}
                        onClick={() => {
                            if (!props.handleGoBack) {
                                return;
                            }
                            props.handleGoBack();
                        }}
                    >
                        {t("Common.goBack")}
                    </button>
                </ButtonContainer>
            )}
        </>
    );
};

export default SelectingView;
