import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import LicensePoolCommonDialog from "components/licenses/licenses-pool/LicensePoolCommonDialog";
import Modal from "components/modal/Modal";
import style from "components/reports/report-view.scss";
import buttonStyle from "components/tenants/tenants.scss";
import { licensePoolService } from "services/licenses/LicensePoolService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface LicensePoolList {
    poolUuid: string;
    poolName: string;
}

interface AssignLicensePoolProperties {
    userUuids: string[];
    assignLPViewFormVisible: boolean;
    setAssignLPViewFormVisible: (assign: boolean) => void;
    updateLicensePoolToUsers: () => void;
    licensePoolsList: LicensePoolList[];
}

const AssignLicensePoolDialog = (props: AssignLicensePoolProperties): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [selectedLicensePool, setSelectedLicensePool] = React.useState("Select");
    const [licensePoolsList, setLicensePoolsList] = React.useState<LicensePoolList[]>([{ poolUuid: "", poolName: "" }]);
    const [poolUuid, setPoolUuid] = React.useState<string>("");
    const [poolAssignAgain, setPoolAssignAgain] = React.useState<boolean>(false);
    const [poolUuidNotSelected, setPoolUuidNotSelected] = React.useState<boolean>(false);

    const assignLicensePoolToUsers = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;

        const item = licensePoolsList.find((item) => item.poolName === selectedLicensePool);
        if (item !== undefined) {
            setPoolUuid(item.poolUuid);

            licensePoolService
                .assignLicensePoolToUsers(item?.poolUuid, props.userUuids, abortController)
                .then(() => {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("UsersTable.assignLicensePoolToUsersSuccessAlert")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                    props.updateLicensePoolToUsers();
                })
                .catch(() => {
                    if (!signal.aborted) {
                        setPoolAssignAgain(true);
                    }
                })
                .finally(() => {
                    props.setAssignLPViewFormVisible(false);
                });
        } else {
            setPoolUuidNotSelected(true);
        }
    };

    const showError = (
        <div className={classNames(form.error)} data-testid={testIds.login.form.errorLabel}>
            {t("AddUserForm.errorMessage.message")}
        </div>
    );

    const changeDefaultViewValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLicensePool(e.target.value);
    };

    const createDefaultViewSelection = (): JSX.Element[] => {
        const options: JSX.Element[] = [];
        licensePoolsList.forEach((each) => {
            options.push(<option value={each.poolName}>{each.poolName}</option>);
        });
        return options;
    };

    return (
        <>
            <Modal
                isOpen={props.assignLPViewFormVisible}
                hideModal={() => props.setAssignLPViewFormVisible(false)}
                modalTitle={t("UsersTable.assignLicensePoolToUsers", { usersCount: props.userUuids.length })}
            >
                <div className={style.resultContainer}>
                    <label className={classNames(form.label, style.previewLabel)}>
                        {t("UsersTable.licensePoolLabel")}
                    </label>
                    <select
                        id={"licensePoolUuids"}
                        className={classNames(form.select, style.selectArrow, form.fixedWidthInput)}
                        onChange={changeDefaultViewValue}
                        onClick={() => setLicensePoolsList(props.licensePoolsList)}
                        data-testid={testIds.workArea.user.assignLicensePoolDialog.licensePoolSelect}
                    >
                        <option value="">{t("Common.select")}</option>
                        {createDefaultViewSelection()}
                    </select>
                </div>
                {poolUuidNotSelected && showError}
                <div className={buttonStyle.buttonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                        data-testid={testIds.workArea.user.assignLicensePoolDialog.assignButton}
                        onClick={() => {
                            assignLicensePoolToUsers();
                        }}
                    >
                        {t("UsersTable.assignButton")}
                    </button>
                    <button
                        className={classNames(buttons.secondaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                        onClick={() => props.setAssignLPViewFormVisible(false)}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.cancel")}
                    </button>
                </div>
            </Modal>
            <LicensePoolCommonDialog
                modalDisplayed={poolAssignAgain}
                setModalDisplayed={() => setPoolAssignAgain(false)}
                handleCommand={assignLicensePoolToUsers}
                poolUuid={poolUuid}
            />
        </>
    );
};
export default connector(AssignLicensePoolDialog);
