import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Column } from "react-table";

import BundleTokenPricingView from "./BundleTokenPricingView";
import { BLANCCO_TOKEN_ID, createProductIdToNameMap, FEATURE_LICENSES, isSubscriptionLicense } from "./common";
import commonStyle from "./license-keys-table.scss";
import tokenTableStyle from "./token-pricing-tier-view.scss";
import Chevron from "components/icons/Chevron";
import Info from "components/icons/Info";
import LicenseTierBanner from "components/licenses/license-tier-banner/LicenseTierBanner";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import tableStyle from "components/table/table.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import { deduceTierLicenses } from "components/tenants/add-tenant/AddTenantForm";
import style from "components/tenants/tenants.scss";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { LicenseData, LicenseKeyCursor, Licenses } from "domain/licenses";
import { LicensingModel } from "domain/tenants";
import { licenseService, ProductRateList, ProductToRateList } from "services/licenses/LicenseService";
import {
    getCurrentTenantDetails,
    hasTenantCookie,
    isLicensingModelBundleWithToken,
    isUserParentInternal,
} from "services/tenants/tenantCookieService";
import { store, StoreState } from "store";
import {
    setHasBmsAllLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsLicenses,
    setHasBmsNtfLicenses,
    setHasBmsValidationLicenses,
    setHasUsdkLicenses,
} from "store/license";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { isExpired } from "utils/format";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface Props {
    initialLicenses: Licenses;
    requestFailureMessage: string;
    scrollPosition?: number;
    tokenRates?: ProductRateList[];
    own: boolean;
}

interface TableState {
    licenses: LicenseData[];
    cursor: LicenseKeyCursor | null;
    scrollPosition?: number;
}

export interface ProductToRates {
    productId: string | undefined;
    rate: string;
}

export interface FetchedLicenseData {
    product: string | undefined;
    license: string | undefined;
    total: number;
    available: number;
    expirationDate: string;
    type: string;
    licenseType: string;
    isInvalid?: boolean;
    isFeatureLicensePresent: boolean;
    used?: number;
    licenses?: LicenseData[];
    overallRemaining?: number;
    assigned?: number;
}

const mapState = (state: StoreState) => ({
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
    user: state.userReducer.user,
});
const connector = connect(mapState, {
    setHasBmsLicenses,
    setHasBmsValidationLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsNtfLicenses,
    setHasBmsAllLicenses,
    setHasUsdkLicenses,
});

const LicensesTable = (props: Props & ConnectedProps<typeof connector>) => {
    const { t } = useTranslation();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const deduceLicenseTotalValue = (licenseData: LicenseData, hasBlanncoTokens?: boolean) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            return !isUserParentInternal() || hasTenantCookie()
                ? t("Licenses.subscriptionLicenseRemaining", { remainingSubscriptionLicense: licenseData.total })
                : t("Licenses.subscription");
        }
        return hasBlanncoTokens ? licenseData.total.toFixed(1) : licenseData.total;
    };

    const ALL_PRODUCTS = createProductIdToNameMap();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const createDropdownArrow = (isExpanded: boolean) => {
        const theme = store.getState();

        return (
            <Chevron
                color={theme.themeReducer.theme.iconFillColor}
                className={classNames(style.toggleArrow, style.right, { [style.down]: isExpanded })}
                width={20}
                height={20}
            />
        );
    };

    const deduceLicenseAvailability = (licenseData: LicenseData, hasBlanncoTokens?: boolean) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            if (licenseData.isInvalid) {
                return t("Licenses.unavailable");
            } else {
                return t("Licenses.subscription");
            }
        }
        return hasBlanncoTokens ? licenseData.available.toFixed(1).toString() : licenseData.available.toString();
    };

    const deduceLicenseUsedValue = (licenseData: LicenseData, hasBlanncoTokens?: boolean) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            return !isUserParentInternal() || hasTenantCookie()
                ? t("Licenses.subscriptionLicenseRemaining", {
                      remainingSubscriptionLicense: licenseData.total - licenseData.available,
                  })
                : t("Licenses.subscription");
        }
        const usedValue = licenseData.total - licenseData.available;
        return hasBlanncoTokens ? usedValue.toFixed(1).toString() : usedValue.toString();
    };

    const deduceLicenseOverallAvailableValue = (licenseData: LicenseData, hasBlanncoTokens?: boolean) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            return !isUserParentInternal() || hasTenantCookie()
                ? t("Licenses.subscriptionLicenseRemaining", { remainingSubscriptionLicense: licenseData.available })
                : t("Licenses.subscription");
        }
        return hasBlanncoTokens
            ? licenseData.overallRemaining?.toFixed(1).toString()
            : licenseData.overallRemaining?.toString();
    };

    const tenantDetails = getCurrentTenantDetails();
    const [finalList, setFinalList] = React.useState<ProductRateList[]>([]);
    const tokenRates = () => {
        const tokenRateList: ProductRateList[] = [];
        const abortController = new AbortController();
        licenseService
            .fetchProductTokenRate(abortController, tenantDetails.uuid)
            .then((rateList: ProductToRateList[]) => {
                rateList.forEach((each) => {
                    each.productToRate.forEach((product) => tokenRateList.push(product));
                });
                setFinalList(tokenRateList);
            });
    };

    const deducedTierLicenses = deduceTierLicenses(tenantDetails.tenantTier, true, tenantDetails.type, true);

    const placeholderObjects: LicenseData[] = [];

    const [finalRateList, setFinalRateList] = React.useState<ProductToRates[]>([]);

    const createPlaceholderTableRows = (licenseDetails: FetchedLicenseData) => {
        if (licenseDetails.product) {
            const licenses: LicenseData = {
                product: licenseDetails.product,
                license: licenseDetails.product,
                total: licenseDetails.total,
                available: licenseDetails.available,
                expirationDate: licenseDetails.expirationDate,
                type: licenseDetails.type,
                licenseType: licenseDetails.licenseType,
                isInvalid: false,
                isFeatureLicensePresent: licenseDetails.isFeatureLicensePresent,
            };
            placeholderObjects.push(licenses);
        }
    };

    const convertToRows = (data: LicenseData[]) => {
        state.licenses.forEach((each) => {
            if (each.type == BLANCCO_TOKEN_ID) {
                each.subLicenses = data;
            }
        });
        setLicensesData(state);
    };

    const fetchLicenseName = (data: ProductRateList[] | undefined, tenantLicenses?: string[]) => {
        const finalData: ProductToRates[] = [];
        if (data) {
            data.forEach((each) => {
                if (ALL_PRODUCTS.get(each.productId) && tenantLicenses?.includes(each.productId)) {
                    const rateList: ProductToRates = {
                        productId: ALL_PRODUCTS.get(each.productId),
                        rate: Number(each.rate).toFixed(1).toString(),
                    };
                    finalData.push(rateList);
                }
            });
        }
        setFinalRateList(finalData);
    };

    const deduceAvailabilityTooltip = (licenseData: LicenseData, hasBlanncoTokens?: boolean) => {
        if (isExpired(licenseData.expirationDate)) {
            return t("Licenses.expired");
        }
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            if (licenseData.available < 1) {
                return t("Licenses.adjusted");
            }
            return t("Licenses.subscription");
        }
        return hasBlanncoTokens ? licenseData.available.toFixed(1).toString() : licenseData.available.toString();
    };

    const deduceUsesLeft = (tokensAvailable: string, product: string, hasBlanncoTokens?: boolean) => {
        let usesLeft = Number(tokensAvailable);
        finalList.forEach((each) => {
            const tokenRate = Number(each.rate);
            if (each.productId == product) {
                usesLeft = Number(tokensAvailable) / tokenRate;
            }
        });
        return hasBlanncoTokens ? usesLeft.toFixed(1).toString() : usesLeft.toString();
    };

    const [tokenPricingTierModalVisible, setTokenPricingTierModalVisible] = React.useState(false);

    const suppressWarning = (licenseData: LicenseData) => {
        return licenseData.isFeatureLicensePresent === false && licenseData.isInvalid === true;
    };

    const createColumns = (): Array<Column<LicenseData>> => {
        const columns: Array<Column<LicenseData>> = [];
        const hasBlanncoTokens = deducedTierLicenses ? true : false;
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.products")} key="1" />,
            accessor: "product",
            Cell: ({ row }) => {
                const isSubRow = row.depth > 0;
                const isBlanccoCommonLicense = row.original.type == BLANCCO_TOKEN_ID;
                const isParentLicense = !isSubRow && deducedTierLicenses && isBlanccoCommonLicense;
                return (
                    <>
                        <div
                            className={classNames(style.alignment, {
                                [tableStyle.subRowBorder]: isSubRow || (row.depth === 0 && row.isExpanded),
                                [tableStyle.subRowNameCell]: isSubRow,
                                [tableStyle.subRows]: isSubRow && deducedTierLicenses,
                                [tableStyle.addOnLicenses]: !isParentLicense,
                                [tableStyle.expandedRow]: row.isExpanded,
                            })}
                        >
                            {isSubRow && (
                                <div className={tableStyle.nameCellConnectorLines}>
                                    <span></span>
                                </div>
                            )}
                            {isParentLicense && (
                                <button
                                    onClick={() => {
                                        if (row.subRows.length === 0) {
                                            deducedTierLicenses.forEach((license) => {
                                                if (ALL_PRODUCTS.get(license)) {
                                                    const subRowLicenses = {
                                                        product: ALL_PRODUCTS.get(license),
                                                        license: ALL_PRODUCTS.get(license),
                                                        total: row.original.total,
                                                        available: row.original.available,
                                                        expirationDate: row.original.expirationDate,
                                                        type: license,
                                                        licenseType: row.original.licenseType,
                                                        isInvalid: false,
                                                        isFeatureLicensePresent:
                                                            !(
                                                                isSubscriptionLicense(license) ||
                                                                FEATURE_LICENSES.includes(license)
                                                            ) || false,
                                                    };
                                                    createPlaceholderTableRows(subRowLicenses);
                                                }
                                            });
                                        }
                                        convertToRows(placeholderObjects);
                                        row.toggleRowExpanded();
                                        fetchLicenseName(finalList);
                                    }}
                                    className={style.subTenantToggleButton}
                                    aria-expanded={row.isExpanded}
                                    data-testid={testIds.workArea.license.table.expandArrow}
                                >
                                    {createDropdownArrow(row.isExpanded)}
                                </button>
                            )}
                            <TextWithTooltip text={row.original.product} highlight={suppressWarning(row.original)} />
                            {isParentLicense && (
                                <>
                                    <button
                                        className={tableStyle.infoIcon}
                                        onClick={() => {
                                            fetchLicenseName(finalList, deducedTierLicenses);
                                            setTokenPricingTierModalVisible(true);
                                        }}
                                        type="button"
                                        data-testid={testIds.workArea.license.table.tokenRateButton}
                                    >
                                        <div className={tableStyle.viewRates}>{t("Licenses.viewRates")}</div>
                                    </button>
                                </>
                            )}
                        </div>
                    </>
                );
            },
            width: deriveColumnWidth(30, tableContainerRef),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.licenseType")} key="2" />,
            accessor: "license",
            Cell: ({ row }) => (
                <TextWithTooltip text={row.original.license} highlight={suppressWarning(row.original)} />
            ),
            width: deriveColumnWidth(30, tableContainerRef),
        });
        if (tenantDetails.licensingModel === LicensingModel.BUNDLE_WITH_TOKEN) {
            columns.push({
                Header: () => {
                    return (
                        <TextWithTooltip text={t("Licenses.usesLeftTooltip")} key="3" adjustWidth={true}>
                            <div className={tokenTableStyle.info}>
                                {t("Licenses.usesLeft")}
                                <div className={tokenTableStyle.infoText}>
                                    <Info borderColor={theme.contentBackgroundColor} color={theme.textColor} />
                                </div>
                            </div>
                        </TextWithTooltip>
                    );
                },
                accessor: "usesLeft",
                Cell: ({ row }) => {
                    const isSubRow = row.depth > 0;
                    return (
                        <TextWithTooltip
                            text={
                                !isSubRow
                                    ? ""
                                    : deduceUsesLeft(
                                          row.original.available.toString(),
                                          row.original.type,
                                          hasBlanncoTokens
                                      )
                            }
                            highlight={!isSubRow ? undefined : suppressWarning(row.original)}
                        />
                    );
                },
                width: deriveColumnWidth(12, tableContainerRef),
            });
        }
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.total")} key="4" />,
            accessor: "total",
            Cell: ({ row }) => {
                const isSubRow = row.depth > 0;
                return (
                    <TextWithTooltip
                        text={isSubRow ? "" : deduceLicenseTotalValue(row.original, hasBlanncoTokens)}
                        highlight={isSubRow ? undefined : suppressWarning(row.original)}
                    />
                );
            },
            width: deriveColumnWidth(12, tableContainerRef),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.remaining")} key="5" />,
            accessor: "available",
            Cell: ({ row }) => {
                const isSubRow = row.depth > 0;
                const hasBlanncoTokens = deducedTierLicenses ? true : false;
                return (
                    <TextWithTooltip
                        content={isSubRow ? "" : deduceLicenseAvailability(row.original, hasBlanncoTokens)}
                        text={isSubRow ? "" : deduceAvailabilityTooltip(row.original, hasBlanncoTokens)}
                        highlight={isSubRow ? undefined : suppressWarning(row.original)}
                    />
                );
            },
            width: deriveColumnWidth(12, tableContainerRef),
        });
        if (!props.own) {
            columns.push({
                Header: () => <TextWithTooltip text={t("Licenses.used")} key="6" />,
                accessor: "used",
                Cell: ({ row }) => {
                    const isSubRow = row.depth > 0;
                    return (
                        <TextWithTooltip
                            text={isSubRow ? "" : deduceLicenseUsedValue(row.original, hasBlanncoTokens)}
                            highlight={isSubRow ? undefined : suppressWarning(row.original)}
                        />
                    );
                },
                width: deriveColumnWidth(14, tableContainerRef),
            });
            columns.push({
                Header: () => <TextWithTooltip text={t("Licenses.overallRemainingLicenses")} key="7" />,
                accessor: "overallRemaining",
                Cell: ({ row }) => {
                    const isSubRow = row.depth > 0;
                    return (
                        <TextWithTooltip
                            text={isSubRow ? "" : deduceLicenseOverallAvailableValue(row.original, hasBlanncoTokens)}
                            highlight={isSubRow ? undefined : suppressWarning(row.original)}
                        />
                    );
                },
                width: deriveColumnWidth(14, tableContainerRef),
            });
        }
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.expirationDate")} key="8" />,
            accessor: "expirationDate",
            Cell: ({ row }) => (
                <DateCell
                    tooltip={true}
                    value={row.original.expirationDate}
                    withoutTime={true}
                    highlight={suppressWarning(row.original)}
                />
            ),
            width: deriveColumnWidth(14, tableContainerRef),
        });
        return columns;
    };

    const columns: Array<Column<LicenseData>> = createColumns();

    const [state, setLicensesData] = React.useState<TableState>({
        licenses: props.initialLicenses.licenseData,
        cursor: props.initialLicenses.cursor,
        scrollPosition: props.scrollPosition,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchAllLicenses({
                abortController,
                identifier: initialLoading ? "" : state.cursor?.identifier,
                tenantIdentifier: initialLoading ? "" : state.cursor?.tenantIdentifier,
                search,
                own: props.own,
            })
            .then((licenseList) => {
                setLicensesData((prevState) => ({
                    ...prevState,
                    licenses: prevState.licenses.concat(licenseList.licenses.licenseData),
                    scrollPosition: prevState.licenses.length - 1,
                    cursor: licenseList.licenses.cursor,
                }));
                if (props.own || hasTenantCookie()) {
                    const hasValidationLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-validation"
                    );
                    props.setHasBmsValidationLicenses(hasValidationLicenses);
                    const hasBbtiLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-bbti"
                    );
                    props.setHasBmsBbtiLicenses(hasBbtiLicenses);
                    const hasInsuranceLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-insurance"
                    );
                    props.setHasBmsInsuranceLicenses(hasInsuranceLicenses);
                    const hasUsdkLicenses = licenseList.licenses.licenseData.some((license) => license.type === "usdk");
                    props.setHasUsdkLicenses(hasUsdkLicenses);
                    const hasNtfLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-ntf"
                    );
                    props.setHasBmsNtfLicenses(hasNtfLicenses);
                    const hasAllLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-all"
                    );
                    props.setHasBmsAllLicenses(hasAllLicenses);
                    props.setHasBmsLicenses(
                        hasValidationLicenses ||
                            hasBbtiLicenses ||
                            hasInsuranceLicenses ||
                            hasNtfLicenses ||
                            hasAllLicenses ||
                            hasUsdkLicenses
                    );
                }

                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("Licenses.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setLicensesData({
            licenses: [],
            cursor: { identifier: "", tenantIdentifier: "" },
            scrollPosition: 0,
        });
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [search]);

    React.useEffect(() => {
        if (isLicensingModelBundleWithToken()) {
            tokenRates();
        }
    }, []);

    let dataCount = null;
    if (state.licenses.length > 0) {
        dataCount = t("Licenses.licenseSearchResultHint", { dataCount: state.licenses.length });
    }

    return (
        <>
            <div className={commonStyle.tierBannerContainer}>
                <LicenseTierBanner />
            </div>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={loading} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.LICENSE_TABLE}
                    data={state.licenses}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("Licenses.emptyStateMessage", { email: SUPPORT_EMAIL })}
                    testId={testIds.workArea.license.table.itself}
                />
            </div>
            <BundleTokenPricingView
                visibility={tokenPricingTierModalVisible}
                setVisibility={setTokenPricingTierModalVisible}
                productToRate={finalRateList}
            />
            {state.cursor != null &&
                state.licenses.length >= 100 &&
                state.licenses.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(
                            buttonStyle.buttonWithoutIcon,
                            buttonStyle.primaryButton,
                            buttonStyle.loadMoreButton
                        )}
                        onClick={() => {
                            fetchData(false);
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

LicensesTable.defaultProps = {
    initialLicenses: {
        licenseData: [],
        cursor: { identifier: "", tenantIdentifier: "" },
    },
    requestFailureMessage: "",
};

export default connector(LicensesTable);
