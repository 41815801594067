import classNames from "classnames";
import { TFunction } from "i18next";
import JSZip from "jszip";
import React, { useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { createImportFailureReasonLocalizations } from "./failureReason";
import style from "./import-report-dialog.scss";
import { latinMappings } from "./latinMapping";
import FailedView from "components/import-file-dialog/FailedView";
import ImportFileDialog, { DialogState } from "components/import-file-dialog/ImportFileDialog";
import ImportJobReadyToImportView from "components/import-file-dialog/ImportJobReadyToImportView";
import ImportJobUploadFailedFilesView from "components/import-file-dialog/ImportJobUploadFailedFilesView";
import LoadingView from "components/import-file-dialog/LoadingView";
import SelectingView from "components/import-file-dialog/SelectingView";
import {
    BDE_WORKFLOW_LICENSE_ID,
    BMDE_WORKFLOW_LICENSE_ID,
    createProductIdToNameMap,
} from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import NotificationModal from "components/modal/NotificationModal";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { LicenseData, LicenseList } from "domain/licenses";
import { deduceAvailableLicenses } from "services/licenses/LicenseService";
import {
    ReportImportJobReport,
    ReportImportJobStatus,
    ReportImportLicense,
    reportImportService,
    UploadUrl,
} from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttonStyle from "styles/buttons.scss";
import { toUtcDateString } from "utils/format";
import { Logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface Props {
    onClose: () => void;
    dialogState: DialogState;
    fileList?: File[];
    fetchAllLicenses: ({
        abortController,
        identifier,
        tenantIdentifier,
        search,
        own = true,
    }: {
        abortController?: AbortController;
        identifier?: string;
        tenantIdentifier?: string;
        search?: string;
        own?: boolean;
    }) => Promise<LicenseList>;
    setDialogState: (dialogState: DialogState) => void;
}

const MAX_INPUT_SIZE_BYTES = 100_000_000;
const MAX_FILE_COUNT = 1000;
const INITIAL_POLLING_DELAY_MILLISECONDS = 5000;
const MAX_POLLING_DELAY_MILLISECONDS = 10_000;
const MAX_CONSECUTIVE_POLLING_FAILURES = 3;
const MAX_TOTAL_POLLING_THRESHOLD_MILLISECONDS = 300_000;
const LARGE_BATCH_POLLING_THRESHOLD_MILLISECONDS = 10_000;
const UPLOAD_REPORTS_CHUNK_SIZE = 10;
const VALIDATION_TASK_CHUNK_SIZE = 10;
const VALIDATION_ZIP_ENTRY_TASK_CHUNK_SIZE = 10;
const UPLOAD_REPORT_RETRIES = 3;
const UPLOADING_REPORTS_STATE_CHANGE_DELAY = 2000;

const TABLE_COLUMN_ID_LICENSES = "licenses";
const TABLE_COLUMN_ID_AVAILABLE_LICENSES = "licensesAvailable";

const LOGGER = new Logger("ImportReportsDialog");

interface TableState {
    reports: ReportImportJobReport[];
    cursor: string[];
    totalFetchedReports: number;
    scrollPosition: number;
}

export interface FailedFile {
    filename: string;
    key: string;
}

const REPORT_ID_PATTERN = new RegExp("<document_id>(.*?)</document_id>", "g");
const UUID_PATTERN = new RegExp("^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$");

export function parseReportIds(xml: string): string[] {
    const matches = xml.matchAll(REPORT_ID_PATTERN);
    const result = [];
    for (const match of matches) {
        result.push(match[1]);
    }
    return result;
}

export function hasDuplicates(reportIds: string[]) {
    const uniqueIds = new Set(reportIds);
    return uniqueIds.size !== reportIds.length;
}

export function validateReportXml(xml: string | ArrayBuffer | null): number {
    if (typeof xml !== "string") {
        return 0;
    }
    if (!((xml.includes("<report>") && xml.includes("</report>")) || xml.includes("<encrypted_report"))) {
        return 0;
    }
    const foundIds = parseReportIds(xml);
    if (foundIds.length === 0) {
        return 0;
    }
    if (hasDuplicates(foundIds)) {
        return 0;
    }
    for (const id of foundIds) {
        if (!UUID_PATTERN.test(id)) {
            return 0;
        }
    }
    return foundIds.length;
}

export function deducePollingDelay(lastValue: number | null) {
    let result = INITIAL_POLLING_DELAY_MILLISECONDS;
    if (lastValue === null) {
        return result;
    }
    const newValue = lastValue * 2;
    result = newValue <= MAX_POLLING_DELAY_MILLISECONDS ? newValue : MAX_POLLING_DELAY_MILLISECONDS;
    return result;
}

const FEATURE_LICENSE_ID_TO_NUMERIC = new Map([
    [BMDE_WORKFLOW_LICENSE_ID, "14"],
    [BDE_WORKFLOW_LICENSE_ID, "15"],
]);

const PRODUCT_ID_TO_NAME = (() => {
    const map = createProductIdToNameMap();
    for (const each of [BMDE_WORKFLOW_LICENSE_ID, BDE_WORKFLOW_LICENSE_ID]) {
        const name = map.get(each);
        if (name == null) {
            LOGGER.debug(`Feature license name is null. ID: ${each}.`);
            continue;
        }
        const numeric = FEATURE_LICENSE_ID_TO_NUMERIC.get(each);
        if (numeric == null) {
            LOGGER.debug(`Feature license numeric ID is null. ID: ${each}.`);
            continue;
        }
        map.set(numeric, name);
    }
    return map;
})();

export function convertLicenseIdToName(id: number): string {
    return PRODUCT_ID_TO_NAME.get(id.toString()) ?? `License ${id}`;
}

export function deriveExtension(filename: string): string {
    return filename.substring(filename.lastIndexOf(".") + 1, filename.length).toLowerCase();
}

type AvailableLicenseError = "EXPIRED" | "NOT_ENOUGH" | "MISSING";
// When showing an error in UI about license issues, we'll only show a single
// reason for now. That's why we now have an order of precedence.
const AVAILABLE_LICENSE_ERROR_PRECEDENCE: AvailableLicenseError[] = ["MISSING", "EXPIRED", "NOT_ENOUGH"];
const AVAILABLE_LICENSE_ERROR_MESSAGE_KEYS: Record<AvailableLicenseError, string> = {
    EXPIRED: "ImportReportsDialog.importReportsTable.licenseError.expired",
    NOT_ENOUGH: "ImportReportsDialog.importReportsTable.licenseError.notEnough",
    MISSING: "ImportReportsDialog.importReportsTable.licenseError.missing",
};

// Trying not to use the word "type" to distinguish from types 1, 2, 3, etc.
export type LicenseModel = "SUBSCRIPTION" | "FEATURE" | "NORMAL";

interface AvailableLicense {
    amount: number;
    model: LicenseModel;
    errorReason?: AvailableLicenseError;
}

function extractAmount(t: TFunction, license: AvailableLicense): string {
    const subscription = license.model === "SUBSCRIPTION";
    const feature = license.model === "FEATURE";
    if (subscription || feature) {
        if (license.errorReason == null) {
            return subscription
                ? t("ImportReportsDialog.importReportsTable.subscriptionLicenseAmount")
                : t("ImportReportsDialog.importReportsTable.featureLicenseAmount");
        }
        return license.errorReason === "EXPIRED"
            ? t("ImportReportsDialog.importReportsTable.subscriptionLicenseExpired")
            : t("Common.notAvailable");
    }
    return license.amount.toString();
}

const SUBSCRIPTION_PRODUCT_IDS = new Set<number>([46, 50, 62]);
const FEATURE_PRODUCT_IDS = new Set<number>(
    Array.from(FEATURE_LICENSE_ID_TO_NUMERIC.values()).map((each) => Number(each))
);

export function deriveLicenseModel(id: number, type?: string): LicenseModel {
    if (type === "subscription" || SUBSCRIPTION_PRODUCT_IDS.has(id)) {
        return "SUBSCRIPTION";
    }
    if (type === "feature" || FEATURE_PRODUCT_IDS.has(id)) {
        return "FEATURE";
    }
    return "NORMAL";
}

export interface AvailableLicensesRendering {
    render: boolean;
    errorReason?: AvailableLicenseError;
    licenses: AvailableLicense[];
}

export function deriveStatusTitle(original: ReportImportJobReport, failureLocalizations: Map<string, string>): string {
    const failed: ReportImportJobStatus = "FAILED";
    if (original.status !== failed) {
        return original.status;
    }
    if (original.failureReason == null) {
        return failed;
    }
    return failureLocalizations.get(original.failureReason) ?? failed;
}

export function deriveAvailableLicensesRendering(
    required: ReportImportLicense[],
    available: LicenseData[]
): AvailableLicensesRendering {
    if (required.length === 0) {
        return { render: false, licenses: [] };
    }
    const availableIdToLicense: Map<number, LicenseData> = available
        .map((each) => {
            const translated = FEATURE_LICENSE_ID_TO_NUMERIC.get(each.type);
            return translated == null ? each : { ...each, type: translated };
        })
        .map((each) => [parseInt(each.type), each] as const)
        .filter((each) => !isNaN(each[0]))
        .reduce((map, each) => Map.prototype.set.apply(map, each), new Map());
    const now = toUtcDateString(new Date());
    const licenses: AvailableLicense[] = required.map((each): AvailableLicense => {
        const license = availableIdToLicense.get(each.productId);
        // The order of conditions isn't coincidental. It's exactly the same
        // order as in AVAILABLE_LICENSE_ERROR_PRECEDENCE and that's by design.
        const model = deriveLicenseModel(each.productId, license?.licenseType);
        if (license == null) {
            return { amount: 0, errorReason: "MISSING", model };
        }
        // If any given license is both expired and doesn't have enough licenses,
        // expiration shall take precedence.
        if (license.expirationDate < now) {
            return { amount: license.available, errorReason: "EXPIRED", model };
        }
        if (model === "SUBSCRIPTION" || model === "FEATURE") {
            if (license.available < 1) {
                return { amount: license.available, errorReason: "NOT_ENOUGH", model };
            }
        } else if (license.available < each.amount) {
            return { amount: license.available, errorReason: "NOT_ENOUGH", model };
        }

        return { amount: license.available, model };
    });
    // We know that having an undefined value after Array.filter is
    // impossible but here TypeScript needs a bit more convincing. Thus "as"
    // is used.
    const allReasons: Set<AvailableLicenseError> = new Set(
        licenses.map((each) => each.errorReason).filter((each) => each != null) as AvailableLicenseError[]
    );
    const result: AvailableLicensesRendering = {
        render: true,
        licenses,
    };
    if (allReasons.size > 0) {
        for (const each of AVAILABLE_LICENSE_ERROR_PRECEDENCE) {
            if (allReasons.has(each)) {
                result.errorReason = each;
                break;
            }
        }
    }
    return result;
}

export function renameFilename(filename: string): string {
    // The code in this function has been loosely copied from
    // https://github.com/hamxabaig/s3-filename file index.js (changeset
    // 96568ea) and https://github.com/jprichardson/string.js file string.js
    // (changeset 21eb9f6).
    const latinised = filename.trim().replace(/[^A-Za-z0-9[\] ]/g, (each) => latinMappings.get(each) || each);
    const lowered = latinised.toLocaleLowerCase();
    const withoutIllegal = lowered.replace(/[^0-9a-zA-Z! _\\.\\*'\\(\\)\\-]/g, "");
    const squashedWhitespace = withoutIllegal.replace(/\s+/g, " ");
    return squashedWhitespace.replace(/ /g, "_");
}

function convertFilenames(files: File[]): Map<string, File> {
    const filenames = new Map<string, File>();
    for (const each of files) {
        const originalFilename = each.name;
        const filename = renameFilename(originalFilename);
        if (filenames.has(filename)) {
            LOGGER.debug(
                `Duplicate derived filename "${filename}" so skipping file. Size: ${each.size}. Original filename: "${originalFilename}".`
            );
            continue;
        }
        LOGGER.debug(`Converted filename "${originalFilename}" to "${filename}".`);
        filenames.set(filename, each);
    }
    return filenames;
}

function processFile(
    filename: string,
    file: File,
    setProcessedFilesCount: React.Dispatch<React.SetStateAction<number>>,
    batchValidFilenames: string[],
    setAllValidFilesCount: React.Dispatch<React.SetStateAction<number>>,
    setTotalReports: React.Dispatch<React.SetStateAction<number>>
): Promise<boolean> {
    return new Promise((resolveFileTask, rejectFileTask) => {
        const extension = deriveExtension(filename);
        if (!(extension === "xml" || extension === "zip")) {
            rejectFileTask(filename);
        }
        if (file.size > MAX_INPUT_SIZE_BYTES) {
            LOGGER.error(
                "Filesize limit exceeded. Skipping file. " +
                    `Derived filename: ${filename}. Original filename: ${file.name}.`
            );
            rejectFileTask(filename);
        }
        if (extension === "zip") {
            JSZip.loadAsync(file).then(async (zip) => {
                const entries = Object.keys(zip.files).map((name) => {
                    return zip.files[name];
                });
                let validEntryCount = 0;
                for (let i = 0; i < entries.length; i += VALIDATION_ZIP_ENTRY_TASK_CHUNK_SIZE) {
                    const zipEntryTasks = [];
                    const zipEntryTaskChunk = entries.slice(i, i + VALIDATION_ZIP_ENTRY_TASK_CHUNK_SIZE);
                    for (const entry of zipEntryTaskChunk) {
                        if (entry.dir || deriveExtension(entry.name) != "xml") {
                            continue;
                        }
                        const zipTask = new Promise((resolveZipTask, rejectZipTask) => {
                            return entry.async("text").then((text: string | null) => {
                                const foundReportCount = validateReportXml(text);
                                if (foundReportCount) {
                                    validEntryCount++;
                                    setTotalReports((prevState) => prevState + foundReportCount);
                                    resolveZipTask(foundReportCount);
                                } else {
                                    rejectZipTask(foundReportCount);
                                }
                                setProcessedFilesCount((prevState) => prevState + 1);
                            });
                        });
                        zipEntryTasks.push(zipTask);
                    }
                    await Promise.allSettled(zipEntryTasks);
                }
                if (validEntryCount > 0) {
                    batchValidFilenames.push(filename);
                    setAllValidFilesCount((prevCount) => prevCount + 1);
                }
                resolveFileTask(validEntryCount > 0);
            });
        } else {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                const foundReportCount = validateReportXml(reader.result);
                if (foundReportCount > 0) {
                    batchValidFilenames.push(filename);
                    setAllValidFilesCount((prevCount) => prevCount + 1);
                    setTotalReports((prevState) => prevState + foundReportCount);
                    resolveFileTask(true);
                } else {
                    rejectFileTask(foundReportCount);
                }
                setProcessedFilesCount((prevState) => prevState + 1);
            };
        }
    });
}

const ImportReportsDialog = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    // First null so convertFilenames isn't constantly invoked on every render.
    const filenameToFile = React.useRef<Map<string, File> | null>(null);
    if (filenameToFile.current == null) {
        filenameToFile.current = convertFilenames(props.fileList ?? []);
    }

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [error, setError] = React.useState<string | undefined>();
    const [jobId, setJobId] = React.useState<string | undefined>();
    const [urls, setUrls] = React.useState<UploadUrl[]>([]);
    const [bulkImportKeys, setBulkImportKeys] = React.useState<string[]>([]);
    const [tableState, setTableState] = React.useState<TableState>({
        reports: [],
        cursor: [],
        scrollPosition: 0,
        totalFetchedReports: 0,
    });
    const savedCallback = useRef<() => void>();
    // When pollingDelay is non-null, we poll.
    const [pollingDelay, setPollingDelay] = useState<number | null>(null);
    const [consecutivePollingFailures, setConsecutivePollingFailures] = useState(0);
    const [totalPollingDelay, setTotalPollingDelay] = useState(0);
    const [inInitialUploadStage, setInInitialUploadStage] = useState(true);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [licenses, setLicenses] = React.useState<LicenseList>();
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [visibleLicenseColumns, setVisibleLicenseColumns] = React.useState(true);
    const [preparingMessage, setPreparingMessage] = React.useState<string>("");
    const [uploadingBatchReportsCount, setUploadingBatchReportsCount] = React.useState<number>(0);
    const [uploadingProgressCount, setUploadingProgressCount] = React.useState<number>(0);
    const [validatingProgressCount, setValidatingProgressCount] = React.useState<number>(0);
    const [failedUploads, setFailedUploads] = React.useState<FailedFile[]>([]);
    const [bulkMode, setBulkMode] = React.useState(false);
    const [totalValidFileCount, setTotalValidFileCount] = React.useState(0);
    const [batchValidFileCount, setBatchValidFileCount] = React.useState(0);
    const [batchProcessedFileCount, setBatchProcessedFileCount] = React.useState(0);
    const [totalReportCount, setTotalReportCount] = React.useState(0);
    const [atLeastOneValid, setAtLeastOneValid] = React.useState(false);
    const [licenseIssue, setLicenseIssue] = useState(false);
    const [fetching, setFetching] = React.useState(false);

    const fetchData = () => {
        setInitialLoading(true);
        if (!jobId) {
            setLoading(true);
            return;
        }
        setFetching(true);

        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportImportService
            .fetchReportImportJob(
                jobId,
                initialLoading ? [] : tableState.cursor,
                bulkMode ? "BULK" : "NORMAL",
                "IMPORT",
                abortController
            )
            .then((response) => {
                setConsecutivePollingFailures(0);
                setValidatingProgressCount(response.validatedCount);
                setTotalReportCount(response.totalCount);
                if (response.reports.length === 0) {
                    let newPollingDelay: number | null = deducePollingDelay(pollingDelay);
                    if (totalPollingDelay > MAX_TOTAL_POLLING_THRESHOLD_MILLISECONDS) {
                        newPollingDelay = null;
                        props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                    }
                    setPollingDelay(newPollingDelay);
                    setTotalPollingDelay(newPollingDelay === null ? 0 : totalPollingDelay + newPollingDelay);
                    return;
                }
                if (validatingProgressCount < response.totalCount) {
                    return;
                }
                if (response.status === "VALIDATED") {
                    setPollingDelay(null);
                    const promise: Promise<LicenseList | null> = response.reports.some(
                        (each) => each.licenses.length > 0
                    )
                        ? fetchLicenses()
                        : Promise.resolve(null);
                    promise.then((licenseList: LicenseList | null) => {
                        const batchLicenseIssue =
                            licenseList != null &&
                            response.reports.some(
                                (each) =>
                                    deriveAvailableLicensesRendering(each.licenses, licenseList.licenses.licenseData)
                                        .errorReason != null
                            );
                        setVisibleLicenseColumns(response.reports.some((each) => each.licenses.length > 0));
                        setTableState((prevState) => ({
                            ...prevState,
                            reports: prevState.reports.concat(response.reports),
                            cursor: response.cursor,
                            scrollPosition: prevState.reports.length - 1,
                            totalFetchedReports: prevState.reports.length + response.reports.length,
                        }));
                        setLoading(false);
                        props.setDialogState(DialogState.VALIDATED_REPORTS);
                        setInInitialUploadStage(false);
                        if (batchLicenseIssue) {
                            setLicenseIssue(true);
                        }
                        if (response.reports.some((report) => report.status === "VALIDATED")) {
                            setAtLeastOneValid(true);
                        }
                    });
                }
            })
            .catch(() => {
                if (consecutivePollingFailures > MAX_CONSECUTIVE_POLLING_FAILURES) {
                    setConsecutivePollingFailures(0);
                    setPollingDelay(null);
                    props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                    return;
                }
                setConsecutivePollingFailures(consecutivePollingFailures + 1);
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                    setFetching(false);
                }
            });
    };

    const fetchLicenses = (): Promise<LicenseList> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        return props
            .fetchAllLicenses({ abortController })
            .then((licenseList) => {
                const deducedList = deduceAvailableLicenses(licenseList);
                setLicenses(deducedList);
                return deducedList;
            })
            .catch((reason) => {
                if (!abortController.signal.aborted) {
                    LOGGER.error('Failed to fetch licenses for report import "Licenses available" column.', reason);
                    props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                }
                return Promise.reject();
            });
    };

    useEffect(() => {
        savedCallback.current = fetchData;
    }, [fetchData]);

    useEffect(() => {
        function callFetchData() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (pollingDelay != null) {
            const id = setInterval(callFetchData, pollingDelay);
            return () => {
                clearInterval(id);
            };
        }
    }, [fetchData, pollingDelay]);

    const dialogLogic = async () => {
        switch (props.dialogState) {
            case DialogState.SELECTING_FILE: {
                setError(undefined);
                return;
            }

            case DialogState.PREPARING_UPLOAD: {
                if (filenameToFile.current == null) {
                    setError(t("Common.noFileSelected"));
                    return;
                }

                const inputFiles = filenameToFile.current;
                if (!inputFiles) {
                    return;
                }
                if (inputFiles.size > MAX_FILE_COUNT) {
                    setError(
                        t("ImportReportsDialog.importReports.tooManyFiles", {
                            fileCount: inputFiles.size,
                            allowedCount: MAX_FILE_COUNT,
                        })
                    );
                    props.setDialogState(DialogState.LOADING_FILE_FAILED);
                    return;
                }
                setPreparingMessage(
                    t("ImportReportsDialog.importReports.preparingMessage", {
                        totalReports: inputFiles.size,
                    })
                );

                const batchValidFilenames: string[] = [];
                const filesArray = Array.from(inputFiles);
                for (let i = 0; i < filesArray.length; i += VALIDATION_TASK_CHUNK_SIZE) {
                    const tasks = [];
                    const fileTaskChunk = filesArray.slice(i, i + VALIDATION_TASK_CHUNK_SIZE);
                    for (const [filename, file] of fileTaskChunk) {
                        const task = processFile(
                            filename,
                            file,
                            setBatchProcessedFileCount,
                            batchValidFilenames,
                            setTotalValidFileCount,
                            setTotalReportCount
                        );
                        tasks.push(task);
                    }
                    await Promise.allSettled(tasks);
                }
                if (totalValidFileCount < 1 && batchValidFilenames.length < 1) {
                    setError(t("ImportReportsDialog.importReports.noValidFiles"));
                    props.setDialogState(DialogState.LOADING_FILE_FAILED);
                    return;
                } else if (totalValidFileCount > 0 && batchValidFilenames.length < 1) {
                    fetchData();
                    return;
                }

                setUploadingBatchReportsCount(batchValidFilenames.length);
                const abortController = new AbortController();
                abortControllers.push(abortController);
                if (jobId) {
                    reportImportService
                        .fetchUploadUrls(jobId, batchValidFilenames, abortController)
                        .then((response) => {
                            if (inInitialUploadStage) {
                                setUrls((prev) => prev.concat(response.urls));
                            } else {
                                setUrls(response.urls);
                            }
                            props.setDialogState(DialogState.UPLOADING_REPORTS);
                        })
                        .catch(() => {
                            if (!abortController.signal.aborted) {
                                props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                            }
                        });
                } else {
                    const bulk = batchValidFilenames.some((filename) => deriveExtension(filename) === "zip");
                    setBatchValidFileCount(batchValidFilenames.length);
                    setBulkMode(bulk);
                    reportImportService
                        .initializeJob(bulk, abortController)
                        .then((response) => {
                            setJobId(response.jobId);
                            reportImportService
                                .fetchUploadUrls(response.jobId, batchValidFilenames, abortController)
                                .then((response) => {
                                    setUrls(response.urls);
                                    props.setDialogState(DialogState.UPLOADING_REPORTS);
                                })
                                .catch(() => {
                                    if (!abortController.signal.aborted) {
                                        props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                                    }
                                });
                        })
                        .catch(() => {
                            if (!abortController.signal.aborted) {
                                props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                            }
                        });
                }
                return;
            }

            case DialogState.UPLOADING_REPORTS: {
                await uploadFiles();
                return;
            }

            case DialogState.VALIDATING_REPORTS: {
                if (!jobId) {
                    props.setDialogState(DialogState.IMPORTING_FAILED);
                    return;
                }

                if (failedUploads.length > 0) {
                    props.setDialogState(DialogState.UPLOADING_REPORTS_FAILED);
                    return;
                }

                const abortController = new AbortController();
                abortControllers.push(abortController);
                setValidatingProgressCount(0);
                reportImportService
                    .validateReports(
                        jobId,
                        { bucketKeys: urls.map((url) => url.key), totalReports: totalReportCount },
                        abortController
                    )
                    .then(() => {
                        setPollingDelay(INITIAL_POLLING_DELAY_MILLISECONDS);
                    })
                    .catch(() => {
                        if (!abortController.signal.aborted) {
                            props.setDialogState(DialogState.PREPARING_UPLOAD_FAILED);
                        }
                    });
                return;
            }
        }
    };

    useEffect(() => {
        if (
            (failedUploads.length || uploadingProgressCount) &&
            uploadingProgressCount + failedUploads.length == uploadingBatchReportsCount
        ) {
            wait(UPLOADING_REPORTS_STATE_CHANGE_DELAY).then(() => {
                if (bulkMode) {
                    setAtLeastOneValid(batchValidFileCount > 0);
                    props.setDialogState(DialogState.VALIDATED_REPORTS_BULK);
                } else {
                    props.setDialogState(DialogState.VALIDATING_REPORTS);
                }
                setUploadingProgressCount(0);
            });
        }
    }, [uploadingProgressCount, failedUploads]);

    const uploadFiles = async () => {
        const filenames = filenameToFile.current ?? new Map();
        for (let i = 0; i < urls.length; i += UPLOAD_REPORTS_CHUNK_SIZE) {
            const tasks = [];
            const urlChunk = urls.slice(i, i + UPLOAD_REPORTS_CHUNK_SIZE);

            for (const url of urlChunk) {
                const filename = url.key.split("/").slice(-1)[0];
                const file: File = filenames.get(filename);
                if (!file) {
                    LOGGER.error("Filename derived from S3 URL not found", filename);
                    continue;
                }
                const task = new Promise((resolve, reject) => {
                    if (bulkMode && deriveExtension(filename) === "zip") {
                        uploadFile(url.url, filename, url.key, file, resolve, reject, UPLOAD_REPORT_RETRIES, null);
                    } else {
                        const reader = new FileReader();
                        reader.readAsText(file);
                        reader.onload = async () => {
                            const readerResult = reader.result;
                            if (typeof readerResult === "string") {
                                await uploadFile(
                                    url.url,
                                    filename,
                                    url.key,
                                    readerResult,
                                    resolve,
                                    reject,
                                    UPLOAD_REPORT_RETRIES,
                                    null
                                );
                            }
                        };
                    }
                });
                tasks.push(task);
            }

            await Promise.allSettled(tasks);
        }
        return;
    };

    const wait = (delay: number | null) => new Promise((resolve) => setTimeout(resolve, delay != null ? delay : 0));

    const uploadFile = async (
        url: string,
        filename: string,
        key: string,
        data: string | File,
        resolver: (value: Response) => void,
        reject: (reason: string) => void,
        retries: number,
        pollingDelay: number | null
    ) => {
        await fetch(url, {
            method: "PUT",
            body: new Blob([data], {
                type: typeof data === "string" ? "application/xml" : "application/octet-stream",
            }),
        })
            .then((response) => {
                setUploadingProgressCount((prev) => ++prev);
                setBulkImportKeys((existingKeys) => [...existingKeys, key]);
                resolver(response);
            })
            .catch(() => {
                if (retries > 0) {
                    const newPollingDelay: number | null = deducePollingDelay(pollingDelay);
                    return wait(newPollingDelay).then(() => {
                        uploadFile(url, filename, key, data, resolver, reject, retries - 1, newPollingDelay);
                    });
                } else {
                    setFailedUploads((prev) => [...prev, { filename: filename, key: key }]);
                    LOGGER.error("Upload failed for file: " + filename);
                    reject("Upload failed for file: " + filename);
                }
            });
    };

    useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const dispatch = () => {
        if (filenameToFile.current == null || filenameToFile.current.size === 0) {
            props.setDialogState(DialogState.SELECTING_FILE);
        } else {
            props.setDialogState(DialogState.PREPARING_UPLOAD);
        }
    };

    const resetTable = () => {
        setTableState({
            reports: [],
            cursor: [],
            totalFetchedReports: 0,
            scrollPosition: 0,
        });
    };

    const handleFileDrop = (fileList: FileList, event: React.DragEvent<HTMLDivElement>) => {
        resetTable();
        usageStatisticsService.sendEvent({
            category: Category.REPORTS,
            action: Action.UPLOAD_REPORTS_DRAGGING,
        });
        event.preventDefault();
        if (fileList.length > 0) {
            filenameToFile.current = convertFilenames(Array.from(fileList));
        }
        dispatch();
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetTable();
        usageStatisticsService.sendEvent({
            category: Category.REPORTS,
            action: Action.UPLOAD_REPORTS_SELECTING,
        });
        const { files } = event.target;
        if (files !== null) {
            filenameToFile.current = convertFilenames(Array.from(files));
        }
        dispatch();
    };

    const onSelectFileClicked = () => {
        fileInputRef.current?.click();
    };

    const handleUploadAnother = () => {
        filenameToFile.current = new Map();
        setError(undefined);
        inInitialUploadStage && filterFailedUploadUrls();
        bulkMode && setUrls([]);
        setFailedUploads([]);
        setBatchProcessedFileCount(0);
        props.setDialogState(DialogState.SELECTING_FILE);
    };

    const handleGoBack = () => {
        props.setDialogState(DialogState.VALIDATED_REPORTS);
    };

    const selecting = (
        <SelectingView
            introductionMessage={t("ImportReportsDialog.selectFile.introductionLabel", {
                allowedCount: MAX_FILE_COUNT,
            })}
            handleFileDrop={handleFileDrop}
            onFileInputChange={onFileInputChange}
            onSelectFileClicked={onSelectFileClicked}
            fileInputRef={fileInputRef}
            showGoBackButton={!inInitialUploadStage}
            handleGoBack={handleGoBack}
        />
    );
    const processingFiles = (
        <LoadingView
            introductionMessage={t("ImportReportsDialog.selectFile.introductionLabel", {
                allowedCount: MAX_FILE_COUNT,
            })}
            loadingMessage={preparingMessage}
            progressMessage={t("ImportReportsDialog.importReports.processingProgressMessage", {
                processedFilesCount: batchProcessedFileCount,
            })}
        />
    );
    const succeeded = (
        <NotificationModal
            open={true}
            hide={props.onClose}
            title={t("ImportReportsDialog.title")}
            message={t("ImportReportsDialog.importReports.importingSucceededMessage")}
        />
    );
    const loadingFailed = (
        <FailedView
            failureMessage={error || t("ImportReportsDialog.importReports.genericError")}
            onUploadAnotherClicked={handleUploadAnother}
        />
    );
    const preparingFailed = (
        <FailedView
            failureMessage={t("ImportReportsDialog.importReports.preparingFailedMessage")}
            onUploadAnotherClicked={handleUploadAnother}
        />
    );
    const uploadingFiles = (
        <LoadingView
            introductionMessage={t("ImportReportsDialog.selectFile.introductionLabel", {
                allowedCount: MAX_FILE_COUNT,
            })}
            loadingMessage={t("ImportReportsDialog.importReports.uploadingMessage")}
            progressMessage={t("ImportReportsDialog.importReports.uploadingProgressMessage", {
                uploadedFilesCount: uploadingProgressCount,
                totalFilesCount: uploadingBatchReportsCount,
            })}
            progressBar={{
                show: true,
                percentage: Math.floor((uploadingProgressCount * 100) / uploadingBatchReportsCount),
                key: DialogState.UPLOADING_REPORTS,
            }}
        />
    );

    let validationMessage = t("ImportReportsDialog.importReports.validatingMessage");
    if (pollingDelay && pollingDelay >= LARGE_BATCH_POLLING_THRESHOLD_MILLISECONDS) {
        validationMessage = t("ImportReportsDialog.importReports.validatingTakingLongMessage");
    }
    const validatingFiles = (
        <LoadingView
            introductionMessage={t("ImportReportsDialog.selectFile.introductionLabel", {
                allowedCount: MAX_FILE_COUNT,
            })}
            loadingMessage={validationMessage}
            progressMessage={t("ImportReportsDialog.importReports.validatingProgressMessage", {
                validatedReportCount: validatingProgressCount,
                totalReportCount: totalReportCount,
            })}
            progressBar={{
                show: true,
                percentage: totalReportCount ? Math.floor((validatingProgressCount * 100) / totalReportCount) : 0,
                key: DialogState.VALIDATED_REPORTS,
            }}
        />
    );
    const importFailureLocalizations = createImportFailureReasonLocalizations(t);
    const columns: Array<Column<ReportImportJobReport>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.filename")} key="filename" />,
            accessor: "filename",
            Cell: (cellInfo) => <TextWithTooltip text={cellInfo.value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Common.reportUuid")} key="uuid" />,
            accessor: "uuid",
            Cell: ({
                cell: {
                    row: { original },
                },
            }) => (
                <div className={style.uuidColumnWrapper}>
                    <TextWithTooltip text={original.uuid} />
                </div>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.status")} key="status" />,
            accessor: "status",
            Cell: ({
                cell: {
                    row: { original },
                },
            }) => (
                <div className={style.uuidColumnWrapper}>
                    <StatusBadge
                        values={[
                            {
                                status: original.status === "VALIDATED" ? Status.SUCCESS : Status.WARNING,
                                title: deriveStatusTitle(original, importFailureLocalizations),
                            },
                        ]}
                        tooltip={true}
                    />
                </div>
            ),
        },
        {
            Header: () => (
                <TextWithTooltip
                    text={t("ImportReportsDialog.importReportsTable.licensesRequiredColumn")}
                    key="licensesRequired"
                />
            ),
            accessor: TABLE_COLUMN_ID_LICENSES,
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip
                    text={value.map((each) => `${convertLicenseIdToName(each.productId)}: ${each.amount}`).join(", ")}
                    dataItems={{
                        testid: testIds.workArea.report.reportImportResultsDialog.resultTable.requiredLicensesLabel,
                        // The structure of this JSON can never change without
                        // first discussing it with QA because test automation
                        // might rely on it.
                        licenses: JSON.stringify({
                            licenses: value.map((each) => ({ product_id: each.productId, amount: each.amount })),
                        }),
                    }}
                />
            ),
        },
        {
            Header: () => (
                <TextWithTooltip
                    text={t("ImportReportsDialog.importReportsTable.licensesAvailableColumn")}
                    key="licensesAvailable"
                />
            ),
            accessor: (report: ReportImportJobReport) => {
                const available = licenses == null ? [] : licenses.licenses.licenseData;
                return deriveAvailableLicensesRendering(report.licenses, available);
            },
            id: TABLE_COLUMN_ID_AVAILABLE_LICENSES,
            Cell: ({ cell: { value: value } }: { cell: { value: AvailableLicensesRendering } }) => {
                const licenseLabelData = {
                    testid: testIds.workArea.report.reportImportResultsDialog.resultTable.availableLicensesLabel,
                    // The structure of this JSON can never change without
                    // first discussing it with QA because test automation
                    // might rely on it.
                    licenses: JSON.stringify({
                        licenses: value.licenses.map((each) => ({
                            amount: each.amount,
                            error_reason: each.errorReason,
                        })),
                    }),
                };
                // TypeScript compiler doesn't complain when this is a
                // separate variable. Otherwise it would be inlined.
                const errorRecord: Record<string, string> =
                    value.errorReason == null ? {} : { reason: value.errorReason };
                const errorIconData = Object.assign(
                    {
                        testid: testIds.workArea.report.reportImportResultsDialog.resultTable
                            .availableLicensesErrorIcon,
                    },
                    value.errorReason == null ? {} : errorRecord
                );

                if (!value.render) {
                    return (
                        <div className={style.availableLicensesContainer}>
                            <div>
                                <TextWithTooltip text={""} dataItems={licenseLabelData} />
                            </div>
                            <TextWithTooltip text={""} dataItems={errorIconData} />
                        </div>
                    );
                }
                if (value.errorReason == null) {
                    return (
                        <div className={style.availableLicensesContainer}>
                            <div>
                                <TextWithTooltip
                                    text={value.licenses.map((each) => extractAmount(t, each)).join(", ")}
                                    dataItems={licenseLabelData}
                                />
                            </div>
                            <TextWithTooltip text={""} dataItems={errorIconData} />
                        </div>
                    );
                }

                function createBoldText() {
                    const amounts = value.licenses.map((each, index) => (
                        <span
                            key={index}
                            className={classNames({ [style.licenseAmountError]: each.errorReason != null })}
                        >
                            {extractAmount(t, each)}
                        </span>
                    ));
                    const separated = [];
                    for (const each of amounts) {
                        if (separated.length !== 0) {
                            separated.push(<span>,</span>);
                        }
                        separated.push(each);
                    }
                    return separated;
                }

                const boldText = createBoldText();
                const plainText = value.licenses.map((each) => extractAmount(t, each)).join(", ");
                return (
                    <div className={style.availableLicensesContainer}>
                        <div>
                            <TextWithTooltip text={plainText} dataItems={licenseLabelData}>
                                {boldText}
                            </TextWithTooltip>
                        </div>
                        <TextWithTooltip
                            text={t(AVAILABLE_LICENSE_ERROR_MESSAGE_KEYS[value.errorReason])}
                            dataItems={errorIconData}
                        >
                            <div className={style.errorBackground} />
                        </TextWithTooltip>
                    </div>
                );
            },
        },
    ];

    const table = (
        <>
            <Table
                tableIdentity={RepositoryKey.REPORT_IMPORT_TABLE}
                data={tableState.reports}
                columns={columns}
                loading={false}
                loaded={!initialLoading}
                tooltips={true}
                scrollTo={tableState.scrollPosition}
                dialogHeight={350}
                hiddenColumns={
                    visibleLicenseColumns ? [] : [TABLE_COLUMN_ID_LICENSES, TABLE_COLUMN_ID_AVAILABLE_LICENSES]
                }
                testId={testIds.workArea.report.reportResultsDialog.resultTable}
            />
            {tableState.cursor != null &&
                tableState.reports.length >= 100 &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        onClick={() => {
                            fetchData();
                        }}
                        className={classNames(
                            buttonStyle.buttonWithoutIcon,
                            buttonStyle.primaryButton,
                            buttonStyle.loadMoreButton
                        )}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );

    const tableWrapper = <FileDrop onDrop={handleFileDrop}>{table}</FileDrop>;

    const uploadingFilesFailedViewOnClose = () => {
        const state =
            failedUploads.length === uploadingBatchReportsCount
                ? DialogState.SELECTING_FILE
                : DialogState.VALIDATING_REPORTS;
        filterFailedUploadUrls();
        setFailedUploads([]);
        props.setDialogState(state);
    };

    const filterFailedUploadUrls = () => {
        const keys = failedUploads.map((file) => file.key);
        const filteredUrls = urls.filter((url) => !keys.includes(url.key));
        setUrls(filteredUrls);
    };
    const uploadingFilesFailed = (
        <ImportJobUploadFailedFilesView
            totalUploads={uploadingBatchReportsCount}
            failedUploads={failedUploads}
            onUploadAnotherClicked={handleUploadAnother}
            onClose={uploadingFilesFailedViewOnClose}
        />
    );

    const handleSubmit = () => {
        if (!jobId) {
            return;
        }

        usageStatisticsService.sendEvent({
            category: Category.REPORTS,
            action: Action.LAUNCH_REPORT_IMPORT,
        });

        props.setDialogState(DialogState.IMPORTING_REPORTS);

        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportImportService
            .importReports(
                jobId,
                abortController,
                bulkMode ? { bucketKeys: bulkImportKeys, totalReports: totalReportCount } : undefined
            )
            .then(() => {
                resetTable();
                setUrls([]);
                setBulkImportKeys([]);
                setJobId(undefined);
                setUploadingProgressCount(0);
                props.setDialogState(DialogState.IMPORTING_SUCCEEDED);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    props.setDialogState(DialogState.IMPORTING_FAILED);
                }
            });
    };

    const validated = (
        <ImportJobReadyToImportView
            allowImport={!fetching && atLeastOneValid && !licenseIssue}
            onUploadAnotherClicked={handleUploadAnother}
            onClose={props.onClose}
            onImportClicked={handleSubmit}
            table={tableWrapper}
            successMessage={t("ImportReportsDialog.selectFile.introductionLabel", {
                allowedCount: MAX_FILE_COUNT,
            })}
            warningMessage={t("ImportReportsDialog.importReportsTable.warningMessage")}
            searchHint={t("ImportReportsDialog.importReports.searchHint", {
                dataCount: tableState.totalFetchedReports,
            })}
        />
    );

    const validatedBulk = (
        <ImportJobReadyToImportView
            allowImport={atLeastOneValid}
            onUploadAnotherClicked={handleUploadAnother}
            onClose={props.onClose}
            onImportClicked={handleSubmit}
            successMessage={t("ImportReportsDialog.importReports.bulkImportReadyToBeImportedMessage", {
                fileCount: bulkImportKeys.length,
                reportIds: totalReportCount,
            })}
            warningMessage={t("ImportReportsDialog.importReportsTable.warningMessage")}
        />
    );

    const failed = (
        <NotificationModal
            open={true}
            hide={props.onClose}
            goBack={handleGoBack}
            title={t("ImportReportsDialog.title")}
            message={t("ImportReportsDialog.importReports.importingFailedMessage")}
        />
    );

    const importing = <LoadingView loadingMessage={t("ImportReportsDialog.importReports.importingMessage")} />;

    const stateToContent = new Map<DialogState, JSX.Element>([
        [DialogState.SELECTING_FILE, selecting],
        [DialogState.LOADING_FILE_FAILED, loadingFailed],
        [DialogState.PREPARING_UPLOAD, processingFiles],
        [DialogState.PREPARING_UPLOAD_FAILED, preparingFailed],
        [DialogState.UPLOADING_REPORTS, uploadingFiles],
        [DialogState.UPLOADING_REPORTS_FAILED, uploadingFilesFailed],
        [DialogState.VALIDATING_REPORTS, validatingFiles],
        [DialogState.VALIDATED_REPORTS, validated],
        [DialogState.VALIDATED_REPORTS_BULK, validatedBulk],
        [DialogState.IMPORTING_REPORTS, importing],
        [DialogState.IMPORTING_SUCCEEDED, succeeded],
        [DialogState.IMPORTING_FAILED, failed],
    ]);

    return (
        <ImportFileDialog
            onClose={props.onClose}
            dialogLogic={dialogLogic}
            stateToContent={stateToContent}
            dialogState={props.dialogState}
            stateDispatch={dispatch}
        />
    );
};

export default ImportReportsDialog;
