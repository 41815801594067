import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import style from "./delivery-history.scss";
import DeliveryHistoryMenuItems from "./DeliveryHistoryMenuItems";
import { fetchRateVersionNameFromUuid, ViewDeliveryHistory } from "./ViewDeliveryHistory";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { getDeliveryTypes } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { DeliveryHistory, LicenseDeliveryHistory } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getCurrentTenantDetails, hasTenantCookie } from "services/tenants/tenantCookieService";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    deliveryHistory: DeliveryHistory[];
    count: number;
    cursor: string;
    scrollPosition: number;
}

interface Props {
    initialDeliveryHistory: DeliveryHistory[];
    requestFailureMessage: string;
    onUpdate: (isSync: boolean) => void;
    count: number;
}

const DeliveryHistoryTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [failureMessage, setFailureMessage] = React.useState<string>("");
    const [deliveryHistoryVisibility, setDeliveryHistoryVisibility] = React.useState(false);
    const [initialLoadingForDeliveryDetails, setInitialLoadingForDeliveryDetails] = React.useState<boolean>(true);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [search, setSearchQuery] = React.useState("");
    const DELIVERY_TYPES = getDeliveryTypes();

    const DEFAULT_DELIVERY_DETAILS = {
        type: "",
        opportunityId: "",
        notes: "",
        caseNumber: "",
        licenses: [],
        uuid: "",
        creatorUuid: "",
        creationDate: "",
        editorUuid: "",
        editDate: "",
    };
    const [activeDeliveryHistoryDetails, setActiveDeliveryHistoryDetails] =
        React.useState<LicenseDeliveryHistory>(DEFAULT_DELIVERY_DETAILS);

    const fetchDeliveryHistoryDetails = (uuid: string) => {
        setInitialLoadingForDeliveryDetails(true);
        setActiveDeliveryHistoryDetails(DEFAULT_DELIVERY_DETAILS);
        setFailureMessage("");
        setDeliveryHistoryVisibility(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const fetchLicenseDeliveries = licenseService.fetchLicenseDeliveryHistory(uuid, abortController);
        const fetchRateVersions = licenseService.fetchTokenRates(abortController);
        Promise.all([fetchLicenseDeliveries, fetchRateVersions])
            .then(([fetchedLicenseDeliveries, fetchedRateVersions]) => {
                fetchedLicenseDeliveries.licenseRateVersion = fetchRateVersionNameFromUuid(
                    fetchedLicenseDeliveries.licenseRateVersion,
                    fetchedRateVersions
                );
                setActiveDeliveryHistoryDetails(fetchedLicenseDeliveries);
                setInitialLoadingForDeliveryDetails(fetchedLicenseDeliveries.type == "");
            })
            .catch(() => {
                setInitialLoadingForDeliveryDetails(true);
                setFailureMessage(t("DeliveryHistory.deliveryHistoryDialog.failedToLoadLicenseDetails"));
            });
    };

    const columns: Array<Column<DeliveryHistory>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.creationDate")} key="creationDate" />,
            accessor: "creationDate",
            Cell: (cellInfo) => (
                <>
                    {/* As kebab menu has only one conditional edit action then no need to display empty kebab menu  */}
                    {hasTenantCookie() ? (
                        <KebabMenu>
                            <DeliveryHistoryMenuItems
                                deliveryHistoryId={cellInfo.cell.row.original.uuid}
                                onDeliveryHistoryEdit={props.onUpdate}
                            />
                        </KebabMenu>
                    ) : (
                        ""
                    )}
                    <button
                        className={style.dateCell}
                        onClick={() => {
                            fetchDeliveryHistoryDetails(cellInfo.cell.row.original.uuid);
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_DELIVERY,
                                action: Action.VIEW_LICENSE_DELIVERY,
                            });
                        }}
                    >
                        <DateCell tooltip={true} value={cellInfo.cell.row.original.creationDate} />
                    </button>
                </>
            ),
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("DeliveryHistory.deliveryHistoryTable.deliveryType")} key="type" />,
            accessor: "type",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={DELIVERY_TYPES.get(value)} />,
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("DeliveryHistory.deliveryHistoryTable.notes")} key="notes" />,
            accessor: "notes",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(58, tableContainerRef),
        },
    ];
    const [setState, setDeliveryHistoryData] = React.useState<TableState>({
        deliveryHistory: [],
        count: 0,
        cursor: "",
        scrollPosition: 0,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchDeliveryHistory(abortController, search, initialLoading ? "" : setState.cursor)
            .then((data) => {
                setDeliveryHistoryData((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.deliveryHistory.length - 1,
                    deliveryHistory: prevState.deliveryHistory.concat(data.deliveryHistories),
                    count: data.count,
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("DeliveryHistory.deliveryHistoryTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                    setLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialDeliveryHistory.length > 0) {
            return;
        }
        setDeliveryHistoryData({ deliveryHistory: [], count: 0, cursor: "", scrollPosition: 0 });
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, search]);

    const modal = (
        <Modal
            key={1}
            isOpen={deliveryHistoryVisibility}
            hideModal={() => setDeliveryHistoryVisibility(false)}
            modalTitle={t("DeliveryHistory.deliveryHistoryDialog.title")}
        >
            {failureMessage != "" ? (
                failureMessage
            ) : (
                <ViewDeliveryHistory
                    deliveryHistoryDetails={activeDeliveryHistoryDetails}
                    loading={initialLoadingForDeliveryDetails}
                    tenantTier={getCurrentTenantDetails().tenantTier}
                    tenantType={getCurrentTenantDetails().type}
                />
            )}
        </Modal>
    );

    let dataCount = null;
    if (setState.deliveryHistory.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: setState.deliveryHistory.length });
    }
    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div>{dataCount}</div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.DELIVERY_HISTORY_TABLE}
                    data={setState.deliveryHistory}
                    columns={columns}
                    loaded={!initialLoading}
                    loading={loading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("DeliveryHistory.deliveryHistoryTable.emptyStateMessage")}
                />
            </div>
            {setState.cursor != null &&
                setState.cursor != "" &&
                setState.deliveryHistory.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(
                            buttonStyle.buttonWithoutIcon,
                            buttonStyle.primaryButton,
                            buttonStyle.loadMoreButton
                        )}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                label: "delivery-history",
                                action: Action.LOAD_MORE,
                                category: Category.WORKFLOW,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            {modal}
        </>
    );
};

DeliveryHistoryTable.defaultProps = {
    initialDeliveryHistory: [],
    requestFailureMessage: "",
};

export default DeliveryHistoryTable;
