import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { subpageContext } from "components/layout/ApplicationLayout";
import AddTenantsView from "components/tenants/add-tenant/AddTenantView";
import { LicenseData } from "domain/licenses";
import { ProductToRateList } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getCurrentTenantDetails,
    getTenantTier,
    getUserTier,
    hasTenantCookie,
} from "services/tenants/tenantCookieService";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    updateState: () => void;
    tenantExpirationDate?: Date;
    tenantLevel: number;
    tenantLicenses?: LicenseData[];
    rateVersions: ProductToRateList[];
    tenantUuid: string;
}
const TenantCreationAction = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);
    function hideTenantModal() {
        context?.setSubpage(undefined);
    }

    function showAddTenantFormModal() {
        usageStatisticsService.sendEvent({
            category: Category.TENANT,
            action: Action.ADD_TENANT,
        });
        context?.setSubpage(
            <AddTenantsView
                onHideModal={hideTenantModal}
                onTenantAdded={props.updateState}
                parentExpirationDate={props.tenantExpirationDate}
                parentLicenses={props.tenantLicenses}
                parentLevel={props.tenantLevel}
                type={getCurrentTenantDetails().type}
                tier={hasTenantCookie() ? getTenantTier() : getUserTier()}
                licensingModel={getCurrentTenantDetails().licensingModel}
                rateVersions={props.rateVersions}
                parentUuid={props.tenantUuid}
            />
        );
    }

    return (
        <>
            <button
                className={classNames(buttons.primaryButton, buttons.small, buttons.buttonWithoutIcon)}
                onClick={showAddTenantFormModal}
                data-testid={testIds.workArea.tenant.addTenantButton}
            >
                {t("AddCustomerView.addCustomer")}
            </button>
        </>
    );
};

export default TenantCreationAction;
