import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./bms-config.scss";
import BMSEditConfigForm from "./BmsEditConfigForm";
import { fetchFeaturesWithAvailableLicense } from "./bmsUtils";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { Configuration } from "domain/licenses";
import { deduceAvailableLicenses, licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttons from "styles/buttons.scss";
import { logger } from "utils/logging";

interface Props {
    uuid: string;
    created: string;
    modified: string;
    edit: boolean;
    onEdit?: () => void;
    onClose: () => void;
}

const BmsEditConfigurationView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [configuration, setConfiguration] = React.useState<Configuration>();
    const [featuresWithAvailableLicense, setFeaturesWithAvailableLicense] = useState<string[]>([]);
    const [message, setMessage] = React.useState<string>("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    React.useEffect(() => {
        if (props.edit) {
            usageStatisticsService.sendEvent({
                category: Category.LICENSE,
                action: Action.EDIT_LICENSE_CONFIGURATION,
            });
        } else {
            usageStatisticsService.sendEvent({
                category: Category.LICENSE,
                action: Action.VIEW_LICENSE_CONFIGURATION,
            });
        }
        fetchClientConfigurationAndLicenses();
    }, [props.uuid]);

    const fetchClientConfigurationAndLicenses = () => {
        if (props.uuid !== "") {
            setMessage("");
            setLoading(true);

            const abortController = new AbortController();
            abortControllers.push(abortController);

            const fetchConfigurationPromise = licenseService
                .fetchClientConfigurationData(props.uuid, abortController)
                .then((data) => {
                    setConfiguration(data);
                })
                .catch((error) => {
                    if (!abortController.signal.aborted) {
                        logger.error("Failed to load default configuration:", error);
                        setMessage(t("Configuration.viewConfigureDialog.requestFailed", { uuid: props.uuid }));
                    }
                });

            const fetchLicensesPromise = licenseService
                .fetchAllLicenses({ abortController, own: false })
                .then((licenseList) => {
                    const deducedList = deduceAvailableLicenses(licenseList);
                    const features = fetchFeaturesWithAvailableLicense(deducedList);
                    setFeaturesWithAvailableLicense(features);
                })
                .catch((error) => {
                    if (!abortController.signal.aborted) {
                        logger.error("Failed to load licenses:", error);
                        setMessage(t("Licenses.requestFailed"));
                    }
                });

            Promise.all([fetchConfigurationPromise, fetchLicensesPromise]).finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
            });
        }
    };

    const onSubmitEventHandler = async (configuration: Configuration): Promise<void> => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        if (configuration) {
            licenseService
                .saveConfiguration(props.uuid, configuration, abortController)
                .then(() => {
                    setMessage(t("Configuration.editConfigureDialog.successMessage"));
                    setLoading(false);
                    if (props.onEdit) {
                        props.onEdit();
                    }
                })
                .catch((e) => {
                    logger.error("Failed to update configuration:", e);
                    if (!abortController.signal.aborted) {
                        setMessage(t("Configuration.editConfigureDialog.failureMessage"));
                    }
                })
                .finally(() => {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                    }
                });
        }
    };

    return loading ? (
        <LoadingIndicator />
    ) : message !== "" ? (
        <>
            <TextBlock>{message}</TextBlock>
            <div className={style.okButtonContainer}>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                    onClick={props.onClose}
                >
                    {t("Common.ok")}
                </button>
            </div>
        </>
    ) : configuration !== undefined ? (
        <BMSEditConfigForm
            configuration={configuration}
            featuresWithAvailableLicenses={featuresWithAvailableLicense}
            uuid={props.uuid}
            created={props.created}
            modified={props.modified}
            edit={props.edit}
            onSuccess={onSubmitEventHandler}
            onEdit={props.onEdit}
            onClose={props.onClose}
        />
    ) : (
        <></>
    );
};

BmsEditConfigurationView.defaultProps = {
    edit: false,
};

export default BmsEditConfigurationView;
