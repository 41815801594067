import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./import-file-dialog.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    allowImport: boolean;
    warningMessage: string;
    successMessage: string;
    searchHint?: string;
    table?: JSX.Element;
    onClose: () => void;
    onUploadAnotherClicked: () => void;
    onImportClicked: () => void;
}

const ImportJobReadyToImportView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={style.leftAlignedWrapper}>
            <div className={style.introductionLabel}>
                <TextBlock>{props.successMessage}</TextBlock>
                <TextBlock>
                    <strong>{t("ImportReportsDialog.importReportsTable.warning")}:</strong> {props.warningMessage}
                </TextBlock>
                <TextBlock disableBottomSpacing={true}>{props.searchHint}</TextBlock>
            </div>
            {props.table}
            <ButtonContainer>
                <button
                    className={classNames(
                        buttons.secondaryButton,
                        buttons.medium,
                        style.button,
                        buttons.buttonWithoutIcon
                    )}
                    data-testid={testIds.common.dialog.closeButton}
                    onClick={() => props.onClose()}
                >
                    {t("Common.close")}
                </button>
                <button
                    className={classNames(
                        buttons.primaryButton,
                        buttons.medium,
                        style.button,
                        buttons.buttonWithoutIcon
                    )}
                    data-testid={testIds.common.importFileDialog.uploadAnotherButton}
                    onClick={() => props.onUploadAnotherClicked()}
                >
                    {t("Common.uploadAnotherFile")}
                </button>
                <button
                    className={classNames(
                        props.allowImport ? buttons.primaryButton : buttons.disabledButton,
                        buttons.medium,
                        style.button,
                        buttons.buttonWithoutIcon
                    )}
                    data-testid={testIds.common.importFileDialog.importButton}
                    onClick={() => props.onImportClicked()}
                    disabled={!props.allowImport}
                >
                    {t("Common.import")}
                </button>
            </ButtonContainer>
        </div>
    );
};

export default ImportJobReadyToImportView;
