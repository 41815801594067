import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import alertStyle from "./manage-license-alerts/manage-license-alert.scss";
import { createProductsMap } from "components/licenses/delivery-history/ViewDeliveryHistory";
import { AlertDetails } from "components/licenses/license-alerts/LicenseAlertsKebabMenu";
import style from "components/licenses/licenses-pool/view-license-pool.scss";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import StaticTable from "components/support/api-guide/StaticTable";
import DateCell from "components/table/DateCell";
import { Alert } from "services/licenses/LicenseAlertService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    handleOkClick: (value: boolean) => void;
    setEditView?: (value: boolean) => void;
    showForm: (value: boolean) => void;
    alertDetails?: (value: AlertDetails) => void;
    licenseAlertDetails: Alert;
}

export const ViewLicenseAlert = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const ALL_PRODUCTS = createProductsMap();
    return (
        <>
            <div className={classNames(form.formFields)}>
                <div className={form.formFields}>
                    <label className={form.label}>{t("LicenseAlerts.assign")}</label>
                    <label id="tenantName">{props.licenseAlertDetails.tenantName}</label>
                </div>
                <label htmlFor="licenses" className={form.label}>
                    {t("LicenseAlerts.licenses")}
                </label>
                <div className={classNames(alertStyle.ViewStaticTable)}>
                    <StaticTable
                        headers={[
                            {
                                value: t("LicenseAlerts.licenseType"),
                            },
                            {
                                value: t("LicenseAlerts.notifyDays"),
                            },
                            {
                                value: t("LicenseAlerts.expiration"),
                            },
                            {
                                value: t("LicenseAlerts.notifyAmount"),
                            },
                            {
                                value: t("LicenseAlerts.remainingLicenses"),
                            },
                            {
                                value: "",
                            },
                        ]}
                        cells={props.licenseAlertDetails.licenses.map((license) => {
                            return [
                                <div key={license.type} className={style.label}>
                                    {ALL_PRODUCTS.get(license.type)}
                                </div>,
                                <div key={"daysLeft" + license}>
                                    {isNaN(license.expirationThreshold) ? "" : license.expirationThreshold}
                                </div>,
                                license.expirationDate ? (
                                    <DateCell tooltip={true} value={license.expirationDate} withoutTime={true} />
                                ) : (
                                    t("Common.never")
                                ),
                                <div key={"amount" + license}>
                                    {isNaN(license.amountThreshold) ? "" : license.amountThreshold}
                                </div>,
                                <div key={"remainingLicenses" + license}>{license.remainingLicenses?.toString()}</div>,
                                license.triggered ? (
                                    <StatusBadge
                                        key={"triggered" + license}
                                        values={[
                                            {
                                                status: Status.INFO,
                                                title: t("LicenseAlerts.reminderSent"),
                                            },
                                        ]}
                                        tooltip={true}
                                    />
                                ) : null,
                            ];
                        })}
                    />
                </div>
            </div>
            <div className={classNames(style.editTenantButtons, style.okButtonContainer)}>
                {props.setEditView !== undefined && (
                    <button
                        className={classNames(buttons.medium, buttons.primaryButton, buttons.buttonWithoutIcon)}
                        onClick={(e) => {
                            props.setEditView?.(true);
                            props.showForm(true);
                            props.alertDetails !== undefined
                                ? props.alertDetails({
                                      alertUuid: props.licenseAlertDetails.alertUuid,
                                      alertName: props.licenseAlertDetails.alertName,
                                      tenantName: props.licenseAlertDetails.tenantName,
                                      tenantUuid: props.licenseAlertDetails.tenantUuid,
                                      poolName: props.licenseAlertDetails.poolName,
                                      poolUuid: props.licenseAlertDetails.poolUuid,
                                  })
                                : null;
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_ALERTS,
                                action: Action.EDIT_LICENSE_ALERTS,
                            });
                            e.preventDefault();
                        }}
                        data-testid={testIds.workArea.license.licenseAlerts.table.kebabMenu.editButton}
                    >
                        {t("Common.edit")}
                    </button>
                )}
                <button
                    className={classNames(buttons.medium, buttons.secondaryButton, buttons.buttonWithoutIcon)}
                    type="reset"
                    onClick={() => {
                        props.handleOkClick(false);
                    }}
                >
                    {t("Common.close")}
                </button>
            </div>
        </>
    );
};
