import classNames from "classnames";
import { Form, Formik, FormikConfig, FormikErrors, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import * as Yup from "yup";

import style from "./custom-report.scss";
import HddTemplate from "./HddTemplate";
import LaptopTemplate from "./LaptopTemplate";
import MobiledeviceTemplate from "./MobileDeviceTemplate";
import MonitorTemplate from "./MonitorTemplate";
import PcTemplate from "./PcTemplate";
import Button from "components/button/Button";
import { AddIcon } from "components/icons/AddIcon";
import Delete from "components/icons/Delete";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import { subpageContext } from "components/layout/ApplicationLayout";
import { SubpageLayout } from "components/layout/subpage-layout/SubpageLayout";
import Modal from "components/modal/Modal";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { CustomFieldFormValues, TemplateType, TemplateTypeEnum, templateTypes } from "domain/customReports";
import { CUSTOM_REPORT_FIELD_MAX_LENGTH } from "domain/globalConstants";
import { reportCreationService, ReportTemplate, ReportValue } from "services/report/erasure/ReportCreationService";
import { reportImportService } from "services/report/ReportImportService";
import { StoreState } from "store";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { Logger } from "utils/logging";

import testIds from "testIds.json";

const LOGGER = new Logger("CustomReportView");

interface Props {
    setVisible: (visible: boolean) => void;
    visible: boolean;
}

export interface FormValues {
    template: string;
    company?: string;
    person?: string;
    customFields: CustomFieldFormValues[];
}
interface Result {
    title: string;
    message: string;
}
const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const TEMPLATE_TYPE_LOCALIZATIONS: Record<TemplateType, string> = {
    Laptop: "ErasureReport.customReport.templateType.laptop",
    HDD: "ErasureReport.customReport.templateType.hdd",
    Mobiledevice: "ErasureReport.customReport.templateType.mobileDevice",
    Monitor: "ErasureReport.customReport.templateType.monitor",
    PC: "ErasureReport.customReport.templateType.pc",
};

// We this kind of generic function use of "any" is justified.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toList(container: any, prefix = ""): ReportValue[] {
    const list: ReportValue[] = [];
    Object.keys(container).forEach((key: string) => {
        if (!key) {
            return;
        }
        const value = container[key];
        if (value == null) {
            return;
        }
        const path = prefix === "" ? key : [prefix, key].join(".");
        if (typeof value === "string") {
            const trimmed = value.trim();
            if (trimmed !== "") {
                list.push({ path, value: trimmed });
            }
            return;
        }
        if (typeof value === "number") {
            list.push({ path, value });
            return;
        }
        if (typeof value === "object") {
            list.push(...toList(value, path));
        }
    });
    // Just to have deterministic results for tests.
    list.sort((first, second) => first.path.localeCompare(second.path));
    return list;
}

function isCustomFieldFormErrors(obj: unknown): obj is FormikErrors<CustomFieldFormValues> {
    if (typeof obj !== "object" || obj === null) {
        return false;
    }

    const potentialErrors = obj as Partial<FormikErrors<CustomFieldFormValues>>;

    return typeof potentialErrors.fieldName === "string" || typeof potentialErrors.fieldValue === "string";
}

const CustomReportView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [resultVisible, setResultVisible] = React.useState(false);
    const [formError, setFormError] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const context = React.useContext(subpageContext);

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (
        values,
        { setSubmitting, validateForm, setErrors }
    ) => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length > 0) {
            LOGGER.debug("Form values:", values);
            setErrors(errors);
            return;
        }
        const customFieldsList: ReportValue[] = [];
        values.customFields.forEach((field) => {
            if (field.fieldName && field.fieldValue) {
                customFieldsList.push({ path: `user_data.fields.${field.fieldName}`, value: field.fieldValue });
            }
        });
        // Create a new object without customFields
        const valuesWithoutCustomFields = Object.fromEntries(
            Object.entries(values).filter(([key]) => key !== "customFields")
        ) as Omit<FormValues, "customFields">;

        const valueList = toList(valuesWithoutCustomFields).filter((value) => value.path !== "template");
        const combinedValueList = [...valueList, ...customFieldsList];

        LOGGER.debug("Report values:", combinedValueList);

        if (combinedValueList.length > 0) {
            setFormError(false);
            try {
                const xml = reportCreationService.createReport(
                    valuesWithoutCustomFields.template as ReportTemplate,
                    combinedValueList
                );
                if (xml) {
                    createReport(xml);
                    showResult({
                        title: t("ErasureReport.customReport.buttonTitle"),
                        message: t("ErasureReport.customReport.createReportMessage"),
                    });
                }
            } catch (error) {
                LOGGER.error("Failed to create XML report:", error);
                showResult({
                    title: t("ErasureReport.customReport.buttonTitle"),
                    message: t("ErasureReport.customReport.createReportError"),
                });
            }
        } else {
            setFormError(true);
        }
        setSubmitting(false);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResultDialog = () => {
        setResultVisible(false);
        handleCloseClick();
    };
    const handleCloseClick = () => {
        context?.setSubpage(undefined);
    };
    const addRow = (formikProps: FormikProps<FormValues>) => {
        const newAdded: CustomFieldFormValues = {
            fieldName: "",
            fieldValue: "",
        };
        formikProps.setFieldValue("customFields", [...formikProps.values.customFields, newAdded]);
    };

    const validationSchema = Yup.object().shape({
        template: Yup.string().required(),
        customFields: Yup.array().of(
            Yup.object().shape({
                fieldName: Yup.string().required(t("ErasureReport.customReport.customFields.fieldNameErrorMessage")),
                fieldValue: Yup.string().required(t("ErasureReport.customReport.customFields.fieldValueErrorMessage")),
            })
        ),
    });

    const templateSpecificationRenderer = (template: string, formikProps: FormikProps<FormValues>) => {
        LOGGER.debug("Template:", template);
        switch (template) {
            case TemplateTypeEnum.HDD:
                return <HddTemplate formikProps={formikProps} />;
            case TemplateTypeEnum.Monitor:
                return <MonitorTemplate formikProps={formikProps} />;
            case TemplateTypeEnum.Mobiledevice:
                return <MobiledeviceTemplate formikProps={formikProps} />;
            case TemplateTypeEnum.Laptop:
                return <LaptopTemplate formikProps={formikProps} />;
            case TemplateTypeEnum.PC:
                return <PcTemplate formikProps={formikProps} />;
            default:
                return null;
        }
    };
    const clearFormFields = (formikProps: FormikProps<FormValues>) => {
        const { setFieldValue, values } = formikProps;
        Object.keys(values).forEach((key) => {
            if (key !== "template") {
                setFieldValue(key, "");
            }
            if (key == "customFields") {
                setFieldValue(key, []);
            }
        });
        setFieldValue("customFields", []);
        setFieldValue("company", "");
        setFieldValue("person", "");
        setFormError(false);
    };

    return (
        <>
            <SubpageLayout
                visible={props.visible}
                title={t("ErasureReport.customReport.buttonTitle")}
                buttons={
                    <>
                        <Button
                            variant={"PRIMARY"}
                            type="submit"
                            form="customReportForm"
                            testId={testIds.workArea.report.viewManualReportCreation.createButton}
                        >
                            {t("Common.create")}
                        </Button>
                    </>
                }
            >
                <Formik
                    validateOnBlur={true}
                    validateOnChange={true}
                    validateOnMount={true}
                    initialValues={{
                        template: TemplateTypeEnum.HDD,
                        customFields: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}
                >
                    {(formikProps) => (
                        <Form id="customReportForm">
                            <>
                                <div>
                                    <div className={style.infoDiv}>
                                        <span className={classNames(form.infoIcon, style.infoIcon)}>
                                            <Info
                                                borderColor={props.theme.contentBackgroundColor}
                                                color={props.theme.iconFillColor}
                                            />
                                        </span>
                                        <p className={style.infoMessage}>
                                            {t("ErasureReport.customReport.infoMessage")}
                                        </p>
                                    </div>
                                    <Heading tag="div" variant="SUBTITLE_1">
                                        {t("ErasureReport.customReport.templateSpecifications.title")}
                                    </Heading>
                                    <div className={form.formFields}>
                                        <label htmlFor="template" className={classNames(form.label)}>
                                            {t("ErasureReport.customReport.customReportTemplate.title")}
                                        </label>
                                        <select
                                            id="template"
                                            name="template"
                                            className={classNames(form.select, form.fixedWidthInput)}
                                            onChange={(e) => {
                                                formikProps.handleChange(e);
                                                clearFormFields(formikProps);
                                            }}
                                            value={formikProps.values.template}
                                            data-testid={
                                                testIds.workArea.report.viewManualReportCreation.templateSelect
                                            }
                                        >
                                            {templateTypes.map((value: TemplateType) => {
                                                return (
                                                    <option key={value} value={value}>
                                                        {t(TEMPLATE_TYPE_LOCALIZATIONS[value])}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {templateSpecificationRenderer(formikProps.values.template, formikProps)}
                                    <Heading tag="div" variant="SUBTITLE_1">
                                        {t("ErasureReport.customReport.generalDetails.title")}
                                    </Heading>
                                    <div
                                        className={form.formFields}
                                        data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
                                    >
                                        <label htmlFor="company" className={form.label}>
                                            {t("ErasureReport.customReport.generalDetails.company")}
                                        </label>
                                        <input
                                            id="company"
                                            name="company"
                                            className={classNames(form.input, form.fixedWidthInput)}
                                            maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                                            data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                                            data-path="description.description_entries.company_information.business_name"
                                            onChange={(event) => {
                                                // Allow any input including spaces
                                                const value = event.target.value;
                                                formikProps.setFieldValue(
                                                    "blancco_data.description.description_entries.company_information.business_name",
                                                    value
                                                );
                                                formikProps.setFieldValue("company", value);
                                            }}
                                            onBlur={(event) => {
                                                // Trim only when user leaves the input field
                                                const trimmedValue = event.target.value.trim();
                                                formikProps.setFieldValue(
                                                    "blancco_data.description.description_entries.company_information.business_name",
                                                    trimmedValue
                                                );
                                                formikProps.setFieldValue("company", trimmedValue);
                                            }}
                                            value={formikProps.values.company}
                                        />
                                    </div>
                                    <div
                                        className={form.formFields}
                                        data-testid={testIds.workArea.report.viewManualReportCreation.parentContainer}
                                    >
                                        <label htmlFor="person" className={form.label}>
                                            {t("ErasureReport.customReport.generalDetails.person")}
                                        </label>
                                        <input
                                            id="person"
                                            className={classNames(form.input, form.fixedWidthInput)}
                                            maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                                            data-testid={testIds.workArea.report.viewManualReportCreation.fieldInput}
                                            data-path="description.description_entries.company_information.erasure_person"
                                            onChange={(event) => {
                                                // Allow any input including spaces
                                                const value = event.target.value;
                                                formikProps.setFieldValue(
                                                    "blancco_data.description.description_entries.company_information.erasure_person",
                                                    value
                                                );
                                                formikProps.setFieldValue("person", value);
                                            }}
                                            onBlur={(event) => {
                                                // Trim only when user leaves the input field
                                                const trimmedValue = event.target.value.trim();
                                                formikProps.setFieldValue(
                                                    "blancco_data.description.description_entries.company_information.erasure_person",
                                                    trimmedValue
                                                );
                                                formikProps.setFieldValue("person", trimmedValue);
                                            }}
                                            value={formikProps.values.person}
                                        />
                                    </div>
                                    <Heading tag="div" variant="SUBTITLE_1">
                                        {t("ErasureReport.customReport.customFields.title")}
                                    </Heading>
                                    <div>
                                        {formikProps.values.customFields.length > 0 && (
                                            <StaticTable
                                                headers={[
                                                    {
                                                        value: t("ErasureReport.customReport.customFields.fieldName"),
                                                    },
                                                    {
                                                        value: t("ErasureReport.customReport.customFields.fieldValue"),
                                                    },
                                                    {
                                                        value: "",
                                                    },
                                                ]}
                                                cells={formikProps.values.customFields.map((field, index) => {
                                                    const fieldErrors =
                                                        formikProps.errors.customFields &&
                                                        Array.isArray(formikProps.errors.customFields)
                                                            ? formikProps.errors.customFields[index]
                                                            : undefined;
                                                    return [
                                                        <div key={`fieldName-${index}`}>
                                                            <input
                                                                type="text"
                                                                name={`customFields[${index}].fieldName`}
                                                                value={field.fieldName}
                                                                maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                className={classNames(form.input, form.fixedWidthInput)}
                                                                data-testid={
                                                                    testIds.workArea.report.viewManualReportCreation
                                                                        .customTable.nameInput.itself
                                                                }
                                                            />
                                                            {formikProps.touched.customFields &&
                                                                formikProps.touched.customFields[index]?.fieldName &&
                                                                isCustomFieldFormErrors(fieldErrors) &&
                                                                fieldErrors.fieldName && (
                                                                    <div
                                                                        className={classNames(
                                                                            form.fixedWidthInput,
                                                                            form.error
                                                                        )}
                                                                        data-testid={
                                                                            testIds.workArea.report
                                                                                .viewManualReportCreation.customTable
                                                                                .nameInput.errorLabel
                                                                        }
                                                                    >
                                                                        {fieldErrors.fieldName}
                                                                    </div>
                                                                )}
                                                        </div>,
                                                        <div key={`fieldValue-${index}`}>
                                                            <input
                                                                type="text"
                                                                name={`customFields[${index}].fieldValue`}
                                                                value={field.fieldValue}
                                                                maxLength={CUSTOM_REPORT_FIELD_MAX_LENGTH}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                className={classNames(form.input, form.fixedWidthInput)}
                                                                data-testid={
                                                                    testIds.workArea.report.viewManualReportCreation
                                                                        .customTable.valueInput.itself
                                                                }
                                                            />
                                                            {formikProps.touched.customFields &&
                                                                formikProps.touched.customFields[index]?.fieldValue &&
                                                                isCustomFieldFormErrors(fieldErrors) &&
                                                                fieldErrors.fieldValue && (
                                                                    <div
                                                                        className={classNames(
                                                                            form.fixedWidthInput,
                                                                            form.error
                                                                        )}
                                                                        data-testid={
                                                                            testIds.workArea.report
                                                                                .viewManualReportCreation.customTable
                                                                                .valueInput.errorLabel
                                                                        }
                                                                    >
                                                                        {fieldErrors.fieldValue}
                                                                    </div>
                                                                )}
                                                        </div>,
                                                        <div
                                                            key={`delete-${index}`}
                                                            className={style.margin}
                                                            onClick={() => {
                                                                const updatedFields = [
                                                                    ...formikProps.values.customFields,
                                                                ];
                                                                updatedFields.splice(index, 1);
                                                                formikProps.setFieldValue(
                                                                    "customFields",
                                                                    updatedFields
                                                                );
                                                            }}
                                                            data-testid={
                                                                testIds.workArea.report.viewManualReportCreation
                                                                    .customTable.removeButton
                                                            }
                                                        >
                                                            <Delete
                                                                noBackground={true}
                                                                strokeColor={props.theme.errorIconColor}
                                                            />
                                                        </div>,
                                                    ];
                                                })}
                                                testId={
                                                    testIds.workArea.report.viewManualReportCreation.customTable.itself
                                                }
                                            />
                                        )}
                                    </div>
                                    <span className={classNames(style.addMoreCustomFields)}>
                                        <div
                                            onClick={() => {
                                                addRow(formikProps);
                                            }}
                                            className={style.link}
                                            data-testid={
                                                testIds.workArea.report.viewManualReportCreation.customTable.addButton
                                            }
                                        >
                                            <AddIcon
                                                color={props.theme.contentBackgroundColor}
                                                linecolor={props.theme.iconFillColor}
                                            />
                                            <span className={style.addMoreButton}>
                                                {t("ErasureReport.customReport.customFields.addCustomField")}
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </>
                        </Form>
                    )}
                </Formik>
            </SubpageLayout>
            <div>
                {formError && (
                    <div className={style.errorBanner}>
                        <div className={style.warningIcon} tabIndex={0}>
                            <FailedRedNotificationIcon
                                backgroundColor={theme.errorIconColor}
                                iconColor={theme.contentBackgroundColor}
                            />
                        </div>
                        <p>{t("ErasureReport.customReport.atLeastOneFieldError")}</p>
                    </div>
                )}
            </div>
            <Modal isOpen={resultVisible} hideModal={hideResultDialog} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={form.okButtonContainer}>
                    <button
                        className={classNames(
                            buttonsStyle.primaryButton,
                            buttonsStyle.medium,
                            buttonsStyle.buttonWithoutIcon,
                            buttonsStyle.okButton
                        )}
                        onClick={hideResultDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default connector(CustomReportView);

export async function createReport(xml: string) {
    const initializeResponse = await reportImportService.initializeJob(false);
    const uploadUrl = (await reportImportService.fetchUploadUrls(initializeResponse.jobId, ["created.xml"])).urls[0];
    await fetch(uploadUrl.url, {
        method: "PUT",
        body: new Blob([xml], {
            type: "application/xml",
        }),
    });
    LOGGER.debug("Report uploaded, start validation in the backend.");

    await reportImportService.validateReports(initializeResponse.jobId, {
        totalReports: 1,
        bucketKeys: [uploadUrl.key],
    });

    const success = await waitForVerification(initializeResponse.jobId);
    if (!success) {
        return;
    }
    LOGGER.debug("Validation successful, start import proper.");

    const importAbort = new AbortController();
    await reportImportService.importReports(initializeResponse.jobId, importAbort);
    LOGGER.debug("Import called without errors.");
}

async function waitForVerification(jobId: string, waitExponent?: number): Promise<boolean> {
    waitExponent ??= 1;
    const response = await reportImportService.fetchReportImportJob(jobId, [], "NORMAL", "IMPORT");
    if (response.status === "WAITING") {
        const waitMillis = 1_000 ** waitExponent;
        await new Promise((resolve) => setTimeout(resolve, waitMillis));
        // 1000 ^ 1   ==   1s
        // 1000 ^ 1.1 ==  ~2s
        // 1000 ^ 1.2 ==  ~4s
        // 1000 ^ 1.3 ==  ~8s
        // 1000 ^ 1.4 == ~16s
        //               ----
        // sum           ~31s
        return await waitForVerification(jobId, waitExponent < 1.5 ? waitExponent + 0.1 : waitExponent);
    }
    if (response.status === "VALIDATED") {
        return true;
    }
    LOGGER.error("Report failed validation.");
    return false;
}
