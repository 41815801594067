import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { FEATURE_LICENSES, isSubscriptionLicense } from "../common";
import { availableLicenses } from "./AddLicensePools";
import { PoolDetails } from "./LicensePoolKebabMenu";
import { ViewPool } from "./LicensePoolsTable";
import style from "./view-license-pool.scss";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import { createProductsMap } from "components/licenses/delivery-history/ViewDeliveryHistory";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import { LicensePoolLicense } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import buttons from "styles/buttons.scss";
import { formatDateWithoutTime } from "utils/format";

interface Props {
    handleOkClick: (value: boolean) => void;
    edit: (value: boolean) => void;
    showForm: (value: boolean) => void;
    setAllLicenses: (value: License[]) => void;
    licenseFetchFailed: (value: boolean) => void;
    isLoading: (value: boolean) => void;
    poolDetails: (value: PoolDetails) => void;
    defaultPool: boolean;
}

export const ViewLicensePool = (
    props: { licensePoolDetails: ViewPool; loading: boolean; poolName: string } & Props
): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const ALL_PRODUCTS = createProductsMap();
    const licenses: LicensePoolLicense[] = [];
    let notes = "";
    let poolUuid = "";
    let assignedUsers = 0;
    props.licensePoolDetails.licensePools.map((each) => {
        if (each.poolName == props.poolName) {
            notes = each.notes;
            poolUuid = each.poolUuid;
            assignedUsers = each.assignedUsers;
            each.licenses.map((license) => licenses.push(license));
        }
    });
    const showManageView = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.isLoading(false);
        props.showForm(true);
        licenseService
            .fetchLicenses(abortController)
            .then((data) => {
                const allAvailableLicenses = availableLicenses(data.licenses);
                props.setAllLicenses(
                    allAvailableLicenses.filter(
                        (each) =>
                            !(isSubscriptionLicense(each.licenseType) || FEATURE_LICENSES.includes(each.licenseType))
                    )
                );
            })
            .catch(() => {
                props.licenseFetchFailed(true);
            })
            .finally(() => {
                props.isLoading(false);
            });
    };

    return props.loading ? (
        <div>
            <LoadingIndicator />
        </div>
    ) : (
        <>
            <div className={style.note}>
                {t("Common.note")}
                {notes}
            </div>
            <div className={style.tableContainer}>
                <StaticTable
                    tableClass={style.table}
                    headers={[
                        {
                            value: t("DeliveryHistory.deliveryHistoryDialog.table.licenseType"),
                        },
                        {
                            value: t("LicensePools.view.remainingLicense"),
                        },
                        {
                            value: t("LicensePools.view.licenses.table.expirationDate"),
                        },
                    ]}
                    cells={licenses.map((id, key) => {
                        return [
                            <div key={key} className={style.label}>
                                {
                                    <div key={key} className={classNames(style.label)}>
                                        {ALL_PRODUCTS.get(id.licenseId)}
                                    </div>
                                }
                            </div>,
                            <div key={key} className={style.label}>
                                {id.amount}
                            </div>,
                            <div key={key} className={style.label}>
                                {formatDateWithoutTime(id.expirationDate)}
                            </div>,
                        ];
                    })}
                />
            </div>
            <div className={classNames(style.editTenantButtons, style.okButtonContainer)}>
                {!props.defaultPool && (
                    <button
                        className={classNames(buttons.medium, buttons.primaryButton, buttons.buttonWithoutIcon)}
                        onClick={(e) => {
                            props.edit(true);
                            showManageView();
                            props.poolDetails({
                                uuid: poolUuid,
                                name: props.poolName,
                                assignedUsers: assignedUsers,
                                licenses: licenses,
                                notes: notes,
                            });
                            e.preventDefault();
                        }}
                    >
                        {t("Common.edit")}
                    </button>
                )}
                <button
                    className={classNames(buttons.medium, buttons.secondaryButton, buttons.buttonWithoutIcon)}
                    type="reset"
                    onClick={() => {
                        props.handleOkClick(false);
                    }}
                >
                    {t("Common.close")}
                </button>
            </div>
        </>
    );
};
