import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import LicensePoolKebabMenu, { PoolDetails } from "./LicensePoolKebabMenu";
import { ViewLicensePool } from "./ViewLicensePool";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { createLicenseTypes, FEATURE_LICENSES, isSubscriptionLicense } from "components/licenses/common";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import style from "components/licenses/license-history/license-events.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_LICENSE_POOL_CREATE } from "domain/authority";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import { LicensePool, licensePoolService, LicensePoolsResponse } from "services/licenses/LicensePoolService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

interface TableState {
    licensePools: LicensePool[];
    cursor: string;
    scrollPosition?: number;
    poolCount: number;
}

export interface ViewPool {
    cursor?: string;
    licensePools: LicensePool[];
}

interface Props {
    count: number;
    setAllLicenses: (value: License[]) => void;
    licenseFetchFailed: (value: boolean) => void;
    loading: (value: boolean) => void;
    showForm: (value: boolean) => void;
    isEdit: (value: boolean) => void;
    onLicensePoolDelete: () => void;
    cellDetails: (value: PoolDetails) => void;
    hideDialogView: React.Dispatch<React.SetStateAction<boolean>>;
}

const connector = connect(mapState, { applyMode });

const LicensePoolsTable = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [tableState, setTableState] = React.useState<TableState>({
        licensePools: [],
        cursor: "",
        scrollPosition: 0,
        poolCount: 0,
    });

    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [requestFailure, setRequestFailure] = React.useState();
    const [quickSearchFilter, setQuickSearchFilter] = React.useState("");
    const [refreshCount, setRefreshCount] = React.useState(0);
    const productList = createLicenseTypes(true)
        .filter((each) => !(FEATURE_LICENSES.includes(each.productId) || isSubscriptionLicense(each.productId)))
        .map((each) => each);

    const POOL_DETAILS = {
        cursor: "",
        licensePools: [],
        count: 0,
    };
    const [licensePoolDetails, setLicensesPoolDetails] = React.useState<ViewPool>(POOL_DETAILS);
    const [licensePoolDetailsVisibility, setLicensePoolDetailsVisibility] = React.useState(false);
    const [initialLoadingForPoolDetails, setInitialLoadingForPoolDetails] = React.useState<boolean>(true);
    const [poolName, setPoolName] = React.useState<string>("");
    const [defaultPool, setDefaultPool] = React.useState<boolean>(false);

    const modal = (
        <Modal
            key={1}
            isOpen={licensePoolDetailsVisibility}
            hideModal={() => setLicensePoolDetailsVisibility(false)}
            modalTitle={poolName}
        >
            <ViewLicensePool
                licensePoolDetails={licensePoolDetails}
                loading={initialLoadingForPoolDetails}
                handleOkClick={setLicensePoolDetailsVisibility}
                poolName={poolName}
                edit={props.isEdit}
                showForm={props.showForm}
                setAllLicenses={props.setAllLicenses}
                licenseFetchFailed={props.licenseFetchFailed}
                isLoading={props.loading}
                poolDetails={props.cellDetails}
                defaultPool={defaultPool}
            />
        </Modal>
    );
    function createLicenseCountColumns(columns: Column<LicensePool>[]) {
        if (tableState.licensePools.length > 0) {
            for (const product of productList) {
                /*Checks if the productId exists within the tableState.licensePools.*/
                if (
                    tableState.licensePools?.find((pool) =>
                        pool.licenses?.some((each) => (each.licenseId ? each.licenseId === product.productId : false))
                    )
                ) {
                    columns.push({
                        id: product.productId,
                        Header: () => <TextWithTooltip text={product.productName} key={columns.length} />,
                        accessor: (licensePool: LicensePool): LicensePool => licensePool,
                        Cell: ({ cell: { value: license } }: { cell: { value: LicensePool } }) => {
                            const matchedLicense = license.licenses.find(
                                (each) => each.licenseId === product.productId
                            );
                            return (
                                <TextWithTooltip text={matchedLicense ? matchedLicense.amount.toString() : undefined} />
                            );
                        },
                    });
                }
            }
            return columns;
        }
    }

    const createColumns = (): Column<LicensePool>[] => {
        const columns: Column<LicensePool>[] = [];
        columns.push({
            Header: () => <TextWithTooltip text={t("LicensePools.table.poolName")} key={columns.length} />,
            accessor: "poolName",
            Cell: (cellInfo) => (
                <>
                    {userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_CREATE]) &&
                        !cellInfo.cell.row.original.defaultPool && (
                            <KebabMenu>
                                <LicensePoolKebabMenu
                                    licensePoolName={cellInfo.cell.row.original.poolName}
                                    licensePoolUuid={cellInfo.cell.row.original.poolUuid}
                                    assignedUsers={cellInfo.cell.row.original.assignedUsers}
                                    licenses={cellInfo.cell.row.original.licenses}
                                    notes={cellInfo.cell.row.original.notes}
                                    setAllLicenses={props.setAllLicenses}
                                    licenseFetchFailed={props.licenseFetchFailed}
                                    loading={props.loading}
                                    showForm={props.showForm}
                                    edit={props.isEdit}
                                    poolDetails={props.cellDetails}
                                    onLicensePoolDelete={props.onLicensePoolDelete}
                                    setRefreshCount={setRefreshCount}
                                />
                            </KebabMenu>
                        )}
                    <div>
                        <button
                            className={style.licensePoolNameCell}
                            onClick={() => {
                                setPoolName(cellInfo.cell.row.original.poolName);
                                setDefaultPool(cellInfo.cell.row.original.defaultPool);
                                setInitialLoadingForPoolDetails(tableState.licensePools == null);
                                setLicensePoolDetailsVisibility(true);
                            }}
                        >
                            <TextWithTooltip text={cellInfo.cell.row.original.poolName} />
                        </button>
                    </div>
                </>
            ),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("LicensePools.table.creationDate")} key={columns.length} />,
            accessor: "creationDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("LicensePools.table.creatorName")} key={columns.length} />,
            accessor: "creatorName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("LicensePools.table.assignedUsers")} key={columns.length} />,
            accessor: "assignedUsers",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("LicensePools.table.notes")} key={columns.length} />,
            accessor: "notes",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        });
        createLicenseCountColumns(columns);
        return columns;
    };

    const fetchData = (initialLoading: boolean) => {
        initialLoading ? setInitialLoading(true) : setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);

        licensePoolService
            .fetchLicensePools(abortController, undefined, quickSearchFilter, !initialLoading ? tableState.cursor : "")
            .then((data: LicensePoolsResponse) => {
                setTableState((prevState) => ({
                    ...prevState,
                    licensePools: prevState.licensePools.concat(data.licensePools),
                    scrollPosition: prevState.licensePools.length - 1,
                    cursor: data.cursor,
                    poolCount: data.count,
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailure(t("LicensePools.table.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const columns: Column<LicensePool>[] = createColumns();

    const clearTable = () => {
        setInitialLoading(false);
        setLoading(false);
        setRequestFailure(undefined);
    };

    React.useEffect(() => {
        setInitialLoading(true);
        setTableState({
            licensePools: [],
            cursor: "",
            scrollPosition: 0,
            poolCount: 0,
        });
        setRefreshCount((prev) => ++prev);
    }, [quickSearchFilter, props.count]);

    React.useEffect(() => {
        if (props.theme) {
            setTableState({
                licensePools: [],
                cursor: "",
                scrollPosition: 0,
                poolCount: 0,
            });
            fetchData(true);
        } else {
            clearTable();
        }
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [refreshCount]);

    React.useEffect(() => {
        setLicensesPoolDetails(tableState);
    }, [tableState]);

    const rowCount =
        tableState.poolCount <= 0
            ? null
            : t("LicensePools.table.countLabel", {
                  partialCount: tableState.licensePools.length,
                  totalCount: tableState.poolCount,
              });
    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{rowCount}</div>
                <div className={classNames(formStyle.search, style.padding)}>
                    <SearchView setSearch={setQuickSearchFilter} searchInProgress={false} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.LICENSE_POOLS_TABLE}
                    data={tableState.licensePools}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailure}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    inDialogBox={false}
                    emptyMessage={t("LicensePools.table.emptyStateMessage")}
                    testId={testIds.workArea.license.licensePools.table.itself}
                />
            </div>
            {tableState.cursor != null &&
                tableState.licensePools.length >= TABLE_PAGE_LIMIT &&
                tableState.licensePools.length != 0 &&
                !requestFailure &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(
                            buttonStyle.buttonWithoutIcon,
                            buttonStyle.primaryButton,
                            buttonStyle.loadMoreButton
                        )}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                action: Action.LOAD_MORE,
                                category: Category.LICENSE_POOLS,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            {modal}
        </>
    );
};

export default connector(LicensePoolsTable);
