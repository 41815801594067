import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./add-entitlement.scss";
import AddEntitlementForm, { FormValues, LicenseCount } from "./AddEntitlementForm";
import Modal from "components/modal/Modal";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { Container, EmsConfiguration, LicenseData } from "domain/licenses";
import { licenseService, ProductRateList, ProductToRateList } from "services/licenses/LicenseService";
import { getCurrentTenantDetails, hasTenantCookie } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttonsStyle from "styles/buttons.scss";

interface Result {
    title: string;
    message: string;
}

export interface EntitlementRequestLicense {
    blanccoProductId: number;
    licenseAmount: number;
}
const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
}));

interface Props {
    emsConfiguration: EmsConfiguration;
    availableLicenses: LicenseData[];
    slContainers: Container[];
    onUpdate: () => void;
}

const AddEntitlementView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [addEntitlementFormVisible, setAddEntitlementFormVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const addEntitlementSubmitEventHandler = async (
        { type, description, containerId }: FormValues,
        licensesCount: LicenseCount[]
    ): Promise<void> => {
        const abortController = new AbortController();

        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            const licenses: EntitlementRequestLicense[] = [];
            licensesCount.forEach((license) => {
                licenses.push({ licenseAmount: license.assign, blanccoProductId: parseInt(license.productId) });
            });
            await licenseService.createEntitlement(
                type,
                description,
                licenses,
                abortController,
                type === "SL_UPDATE" ? containerId : undefined
            );
        } catch (e) {
            if (!signal.aborted) {
                showResult({
                    title: t("AddEntitlementsView.entitlementFailure"),
                    message: t("AddEntitlementsView.failureMessage", { email: SUPPORT_EMAIL }),
                });
                setAddEntitlementFormVisible(true);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setAddEntitlementFormVisible(false);
        showResult({
            title: t("AddEntitlementsView.entitlementSuccess"),
            message: t("AddEntitlementsView.successMessage"),
        });
    };

    const tenantDetails = getCurrentTenantDetails();
    const tokenRateList: ProductRateList[] = [];
    const fetchTokenRates = () => {
        const abortController = new AbortController();
        licenseService
            .fetchProductTokenRate(abortController, tenantDetails.uuid)
            .then((rateList: ProductToRateList[]) => {
                rateList.forEach((each) => {
                    each.productToRate.forEach((product) => tokenRateList.push(product));
                });
            });
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideSuccessDialog = () => {
        props.onUpdate();
        setResultVisible(false);
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    return (
        <>
            {fetchTokenRates()}
            <div>
                {hasTenantCookie() ? (
                    ""
                ) : props.emsConfiguration.hlEntitlements || props.emsConfiguration.slEntitlements ? (
                    <button
                        className={classNames(
                            buttonsStyle.primaryButton,
                            buttonsStyle.small,
                            buttonsStyle.buttonWithoutIcon
                        )}
                        onClick={() => {
                            if (getCurrentTenantDetails().licensingModel.includes("BUNDLE_WITH_TOKEN")) {
                                if (tokenRateList.length > 0) {
                                    setAddEntitlementFormVisible(true);
                                } else {
                                    showResult({
                                        title: t("AddEntitlementsView.title"),
                                        message: t("AddEntitlementsView.tokenRateFailureMessage", {
                                            email: SUPPORT_EMAIL,
                                        }),
                                    });
                                }
                            } else {
                                setAddEntitlementFormVisible(true);
                            }
                        }}
                    >
                        {t("AddEntitlementsView.addEntitlementButton")}
                    </button>
                ) : (
                    ""
                )}

                <Modal
                    isOpen={addEntitlementFormVisible}
                    hideModal={() => setAddEntitlementFormVisible(false)}
                    modalTitle={t("AddEntitlementsView.title")}
                >
                    <div className={style.fixedWidthModal}>
                        <AddEntitlementForm
                            submitEventHandler={addEntitlementSubmitEventHandler}
                            fetchedTokenRates={tokenRateList}
                            emsConfiguration={props.emsConfiguration}
                            availableLicenses={props.availableLicenses}
                            slContainers={props.slContainers}
                        />
                    </div>
                </Modal>

                <Modal isOpen={resultVisible} hideModal={hideSuccessDialog} modalTitle={result.title}>
                    <div className={style.resultContainer}>{result.message}</div>
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(
                                buttonsStyle.primaryButton,
                                buttonsStyle.medium,
                                buttonsStyle.buttonWithoutIcon,
                                buttonsStyle.okButton
                            )}
                            onClick={hideSuccessDialog}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    );
};
export default connector(AddEntitlementView);
