import classNames from "classnames";
import { ErrorMessage, Field, Formik, FormikConfig, Form as FormikForm, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { number, object, string } from "yup";

import Co2EquivalentsChart from "./Co2EquivalentsChart";
import { commonFunctions } from "./CommonFunctions";
import style from "components/dashboard/esg/esg-calculator.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { DashboardData, DEVICE_CATEGORY, DeviceType, deviceTypes, EsgData } from "domain/dashboard";
import { dashboardService } from "services/dashboard/DashboardService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface FormValues {
    deviceCategory: string;
    deviceNumber: number;
}

interface State {
    data: EsgData;
    calculated: boolean;
    loading: boolean;
    loadingFailed: boolean;
}

const MAX_DEVICE_COUNT = 1000_000_000;

const DEVICE_CATEGORY_LOCALIZATIONS: Record<DeviceType, string> = {
    Laptop: "EsgViews.deviceTypes.laptop",
    Desktop: "EsgViews.deviceTypes.desktop",
    Server: "EsgViews.deviceTypes.server",
    "Loose drive": "EsgViews.deviceTypes.looseDrive",
    Smartphone: "EsgViews.deviceTypes.smartphone",
    Tablet: "EsgViews.deviceTypes.tablet",
};

const EsgCalculatorView = (): JSX.Element => {
    const { t } = useTranslation();

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [calculatorVisible, setCalculatorVisible] = React.useState(false);
    const [calculatorConfiguration, setCalculatorConfiguration] = React.useState({
        Laptop: { weight: 0, co2: 0 },
        Server: { weight: 0, co2: 0 },
        Desktop: { weight: 0, co2: 0 },
        Smartphone: { weight: 0, co2: 0 },
        "Loose drive": { weight: 0, co2: 0 },
        Tablet: { weight: 0, co2: 0 },
    });

    const hideCalculator = () => {
        setCalculatorVisible(false);
    };

    const showCalculator = () => {
        setCalculatorVisible(true);
    };

    const [state, setState] = React.useState<State>({
        data: { months: [] },
        calculated: false,
        loading: true,
        loadingFailed: false,
    });

    const getComputedEsgData = (configuration: DashboardData, values: FormValues): EsgData => {
        const monthsBase = [
            {
                month: "2022-02",
                content: {
                    Laptop: { weight: 0, co2: 0 },
                    Desktop: { weight: 0, co2: 0 },
                    Server: { weight: 0, co2: 0 },
                    "Loose drive": { weight: 0, co2: 0 },
                    Tablet: { weight: 0, co2: 0 },
                    Smartphone: { weight: 0, co2: 0 },
                },
            },
        ];
        const result = {
            months: monthsBase,
        };
        switch (values.deviceCategory) {
            case DEVICE_CATEGORY.LAPTOP:
                result.months[0].content.Laptop.weight =
                    values.deviceNumber * configuration[values.deviceCategory].weight;
                result.months[0].content.Laptop.co2 = values.deviceNumber * configuration[values.deviceCategory].co2;
                break;
            case DEVICE_CATEGORY.DESKTOP:
                result.months[0].content.Desktop.weight =
                    values.deviceNumber * configuration[values.deviceCategory].weight;
                result.months[0].content.Desktop.co2 = values.deviceNumber * configuration[values.deviceCategory].co2;
                break;
            case DEVICE_CATEGORY.SERVER:
                result.months[0].content.Server.weight =
                    values.deviceNumber * configuration[values.deviceCategory].weight;
                result.months[0].content.Server.co2 = values.deviceNumber * configuration[values.deviceCategory].co2;
                break;
            case DEVICE_CATEGORY.LOOSE_DRIVE:
                result.months[0].content["Loose drive"].weight =
                    values.deviceNumber * configuration[values.deviceCategory].weight;
                result.months[0].content["Loose drive"].co2 =
                    values.deviceNumber * configuration[values.deviceCategory].co2;
                break;
            case DEVICE_CATEGORY.SMARTPHONE:
                result.months[0].content.Smartphone.weight =
                    values.deviceNumber * configuration[values.deviceCategory].weight;
                result.months[0].content.Smartphone.co2 =
                    values.deviceNumber * configuration[values.deviceCategory].co2;
                break;
            case DEVICE_CATEGORY.TABLET:
                result.months[0].content.Tablet.weight =
                    values.deviceNumber * configuration[values.deviceCategory].weight;
                result.months[0].content.Tablet.co2 = values.deviceNumber * configuration[values.deviceCategory].co2;
                break;
            default:
                throw new Error("Unknown device category: " + values.deviceCategory);
        }
        return result;
    };

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = (values) => {
        setState({
            ...state,
            data: getComputedEsgData(calculatorConfiguration, values),
            calculated: true,
        });
    };

    const fetchConfiguration = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);

        dashboardService
            .fetchCalculatorConfiguration(abortController)
            .then((data) => {
                setCalculatorConfiguration(data);
                setState({ ...state, loading: false });
            })
            .catch(() => {
                logger.error("Failed to fetch calculator configuration.");
                setState({ ...state, loadingFailed: true, loading: false });
            });
    };

    React.useEffect(() => {
        fetchConfiguration();
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const validateDeviceCount = (deviceCount: number): string | undefined => {
        let error;
        if (!Number.isInteger(deviceCount)) {
            error = t("EsgCalculatorView.deviceNumberNotAnInteger");
        }
        return error;
    };

    const displayedValuesAndUnits = commonFunctions.getDisplayedValueAndUnit(
        commonFunctions.computeTotalOfCo2EmissionsPrevented(state.data.months)
    );

    const renderSummary = (deviceCount: number, deviceCategory: string): JSX.Element => {
        return (
            <div data-testid={testIds.workArea.dashboard.sustainability.calculatorDialog.resultSummary}>
                <TextBlock alignCenter={true}>
                    {t("EsgCalculatorView.summaryFirstLine", {
                        deviceCount: deviceCount,
                        deviceCategory: deviceCategory,
                    })}
                    <br />
                    <b>
                        {t("EsgCalculatorView.summarySecondLine", {
                            totalAmountOfCo2: displayedValuesAndUnits.total.toFixed(),
                            unit: t(displayedValuesAndUnits.totalUnit),
                        })}
                    </b>
                    <br />
                    {t("EsgCalculatorView.summaryThirdLine")}
                </TextBlock>
            </div>
        );
    };

    return (
        <div>
            <button
                className={classNames(buttons.primaryButton, buttons.small, buttons.buttonWithoutIcon)}
                onClick={showCalculator}
                data-testid={testIds.workArea.dashboard.sustainability.calculatorButton}
            >
                {t("EsgCalculatorView.calculatorButton")}
            </button>

            <Modal isOpen={calculatorVisible} hideModal={hideCalculator} modalTitle={t("EsgCalculatorView.title")}>
                <div className={form.fixedWidthModal}>
                    <Formik
                        initialValues={{ deviceNumber: 1, deviceCategory: DEVICE_CATEGORY.LAPTOP }}
                        onSubmit={submitHandler}
                        validationSchema={object().shape({
                            deviceCategory: string().required(t("EsgCalculatorView.deviceCategoryRequired")),
                            deviceNumber: number()
                                .required(t("EsgCalculatorView.deviceNumberRequired"))
                                .max(
                                    MAX_DEVICE_COUNT,
                                    t("EsgCalculatorView.deviceNumberInvalidMaxValue", {
                                        deviceNumberMaxValue: MAX_DEVICE_COUNT,
                                    })
                                )
                                .min(1, t("EsgCalculatorView.deviceNumberInvalidMinValue")),
                        })}
                    >
                        {({ values, errors, handleBlur, handleChange }: FormikProps<FormValues>) => {
                            if (state.loading) {
                                return <LoadingIndicator />;
                            }
                            if (state.loadingFailed) {
                                return (
                                    <div>
                                        <div className={form.submitError}>
                                            {t("EsgCalculatorView.fetchConfigurationFailed")}
                                        </div>
                                        <div className={form.buttonContainer}>
                                            <button
                                                className={classNames(
                                                    buttons.primaryButton,
                                                    buttons.small,
                                                    buttons.buttonWithoutIcon
                                                )}
                                                type="submit"
                                                data-testid={testIds.common.dialog.closeButton}
                                                onClick={hideCalculator}
                                            >
                                                {t("Common.close")}
                                            </button>
                                        </div>
                                    </div>
                                );
                            }
                            if (state.calculated) {
                                return (
                                    <>
                                        <Co2EquivalentsChart
                                            dataFetched={true}
                                            widgetData={state.data.months}
                                            renderSummary={renderSummary(values.deviceNumber, values.deviceCategory)}
                                        />
                                        <div className={style.buttonContainer}>
                                            <button
                                                className={classNames(
                                                    buttons.primaryButton,
                                                    buttons.small,
                                                    buttons.buttonWithoutIcon
                                                )}
                                                onClick={() => {
                                                    setState({
                                                        ...state,
                                                        data: state.data,
                                                        calculated: false,
                                                    });
                                                }}
                                                data-testid={
                                                    testIds.workArea.dashboard.sustainability.calculatorDialog
                                                        .backButton
                                                }
                                            >
                                                {t("EsgCalculatorView.returnButton")}
                                            </button>
                                        </div>
                                    </>
                                );
                            }
                            return (
                                <FormikForm>
                                    <TextBlock>{t("EsgCalculatorView.formExplanation")}</TextBlock>
                                    <div className={form.formFields}>
                                        <label htmlFor="deviceCategory" className={form.label}>
                                            {t("EsgCalculatorView.categoryLabel")}
                                        </label>
                                        <select
                                            id="deviceCategory"
                                            className={classNames(form.select, form.fixedWidthInput, {
                                                [form.inputError]: errors.deviceCategory,
                                            })}
                                            name="deviceCategory"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.deviceCategory}
                                            data-testid={
                                                testIds.workArea.dashboard.sustainability.calculatorDialog.deviceSelect
                                                    .itself
                                            }
                                        >
                                            <option value="">{t("EsgCalculatorView.selectDeviceCategory")}</option>
                                            {deviceTypes.map((value: DeviceType) => {
                                                return (
                                                    <option key={value} value={value}>
                                                        {t(DEVICE_CATEGORY_LOCALIZATIONS[value])}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.dashboard.sustainability.calculatorDialog.deviceSelect
                                                    .errorLabel
                                            }
                                        >
                                            <ErrorMessage name="deviceCategory" />
                                        </div>
                                    </div>
                                    <div className={form.formFields}>
                                        <label htmlFor="deviceNumber" className={form.label}>
                                            {t("EsgCalculatorView.deviceNumber")}
                                        </label>
                                        <Field
                                            name="deviceNumber"
                                            className={classNames(form.input, form.fixedWidthInput, {
                                                [form.inputError]: errors.deviceNumber,
                                            })}
                                            type={"number"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.deviceNumber}
                                            validate={validateDeviceCount}
                                            data-testid={
                                                testIds.workArea.dashboard.sustainability.calculatorDialog
                                                    .deviceCountInput.itself
                                            }
                                        />
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.dashboard.sustainability.calculatorDialog
                                                    .deviceCountInput.errorLabel
                                            }
                                        >
                                            <ErrorMessage name="deviceNumber" />
                                        </div>
                                    </div>
                                    <div className={style.buttonContainer}>
                                        <button
                                            type="reset"
                                            className={classNames(
                                                buttons.secondaryButton,
                                                buttons.small,
                                                buttons.buttonWithoutIcon
                                            )}
                                            data-testid={
                                                testIds.workArea.dashboard.sustainability.calculatorDialog.resetButton
                                            }
                                        >
                                            {t("EsgCalculatorView.resetButton")}
                                        </button>
                                        <button
                                            type="submit"
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.small,
                                                buttons.buttonWithoutIcon
                                            )}
                                            data-testid={
                                                testIds.workArea.dashboard.sustainability.calculatorDialog.submitButton
                                            }
                                            onClick={() => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.DASHBOARD,
                                                    action: Action.CALCULATE_ESG_VIEW,
                                                });
                                            }}
                                        >
                                            {t("EsgCalculatorView.calculateButton")}
                                        </button>
                                    </div>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
        </div>
    );
};

export default EsgCalculatorView;
