import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./erasure-reports-table.scss";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import Modal from "components/modal/Modal";
import { reportDeletionService } from "services/report/ReportDeletionService";
import { getReportUuidsToBeDeleted, setReportUuidsToBeDeleted } from "services/table/tableRepository";
import buttonStyle from "styles/buttons.scss";

import testIds from "testIds.json";

const BulkReportDeletion = (props: {
    selectedReports: string[];
    deletionInvoked: boolean;
    onDeletionInvoked: (invoked: boolean) => void;
    onDeletionDone: (done: boolean) => void;
}): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [okClicked, setOkClicked] = React.useState<boolean>(false);
    const [deletionResult, setDeletionResult] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleReportDelete = () => {
        setOkClicked(true);
        deleteReportEventHandler(props.selectedReports);
    };

    const deleteReportEventHandler = (selectedReports: string[]) => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const currentReportsToBeDeleted = getReportUuidsToBeDeleted().concat(selectedReports);
        setReportUuidsToBeDeleted(currentReportsToBeDeleted);
        reportDeletionService
            .deleteMultipleReports(selectedReports, abortController)
            .then(() => {
                setDeletionResult(
                    t("DeleteReport.deleteMultipleReportsSuccess", { totalReports: selectedReports.length })
                );
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setDeletionResult(
                        t("DeleteReport.deleteMultipleReportsFailure", { totalReports: selectedReports.length })
                    );
                }
            })
            .finally(() => {
                setLoading(false);
                props.onDeletionInvoked(false);
                props.onDeletionDone(true);
            });
    };

    const bulkDeleteResultDialog = (
        <Modal
            isOpen={okClicked}
            hideModal={() => setOkClicked(false)}
            modalTitle={t("DeleteReport.deleteMultipleReportsResultTitle")}
        >
            <div className={classNames(style.processDialog, style.filters)}>
                <div>{deletionResult}</div>
                <div className={classNames(style.exportButtonContainer, style.cancelButton)}>
                    <button
                        className={classNames(
                            buttonStyle.primaryButton,
                            buttonStyle.medium,
                            buttonStyle.warningTextColor,
                            buttonStyle.okButton
                        )}
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={() => {
                            setOkClicked(false);
                        }}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </div>
        </Modal>
    );

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, []);

    return (
        <>
            {!loading ? bulkDeleteResultDialog : null}
            <DeleteConfirmationDialog
                modalDisplayed={props.deletionInvoked}
                introductionMessage={t("DeleteReport.deleteMultipleReportsIntroduction", {
                    totalReports: props.selectedReports.length,
                })}
                confirmationMessage={t("DeleteReport.deleteMultipleReportsConfirmation")}
                handleCommand={handleReportDelete}
                setModalDisplayed={props.onDeletionInvoked}
                title={t("DeleteReport.deleteMultipleReportsTitle")}
                loading={loading}
            />
        </>
    );
};

export default BulkReportDeletion;
