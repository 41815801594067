import { initialize } from "./initialize";
import { store } from "store";

const UST_ID = process.env.UST_ID ? process.env.UST_ID : "";

initialize();

export interface Event {
    category: string;
    action: string;
    label?: string;
}

// NOTE!
// When it comes to adding or removing, we'll always use "add" and "remove".
// Not for example, "create" or "delete". That applies equally to the enum
// names and their values. Always "ADD" in the enum name and obviously "add"
// in the values because they're always in lowercase.
// Also, "new" isn't used in the value when adding something. It's just "add
// user" and not "add new user".

export enum Action {
    // Header actions
    CHANGE_LANGUAGE = "change language",
    CHANGE_USER_THEME = "change user theme",
    DOWNLOAD_TERMS_AND_CONDITIONS = "download terms and conditions",
    LOGOUT = "logout",
    REMOVE_NOTIFICATION = "remove notification",
    VIEW_TERMS_AND_CONDITIONS = "view terms and conditions",
    VIEW_USER_PROFILE = "view user profile",
    VIEW_USER_SETTINGS = "view user settings",

    // License actions
    ADD_LICENSE_CONFIGURATION = "add license configuration",
    ASSIGN_LICENSE_KEY = "assign license keys",
    CHANGE_LICENSE_KEY_ASSIGNED_COUNT = "change license key assigned count",
    CHANGE_LICENSE_KEY_END_DATE = "change license key end date",
    CHANGE_LICENSE_KEY_IDENTIFIER = "change license key identifier",
    CHANGE_LICENSE_KEY_START_DATE = "change license key start date",
    CHANGE_LICENSE_KEY_USAGE_HOURS = "change license key usage hours",
    CHANGE_NUMBER_OF_LICENSE_KEYS = "change number of license keys",
    EDIT_LICENSE_CONFIGURATION = "edit license configuration",
    EXPORT_LICENSE_KEYS = "export license keys",
    REMOVE_LICENSE_CONFIGURATION = "remove license configuration",
    RESET_LICENSE_CONFIGURATION = "reset license configuration",
    VIEW_LICENSE_CONFIGURATION = "view license configuration",

    // Report actions
    CHANGE_COLUMN_SORT = "change column sort",
    CHANGE_COLUMN_WIDTH = "change column width",
    EXECUTE_QUICK_SEARCH = "execute quick search",
    EXPORT_DIAGNOSTIC_REPORT = "export diagnostic report",
    EXPORT_ERASURE_REPORT = "export erasure report",
    EXPORT_SELECTED_REPORTS = "export selected reports",
    LAUNCH_REPORT_IMPORT = "launch report import",
    LOAD_MORE = "load more",
    PREVIEW_ERASURE_REPORT_WITH_TEMPLATE = "preview erasure report with template",
    PRINT_SELECTED_REPORTS = "print selected reports",
    REMOVE_SINGLE_REPORT = "remove a single report",
    EDIT_SINGLE_REPORT = "edit a single report",
    REMOVE_MULTIPLE_REPORTS = "remove multiple reports",
    VIEW_DEVICE_DETAILS = "view device details",
    VIEW_DIAGNOSTIC_BUYBACK_TRADEIN = "view buy-back / trade-in",
    VIEW_DIAGNOSTIC_SUMMARY = "view diagnostic summary",
    VIEW_INSURANCE = "view insurance",
    VIEW_NTF = "view ntf",
    VIEW_REPORT_PROPERTIES = "view report properties",
    VIEW_SCREEN_ANALYSIS = "view screen analysis",
    VIEW_DATE_RANGE_FILTER = "view date range filter",
    SELECT_DATE_RANGE = "select date range filter",
    UPLOAD_REPORTS_DRAGGING = "upload reports via dragging",
    UPLOAD_REPORTS_SELECTING = "upload reports via selecting",

    // Report import notification actions
    SEE_DETAILS = "see details",

    // Report import results dialog
    REFRESH = "refresh",

    // Tenant actions
    ACCESS_TENANT = "access tenant",
    ADD_TENANT = "add tenant",
    ADD_SUB_TENANT = "add sub-tenant",
    EDIT_TENANT = "edit tenant",
    ENABLE_TENANT = "enable tenant",
    UPLOAD_CUSTOMER_LOGO = "upload customer logo",
    VIEW_TENANT_DETAILS = "view tenant details",
    VIEW_TENANT_HIERARCHY = "view tenant hierarchy",
    DELETE_TENANT = "delete tenant",

    // User actions
    ADD_USER = "add user",
    EDIT_USER = "edit user",
    REMOVE_USER = "remove user",
    ENABLE_USER = "enable user",
    ASSIGN_LICENSE_POOL_USER = "assign license pool",

    // API key actions
    EDIT_API_KEY = "edit api key",
    REMOVE_API_KEY = "remove api key",

    // Workflow templates actions
    ADD_WORKFLOW_TEMPLATE = "add workflow template",
    EXPORT_WORKFLOW_TEMPLATE = "export workflow template",
    PUBLISH_TEMPLATE = "publish workflow template",
    REMOVE_WORKFLOW_TEMPLATE = "remove workflow template",
    ADD_SUB_WORKFLOW_TEMPLATE = "add sub-workflow",
    UNPUBLISH_TEMPLATE = "unpublish workflow template",

    // Workflow actions
    ADD_WORKFLOW = "add workflow",
    CLOSE_UPDATE_WORKFLOWS_DIALOG = "close update workflows dialog",
    EXPORT_WORKFLOW = "export workflow",
    IMPORT_WORKFLOW = "import workflow",
    LOAD_WORKFLOW_VERSION = "load workflow version",
    OPEN_OLD_WORKFLOW_IN_NEW_EDITOR = "open the old workflow in the new editor",
    REMOVE_WORKFLOW = "remove workflow",
    SAVE_WORKFLOW_AS = "save workflow as",
    SET_DEFAULT_WORKFLOW = "set default workflow",
    SHOW_USER_LIST_IN_WORKFLOW = "show active user list",
    TRY_NEW_WORKFLOW_EDITOR = "try new workflow editor",
    UPDATE_WORKFLOW_INITIATED = "update workflow initiated",
    UPDATE_WORKFLOW_POSTPONED = "update workflow postponed",

    // Entitlement actions
    ADD_ENTITLEMENT = "add entitlement",
    ADD_ENTITLEMENT_LICENSE = "add entitlement license",
    CHANGE_ENTITLEMENT_CONTAINER = "change entitlement container",
    CHANGE_ENTITLEMENT_TYPE = "change entitlement type",
    CHANGE_LICENSE_TYPE = "change license type",
    EDIT_CONTAINER = "edit container",
    EDIT_ENTITLEMENT = "edit entitlement",
    REMOVE_ALL_LICENSES = "clear licenses",
    REMOVE_LICENSE = "remove license",
    SYNCHRONIZE_ENTITLEMENTS = "synchronize entitlements",

    // License delivery actions
    ADD_LICENSE_DELIVERY = "add license delivery",
    ADD_LICENSE_TO_DELIVERY = "add license to delivery",
    CHANGE_DELIVERY_TYPE = "change delivery type",
    EDIT_LICENSE_DELIVERY = "edit license delivery",
    VIEW_LICENSE_DELIVERY = "view license delivery",

    // License events actions
    EXPORT_LICENSE_EVENTS = "export license history",
    FILTER_BY_ACTION = "select event action",
    FILTER_BY_DATE = "select date range",
    FILTER_BY_PRODUCT = "select product",
    FILTER_BY_TYPE = "select event type",

    //Token rate actions
    CHANGE_TOKEN_RATE_VERSION = "change token rate version",

    // Report template actions
    ADD_REPORT_TEMPLATE = "add report template",
    EDIT_REPORT_TEMPLATE = "edit report template",
    REMOVE_REPORT_TEMPLATE = "remove report template",
    SET_DEFAULT_REPORT_TEMPLATE = "set default report template",
    VIEW_REPORT_TEMPLATE = "view report template",

    // Report view actions
    ADD_COLUMN = "add column",
    ADD_FILTER = "add filter",
    ADD_FILTER_GROUP = "add filter group",
    ADD_REPORT_VIEW = "add report view",
    CHANGE_FILTER_OPERATOR = "change filter operator",
    CHANGE_FILTER_PATH = "change filter path",
    CHANGE_PRIVATE = "change private",
    CHANGE_PRODUCT = "change product",
    CLONE_REPORT_VIEW = "clone report view",
    EDIT_REPORT_VIEW = "edit report view",
    EXPORT_REPORT_VIEW = "export report view",
    IMPORT_REPORT_VIEW = "import report view",
    REMOVE_COLUMN = "remove column",
    REMOVE_FILTER = "remove filter",
    REMOVE_FILTER_GROUP = "remove filter group",
    REMOVE_REPORT_VIEW = "remove report view",

    // Dashboard view actions
    CALCULATE_ESG_VIEW = "calculate esg view",

    // Role actions
    ADD_ROLE = "add role",
    VIEW_ROLE_DETAILS = "view role details",
    EDIT_ROLE = "edit role",
    SET_DEFAULT_ROLE = "set default role",
    CLONE_ROLE = "clone role",
    DELETE_ROLE = "delete role",

    // Report paths actions
    EXPORT_REPORT_PATHS = "export report paths",

    // User group actions
    REMOVE_USER_GROUP = "remove user group",
    VIEW_USER_GROUP_DETAILS = "view user group details",

    // License pool actions
    ADD_LICENSE_TO_POOL = "add license to pool",
    DELETE_LICENSE_POOL = "delete a license pool",
    MOVE_LICENSES_BETWEEN_POOLS = "move licenses between pools",

    // License alerts actions
    DELETE_LICENSE_ALERTS = "delete license alerts",
    EDIT_LICENSE_ALERTS = "edit license alerts",
    VIEW_LICENSE_ALERTS = "view license alerts",
}

export enum Category {
    API_KEYS = "api keys",
    DASHBOARD = "dashboard",
    HEADER = "header",
    LICENSE = "license",
    LICENSE_DELIVERY = "license delivery",
    LICENSE_ENTITLEMENT = "license entitlement",
    LICENSE_EVENTS = "license history",
    LICENSE_POOLS = "license pool",
    LICENSE_ALERTS = "license alerts",
    TOKEN_RATE = "license rate",
    NEW_WORKFLOW_EDITOR = "new workflow editor",
    REPORTS = "reports",
    REPORT_IMPORT = "report import",
    REPORT_DELETION = "report deletion",
    REPORT_TEMPLATE = "report template",
    REPORT_VIEW = "report view",
    TENANT = "tenant",
    USER = "user",
    WORKFLOW = "workflow",
    ROLE = "role",
    REPORT_PATHS = "report paths",
    USER_GROUP = "user group",
    ADVANCED_SEARCH = "advanced search",
    WORKFLOW_TEMPLATES = "workflow templates",
}

export enum Label {
    ALL = "all",
    API_KEYS = "api keys",
    ERASURE = "erasure",
    DIAGNOSTIC = "diagnostic",
    ENTITLEMENT = "entitlement",
    CONTAINER = "container",
    BMS = "bms",
    LICENSE = "license",
    LICENSE_DELIVERY = "license_delivery",
    USER = "user",
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "this week",
    LAST_SEVEN_DAYS = "last seven days",
    THIS_MONTH = "this month",
    LAST_MONTH = "last month",
    LAST_THIRTY_DAYS = "last 30 days",
    THIS_YEAR = "this year",
    LAST_YEAR = "last year",
    CUSTOM = "custom",
    REPORT_PATHS = "report paths",
    ROLE = "role",
    USER_GROUP_DETAILS_DIALOG = "user group details dialog",
    LICENSE_ALERT = "license alerts",
    WORKFLOW_TEMPLATES = "Workflow templates",
}

class UsageStatisticsService {
    public static isEnabled(): boolean {
        const storeElement = store.getState();
        let usageStatistics = true;
        if (storeElement.userReducer.user !== null) {
            usageStatistics = storeElement.userReducer.user.usageStatistics;
        }
        return usageStatistics;
    }

    public sendEvent(event: Event): void {
        if (UsageStatisticsService.isEnabled() && UST_ID !== "") {
            if (event.label === Action.CHANGE_LANGUAGE) {
                gtag("set", "language", event.label);
            }
            gtag("event", event.category, {
                action: event.action,
                ...(event.label != null ? { label: event.label } : {}),
            });
        }
    }
}

export const usageStatisticsService = new UsageStatisticsService();
