import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import AssignKeysView from "./assign-bms-keys/AssignKeysView";
import LicenseKeysTable from "./LicenseKeysTable";
import ExportIcon from "components/icons/ExportIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LICENSES_ROUTE } from "components/router/Routes";
import { AUTH_LICENSE_ASSIGN } from "domain/authority";
import { licenseService } from "services/licenses/LicenseService";
import { getStanUrl } from "services/login/endpointRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    tenantUuid: state.userReducer.user?.tenantUuid,
});

const connector = connect(mapState, {});

function BmsLicenseKeysView(props: ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = React.useState(0);
    const [allowAssigningKeys, setAllowAssigningKeys] = React.useState<boolean>(true);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [showExportButton, setShowExportButton] = React.useState<boolean>(false);

    function incrementState(): void {
        setState((previous) => previous + 1);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        hasTenantCookie()
            ? setAllowAssigningKeys(true)
            : licenseService.userHasOwnBmsLicense(abortController, props.tenantUuid ?? "").then((response) => {
                  setAllowAssigningKeys(response);
              });
    }, []);

    const exportLicenseKeys = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE,
            action: Action.EXPORT_LICENSE_KEYS,
        });

        window.location.href = getStanUrl() + "/license-keys/export";
    };

    const exportButton = showExportButton ? (
        <button
            data-tip={t("LicenseKeys.export.tooltip")}
            onClick={exportLicenseKeys}
            data-testid={testIds.workArea.license.bms.exportKeysButton}
            className={classNames(buttonStyle.secondaryButton, buttonStyle.small, buttonStyle.buttonWithoutIcon)}
        >
            <ExportIcon color={theme.linkTextColor} size={16} />
            {t("LicenseKeys.export.title")}
        </button>
    ) : null;

    const createViewActions = () => {
        const assignButton =
            allowAssigningKeys && userSessionService.userHasAllAuthorities([AUTH_LICENSE_ASSIGN]) ? (
                <AssignKeysView updateTable={incrementState} />
            ) : undefined;
        return (
            <>
                {exportButton}
                {assignButton}
            </>
        );
    };

    return (
        <ApplicationLayout
            viewTitle={t("LicenseKeys.mobileSolutionsKeys")}
            view={<LicenseKeysTable count={state} setExportButtonVisibility={setShowExportButton} />}
            mainRoute={LICENSES_ROUTE}
            viewActions={createViewActions()}
        />
    );
}

export default connector(BmsLicenseKeysView);
