import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./add-user.scss";
import AddUserForm, { FormValues } from "./AddUserForm";
import Modal from "components/modal/Modal";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import UnexpectedErrorMessage from "components/unexpected-error-message/UnexpectedErrorMessage";
import {
    AUTH_LICENSE_POOL_CREATE,
    AUTH_LICENSE_POOL_DELETE,
    AUTH_LICENSE_POOL_EDIT,
    AUTH_LICENSE_POOL_VIEW,
    AUTH_SSO_CONFIGURE,
} from "domain/authority";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { DEFAULT_LOGIN_METHOD, STANDARD_USER_TYPE } from "domain/user";
import { Role } from "domain/users";
import { licensePoolService, LicensePoolsResponse } from "services/licenses/LicensePoolService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getTenantUuid, hasTenantCookie } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { userService } from "services/user/users/UserService";
import { getUser } from "services/user/userSessionRepository";
import { userSessionService } from "services/user/UserSessionService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string | JSX.Element;
}

interface CreateUserResponse {
    code: string;
    message: string;
    requestId?: string;
}

export function deriveTenantUuid(): string {
    if (hasTenantCookie()) {
        return getTenantUuid();
    }

    const user = getUser();
    if (user == null) {
        throw new Error("Can't derive tenant UUID. It should be impossible for user to be null but it is.");
    }
    return user.tenantUuid;
}

interface RolesFetch {
    failed: boolean;
    errorModalVisible: boolean;
}

export interface Props {
    allLoaded: boolean;
    onUserAdded: () => void;
    setRolesFetched: (fetched: boolean) => void;
}

const AddUserView = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [roles, setRoles] = React.useState<Role[]>();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const [rolesFetch, setRolesFetch] = React.useState<RolesFetch>({ failed: false, errorModalVisible: false });
    const [ssoEnabled, setSsoEnabled] = React.useState(false);
    const POOL_DETAILS = {
        cursor: "",
        licensePools: [],
        count: 0,
    };
    const [licensePoolDetails, setLicensesPoolDetails] = React.useState<LicensePoolsResponse>(POOL_DETAILS);
    React.useEffect(() => {
        async function fetchDetails() {
            const abortController = new AbortController();
            const tenantUuid = deriveTenantUuid();
            await Promise.allSettled([
                tenantService.fetchTenant(tenantUuid),
                userSessionService.userHasAnyAuthority([AUTH_SSO_CONFIGURE]) &&
                userSessionService.hasFeatureLicense("FEATURE_SSO")
                    ? userService.fetchSsoSettings(tenantUuid)
                    : Promise.reject(),
                userSessionService.userHasAnyAuthority([
                    AUTH_LICENSE_POOL_CREATE,
                    AUTH_LICENSE_POOL_EDIT,
                    AUTH_LICENSE_POOL_VIEW,
                    AUTH_LICENSE_POOL_DELETE,
                ]) && userSessionService.hasFeatureLicense("FEATURE_LICENSE_POOLS")
                    ? licensePoolService.fetchLicensePools(abortController)
                    : Promise.reject(),
            ]).then(([tenantServiceResult, userServiceResult, licensePoolsListResult]) => {
                if (tenantServiceResult.status === "fulfilled") {
                    setRoles(tenantServiceResult.value.roles);
                    props.setRolesFetched(true);
                } else {
                    setRolesFetch({ failed: true, errorModalVisible: true });
                    props.setRolesFetched(false);
                }
                setSsoEnabled(userServiceResult.status === "fulfilled");
                if (licensePoolsListResult.status === "fulfilled") {
                    setLicensesPoolDetails(licensePoolsListResult.value);
                }
            });
        }
        fetchDetails();
    }, []);
    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    });

    const addUserSubmitEventHandler = async ({
        fullName,
        email,
        roleUuid,
        expirationDate,
        loginMethod,
        licensePool,
        userType,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;

        const item = licensePoolDetails.licensePools.find((item) => item.poolName === licensePool);
        if (item !== undefined) {
            licensePool = item.poolUuid;
        }

        try {
            await userService.createUser(
                fullName,
                email,
                roleUuid,
                expirationDate,
                loginMethod,
                licensePool,
                userType,
                abortController
            );
        } catch (e) {
            if (!signal.aborted) {
                let error: CreateUserResponse = { code: "", message: "" };
                try {
                    error = JSON.parse(e.message).error;
                } catch (ex) {
                    logger.error("Error while parsing exception body:", ex);
                }
                hideAddUserForm();
                showResult({
                    title: t("AddUserView.userFailure"),
                    message: getErrorMessage(error, email),
                });
                logger.error("Failed to add a user:", e);
                if (error.message === "UsernameExistsException") {
                    throw new Error(error.message);
                }
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        hideAddUserForm();
        showResult({
            title: t("AddUserView.userSuccess"),
            message: fullName
                ? t("AddUserView.nameSuccessMessage", { name: fullName })
                : t("AddUserView.emailSuccessMessage", { email }),
        });
    };

    const getErrorMessage = (error: CreateUserResponse, email: string): string | JSX.Element => {
        let errorMessage: string;
        if (error.code !== "BAD_REQUEST") {
            return getUnexpectedErrorMessage(error.requestId as string);
        } else {
            if (error.message === "Invalid email") {
                if (email === "") {
                    errorMessage = t("AddUserView.errorMessages.missingEmail");
                } else {
                    errorMessage = t("AddUserView.errorMessages.invalidEmail", { email });
                }
            } else if (error.message === "UsernameExistsException") {
                errorMessage = t("AddUserView.errorMessages.conflictingEmail", { email });
            } else {
                return getUnexpectedErrorMessage(error.requestId as string);
            }
        }
        return errorMessage;
    };

    const getUnexpectedErrorMessage = (requestId: string): JSX.Element => {
        return <UnexpectedErrorMessage requestId={requestId as string} />;
    };

    const [addUserFormVisible, setAddUserFormVisible] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);

    const showAddUserForm = () => {
        usageStatisticsService.sendEvent({
            category: Category.USER,
            action: Action.ADD_USER,
        });
        setAddUserFormVisible(true);
    };

    const hideAddUserForm = () => {
        setAddUserFormVisible(false);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResult = () => {
        setResultVisible(false);
        props.onUserAdded();
    };

    const hideRoleFetchErrorModal = () => {
        setRolesFetch((previous) => ({
            failed: previous.failed,
            errorModalVisible: false,
        }));
    };

    const deriveDefaultLicensePool = () => {
        const defaultPool = licensePoolDetails.licensePools.find((p) => p.defaultPool);
        if (defaultPool != undefined) {
            return defaultPool.poolUuid;
        } else {
            return "";
        }
    };

    const addEnabled = roles != null && props.allLoaded && !rolesFetch.failed;
    // This is stupid but unless we have "roles != null" check here again, TypeScript compiler won't figure out that
    // "roles" isn't null or undefined. Without it, AddUserForm "roles" attribute assignment would cause a compilation
    // error.
    const addUserModal = roles != null && addEnabled && (
        <Modal
            isOpen={addUserFormVisible}
            hideModal={hideAddUserForm}
            modalTitle={t("AddUserForm.title")}
            key="modalMe"
        >
            <div className={form.fixedWidthModal}>
                <TextBlock>{t("AddUserForm.addUserSummaryText")}</TextBlock>
                <AddUserForm
                    submitEventHandler={addUserSubmitEventHandler}
                    roles={roles}
                    disableEmail={false}
                    enabled={true}
                    disableStatus={false}
                    expirationDate={""}
                    loginMethod={DEFAULT_LOGIN_METHOD}
                    ssoEnabled={ssoEnabled}
                    licensePools={licensePoolDetails.licensePools}
                    userLicensePoolUuid={deriveDefaultLicensePool()}
                    userType={STANDARD_USER_TYPE}
                />
            </div>
        </Modal>
    );
    return (
        <div>
            <button
                className={classNames(buttons.primaryButton, buttons.small, buttons.buttonWithoutIcon)}
                onClick={showAddUserForm}
                data-testid={testIds.workArea.user.addUserButton}
                hidden={!addEnabled}
            >
                {t("AddUserView.userButton")}
            </button>
            {addUserModal}
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={rolesFetch.errorModalVisible}
                hideModal={hideRoleFetchErrorModal}
                modalTitle={t("AddUserView.failedRoleFetch.title")}
            >
                <div className={style.resultContainer}>
                    {t("AddUserView.failedRoleFetch.message", { email: SUPPORT_EMAIL })}
                </div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                        onClick={hideRoleFetchErrorModal}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AddUserView;
