import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import Warning from "components/icons/Warning";
import Modal from "components/modal/Modal";
import style from "components/reports/report-view.scss";
import warningStyle from "components/tenants/tenants.scss";
import Heading from "components/typography/heading/Heading";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

export interface LicensePoolProperties {
    modalDisplayed: boolean;
    setModalDisplayed: (display: boolean) => void;
    message?: string;
    supportMessage?: string;
    handleCommand: () => void;
    title?: string;
    poolUuid: string;
}

const LicensePoolCommonDialog = (props: LicensePoolProperties & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    return (
        <div>
            <Modal isOpen={props.modalDisplayed} hideModal={() => props.setModalDisplayed(false)}>
                <div className={warningStyle.warningContainer}>
                    <Warning color={theme.errorBackgroundColor} />
                </div>
                <div className={style.title}>
                    <Heading tag={"h2"}>{t("LicensePools.assign.licensePoolDialog.licensePoolAssignTitle")}</Heading>
                </div>
                <div className={warningStyle.warningContainer}>
                    {t("LicensePools.assign.licensePoolDialog.failureMessage")}
                </div>
                <div className={warningStyle.warningContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                        onClick={() => props.handleCommand()}
                        data-testid={testIds.common.dialog.retryButton}
                    >
                        {t("LicensePools.assign.assignAgainButton")}
                    </button>
                    <button
                        className={classNames(buttons.secondaryButton, buttons.medium, buttons.buttonWithoutIcon)}
                        onClick={() => props.setModalDisplayed(false)}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.cancel")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};
export default connector(LicensePoolCommonDialog);
